//depends on Chatter, Interface, Attachments
var ReservationSlideOut = {
  venueId: null,
  base_url: null,
  tags: null,
  booked_by_content: null,
  full_eta_options: null,
  _booked_by_options: null,
  _primary_card: null,
  _venue_group_client_id: null,
  _source_venue_group_client_id: null,
  _timeFormat: 'h:MM TT',
  _uncancelFlow: false,
  _uncancelHandler: null,
  _party_size_cutoff: 8,
  _card_ids: [],
  _companies: [],

  time_selected: null,
  _actual_is_auto_assign: null,
  _actual_is_custom_assign: null,

  // A great deal of violence is committed
  // upon the reservation form to get all
  // the data in there. This is a catch all
  // to protect things that need to be
  // preserved while things are rendering
  // and clicking all around them.
  EDIT_LOCK: false,

  // when set to true, prevents shifts or availability calls from starting
  LOAD_LOCK: false,

  // don't do certain things unless we've opened this sucker once
  HAS_SLIDEOUT_OPENED: false,

  init: function (
    venueId,
    base_url,
    currentday_url_param,
    venue_today_url_param,
    is_basic_user,
    booked_by_key,
    currency_symbol,
    is_nightlife_class,
    is_dining_class,
    can_edit_client_tags,
    payment_type,
    payment_account,
    KEY,
    is_bank_setup,
    autoselect_table,
    reservations_require_approval,
    default_seating_area,
    show_availability_seatingarea_covers,
    timezone,
    doNotInitFollowers,
    can_override_table_combos,
    reminderEmailTimeNightlifeExists,
    reminderEmailTimeBreakfast,
    reminderEmailTimeBrunch,
    reminderEmailTimeLunch,
    reminderEmailTimeDay,
    reminderEmailTimeDinner,
    reminderEmailTimeNight,
    send_reminder_default_method,
    reminders_sms_enabled,
    experience_id = null
  ) {
    this.venueId = venueId
    this.base_url = base_url
    this._pending_show_reservation = $.noop
    this._is_basic_user = is_basic_user
    this._locale = Pmp.Manager.Global._locale
    this._booked_by_key = booked_by_key
    this._experienceId = experience_id
    this._autoselect_table = autoselect_table
    this._currency_symbol = currency_symbol
    this._is_nightlife_class = is_nightlife_class
    this._is_dining_class = is_dining_class
    this._venue_today_url_param = venue_today_url_param
    var minDate = $.datepicker.parseDate('mm-dd-yy', venue_today_url_param)
    var currentDay = $.datepicker.parseDate('mm-dd-yy', currentday_url_param)
    this._actualDateUrlParam = currentDay < minDate ? venue_today_url_param : currentday_url_param
    this._can_edit_client_tags = can_edit_client_tags
    this._send_confirmation_email_by_default =
      globalInit.venueSettings.send_email_confirmations_default && globalInit.venueSettings.uses_confirmation_email_default
    this._send_confirmation_sms_by_default =
      globalInit.venueSettings.send_email_confirmations_default && globalInit.venueSettings.uses_confirmation_sms_default
    this._is_bank_setup = is_bank_setup
    this._payment_account = payment_account
    this._is_military_time = Pmp.Manager.Global._is_military_time
    this._reservations_require_approval = reservations_require_approval
    this._default_seating_area = default_seating_area
    this._show_availability_seatingarea_covers = show_availability_seatingarea_covers
    this._timezone = timezone
    this._can_override_table_combos = can_override_table_combos
    var url_parts = this.base_url.split('/')
    this._venue_url_key = url_parts[url_parts.length - 1]
    this._reminderTimesExistDining = {}
    this._reminderTimesExistDining['BREAKFAST'] = reminderEmailTimeBreakfast
    this._reminderTimesExistDining['BRUNCH'] = reminderEmailTimeBrunch
    this._reminderTimesExistDining['LUNCH'] = reminderEmailTimeLunch
    this._reminderTimesExistDining['DAY'] = reminderEmailTimeDay
    this._reminderTimesExistDining['DINNER'] = reminderEmailTimeDinner
    this._reminderTimesExistDining['LEGACY'] = reminderEmailTimeNight
    this._reminders_sms_enabled = reminders_sms_enabled
    this._send_reminder_default_method = reminders_sms_enabled ? send_reminder_default_method : 'EMAIL'
    this._reminder_email_time_for_nightlife_exists = reminderEmailTimeNightlifeExists
    this._rid = null
    this.$res_problem_alert = $('#problem-res-details')
    this.$res_problem_desc = $('#res-problem-desc')

    if (this._is_military_time) {
      this._timeFormat = 'HH:MM'
    }
    //this._venue_seating_area_id = venue_seating_area_id;
    //force_client_profile_search

    this._table_added_count = 0
    this._table_ids = []

    this._do_client_search_timeout_obj = undefined
    this._last_client_search_hash = undefined
    this._is_client_profile_selected = false
    this._booked_by_content = null

    this._tableids_to_seatingareaids = {}
    this._tableids_to_tables = {}
    this._table_combo_map = {}

    this._system_class = 'TABLE'
    this._prefillCreditCardOverride = false

    // disable slideout until tables and shifts are fully loaded
    this._tables_loaded = false
    this._shifts_loaded = false
    // this._capacity_mismatch tracks if the current table selected and party size match
    // superusers/managers can override this restriction
    // we use this to help determine whether we need to trigger an availability search
    // when party size changes
    this._capacity_mismatch = false
    this._shiftsByDay = {}
    this._shiftDict = undefined
    this._current_shift_day_key = undefined

    this._source_search = false

    this._setupCalendar()
    this._loadShifts(this._actualDateUrlParam)
    this.cacheTimeOptions()
    this.bindHandlers()
    BillingService.init(KEY, payment_type)
    BillingHistory.init(this.base_url)
    this.KEY = KEY
    Chatter.init()
    MailService.init()
    GroupManager.init()
    // don't put activity messages in the chat window
    Chatter.ignore_activity()

    var that = this

    $('#time-display span.pacing').html('')
    $('#time-display span.possible-pacing').html('')
    this._loadDateButtons(this._actualDateUrlParam)

    $('#res_type').change(function () {
      that._system_class = $(this).val()
    })

    $scope = $('body')
    // not sure if this should go in the slideout.
    $scope.on('click', '#edit-res-button', function () {
      $('#save-reservation').css({ background: '#347baf' })
      $('#time-display span.overbooking').hide()
      $('#time-display span.no_tables').hide()
      if (!that._uncancelFlow) {
        that._fetchAvailability(true, null, 'from_edit_button')
      }
      that.EDIT_LOCK = false
      Interface.toggleform()
    })

    $('#interface-client h3 span.toggle').on('click', function () {
      $('#main-interface').toggleClass('min-client')
      that.updateTabHeight()
    })

    $('#res_type').on('change', function () {
      $('#main-interface').removeClass('bar table')
      $('#main-interface').addClass($(this).val().toLowerCase())
    })

    // resend an email
    $('#resend-email').on('click', function () {
      var id = $('#input-actual-id').val()
      ReservationSlideOut.sendEmailConfirmation(id)
    })

    // navigate to actuals page
    $('#more-edit-actuals').on('click', function () {
      metric.track('Reservations.edit', {})
      ReservationSlideOut.openActual()
    })

    //navigate to actuals page
    $('#edit-actuals-button').on('click', function () {
      metric.track('Reservations.edit', {})
      ReservationSlideOut.openActual()
    })

    // more menu
    var showMoreMenuFn = function () {
      $('.more .container').addClass('show')
    }
    var hideMoreMenuFn = function () {
      $('.more .container').removeClass('show')
    }
    $('#res-actions #more-button').on('click', showMoreMenuFn)
    $('#res-actions .more').on('mouseleave', hideMoreMenuFn)

    $('#nav-profile').on('click', function (e) {
      var venue_id = $('#input-venue-id').val()
      var venue_group_client_id = $('#input-venue-group-client-id').val()
      if (venue_group_client_id) {
        that.showProfile(venue_id, venue_group_client_id)
      }
    })

    $('select[name=card_details]').on('change', function (e) {
      var $cardForm = $(this).closest('.credit-card-input-group')
      if (this.value == 'request') {
        $cardForm.find('.card-request-form input[name=base_amount]').prop('disabled', false)
        $cardForm.addClass('request-card')
      } else {
        $cardForm.find('.card-request-form input[name=base_amount]').prop('disabled', true)
        $cardForm.removeClass('request-card')
      }
      that._updateTextAlts()
    })

    $('input[name=request_action]').on('change', function (e) {
      var $cardForm = $(this).closest('.card-request-form')
      if (this.value == 'request_card') {
        $cardForm.addClass('card-only-display')
      } else {
        $cardForm.removeClass('card-only-display')
      }
    })

    $('.form-element.public-notes').hide()
    $('#id_send_client_email').on('change', function () {
      if (that._isReservationRequestForm()) {
        $('.form-element.public-notes').hide()
        return
      }
      var el = $(this)
      if (el.prop('checked') && el.is(':visible')) {
        $('.form-element.public-notes').show()
      } else {
        $('.form-element.public-notes').hide()
      }
    })

    var $sections = $('#tab-reservation .section-label')
    $sections.on('click', function (e) {
      var name = $(this).attr('id')
      metric.track('Reservations.navSections', {
        page: 'reservations',
        name: name,
      })
      $('#edit-reservation').scrollTop(0)
      $sections.removeClass('open')
      var $e = $(e.currentTarget)
      $e.addClass('open')
      var id = $e.prop('id')

      $set = $e.closest('#res-section-set')
      $set.prop('class', id)
    })

    $('#edit-time-button').on('click', function () {
      that._select_time(null)
      if (that._autoselect_table && !that.isEditing()) {
        $('#preloaded-tables').val('')
        that.table_ids_list = null
        that._capacity_mismatch = false
      }
    })

    var source_ui = '#add-source, #remove-source, #source-form'
    $('#add-source').on('click', function () {
      that.expandSource()
    })
    $('#remove-source').on('click', function () {
      that.clearSourcedBy(true)
      $('#source-form').find('[sr-validate], input, select').prop('disabled', true)
    })

    $('#preloaded-tables').on('change', function () {
      $('#table-inputs').html('')
      $('input[name="table_ids"]').val('')
      that._updateSaveButtonForSelectedTimeAndTable()
      if (this.value === that._CUSTOM_ASSIGN_VAL) {
        $('#override_table_combos').show()
        that.table_ids_list = null
        var input_el = $('<input type="hidden" name="table_ids" />')
        input_el.val(that._CUSTOM_ASSIGN_VAL)
        $('#table-inputs').append(input_el)
        if ($('div.insert-table').is(':parent') === false) {
          that._insertSingleTable(undefined, 'is_required', 'is_custom_table')
        }
        return
      }
      $('#override_table_combos').hide()
      $('div.insert-table').empty()
      if (this.value === that._AUTO_ASSIGN_VAL) {
        that.table_ids_list = null
        var input_el = $('<input type="hidden" name="table_ids" />')
        input_el.val(that._AUTO_ASSIGN_VAL)
        $('#table-inputs').append(input_el)
        return
      }
      that.table_ids_list = this.value
      that._capacity_mismatch = false
      var tables = this.value.split(',')
      var input
      for (var iter = 0; iter < tables.length; iter++) {
        input = $('<input type="hidden" name="table_ids" />')
        input.val(tables[iter])
        $('#table-inputs').append(input)
      }
    })

    $('#nav-chat').on('click', function (e) {
      Chatter.fetch()
    })

    this.setupPaymentActions()
    this._setDefaultCard()
    this.followers = new Followers()
    // This is super hacky, but the tangled web that is the slideout
    // makes initialization extremely tricky on the request queue. we can remove
    // this after we do the refactor.

    if (!doNotInitFollowers) {
      this.followers.init(this.base_url, $('#persons'), true)
    }
    Attachments.init(this.base_url)
    this.validator = new sr.Validator($('#main-interface'), this._currency_symbol)
    this.setupValidator()
    this.chargeValidator = new sr.Validator($('#charge-form-container'), this._currency_symbol)
    this.refundValidator = new sr.Validator($('#refund-form-container'), this._currency_symbol)
    this.notificationValidator = new sr.Validator($('#notification-form-container'), this._currency_symbol)

    this._isStripe = $('#stripe-card-mount').length
    this._stripe = null
    this._mountStripe()
  },

  _mountStripe: function (where) {
    if (!where) {
      where = 'stripe-card-mount'
    }

    if (!document.getElementById(where) || !window.Stripe) {
      return
    }

    var options = this._payment_account ? { stripeAccount: this._payment_account } : {}

    this._stripe = window.Stripe(this.KEY, options)

    var elements = this._stripe.elements()
    this.stripeMount = elements.create('card')
    this.stripeMount.mount('#' + where)
  },

  _updateTextAlts: function () {
    var $noteLabel = $('.notify_toggle .label.post')
    var $actionButton = $('#issue-charge')

    var cardSetting = $('select[name=card_details]').val()
    var actionText = 'Submit'
    var noteText = 'Send payment notification'

    if (cardSetting == 'request') {
      actionText = 'Create Paylink'
      noteText = 'Send paylink email'
      if ($('input[name=notify_toggle]').is(':checked')) {
        actionText += ' and Send Email'
      }
    }
    $actionButton.sext(actionText)
    $noteLabel.sext(noteText)
  },

  _setupCalendar: function () {
    if (!$('#res-date-selector').length) {
      return
    }
    var that = this
    var minDate = $.datepicker.parseDate('mm-dd-yy', that._venue_today_url_param)
    var defaultDate = $.datepicker.parseDate('mm-dd-yy', that._actualDateUrlParam)
    var sel = '#res-date-selector'
    var locale = Pmp.Manager.Global._locale
    var dateFmt = Pmp.Utils.dateFormat(locale)
    var formattedDate = $.datepicker.formatDate(dateFmt, defaultDate)
    Pmp.Utils.LocalizeDatePicker(locale, sel, '#actual-date-submit', 'mm-dd-yy') //, #tab-offer .request_date
    $(sel).datepicker('option', 'minDate', minDate)
    $(sel).datepicker('option', 'defaultDate', defaultDate)
    $(sel).datepicker('option', 'showOtherMonths', true)
    $(sel).datepicker('option', 'selectOtherMonths', true)
    $(sel).datepicker('option', 'closeText', 'Cancel')
    $(sel).datepicker('option', 'onSelect', function (dateText, inst) {
      $('#req-date-display').sext(dateText)
      $('#time-display span.pacing').html('')
      $('#time-display span.possible-pacing').html('')
      $('#time-display span.overbooking').hide()
      var val = $('#actual-date-submit').val()
      that._updateDateButtons(val)
      that.EDIT_LOCK = false
      that._loadShifts(val)
      that._loadEventNotes(val)
      that._setAndFormatDate()
      that._resetAvailability()
      that._modifyEmailWorthyFieldHasChanged()
    })
    $(sel).datepicker('option', 'beforeShow', function () {
      $('#ui-datepicker-div').addClass('calendar customize')
      var val = $('#actual-date-submit').val()
      var dt = $.datepicker.parseDate('mm-dd-yy', val)
      $(this).datepicker('setDate', dt) // TODO: this is a hack to show last selected
    })
    $(sel).datepicker('option', 'beforeShowDay', function (date) {
      return Pmp.Manager.Global._beforeShowDay(date)
    })
    that._setAndFormatDate(formattedDate)
  },

  // Encapsulating here for easy movement
  // if we break things up.
  setupPaymentActions: function () {
    var that = this
    $('.payment_action input').on('click', function (e) {
      var $button = $(e.currentTarget)
      var $local = $button.closest('.card-entry')
      var $context = $local.find('.commentary')
      var $required = $local.find('span.required')
      var $amount_inputs = $local.find('.base_amount input, .tip_percent input')
      var $amounts_wrap = $local.find('.amount-inputs')

      // Special case :/
      var $tip_input = $local.find('.tip_percent input')

      $tip_input.css('background-color', '#fff')
      $amount_inputs.prop('disabled', false)
      $required.show()
      $amounts_wrap.show()
      $context.find('span').hide()
      $context.find('.' + $button.prop('id')).show()

      $('.charge-description, .charge-notification').show()

      var stripeMount = $local.find('.stripe-mount').attr('id')

      if ($button.prop('id').indexOf('save_for_later') !== -1) {
        $tip_input.css('background-color', '#ddd')
        $amount_inputs.prop('disabled', true)
        $required.hide()
        $amounts_wrap.hide()
        $('.charge-description, .charge-notification').hide()
      }
      that._mountStripe(stripeMount)
    })

    $('select[name=choose_card]').on('change', function (e) {
      var $select = $(e.currentTarget)
      var $local = $select.closest('.credit-card-input-group')
      var $newcard = $local.find('.new-card')
      var $saveoption = $local.find('.save_to_profile')
      var $chargeoptions = $local.find('.payment-actions')

      $saveoption.hide()
      $newcard.hide()
      $chargeoptions.hide()

      if (!$select.val()) {
        $newcard.show()
        $chargeoptions.show()
        $saveoption.show()
      }

      // Stops unnecessary validation
      $newcard.find('input,select').prop('disabled', !!$select.val())
    })

    $('input[name=base_amount], input[name=service_charge_percent], input[name=tip_percent]')
      .on('keyup', function (e) {
        that._calc_payment(e)
      })
      .on('keyup paste', function (e) {
        var targetValue = $(e.target).val()

        if (/[^\d\.]/.test(targetValue)) {
          $(e.target).val(targetValue.replace(/[^\d.]+/g, ''))
          that._calc_payment(e)
        }
      })

    $('input[name=apply_service_charge]').on('change', function (e) {
      that._calc_payment(e)
    })

    $('input[name=apply_tax]').on('change', function (e) {
      that._calc_payment(e)
    })

    $('input[name=apply_gratuity_charge]').on('change', function (e) {
      that._calc_payment(e)
    })

    $('.tax_groups #tax_groups').on('change', function (e) {
      var $local = $(e.currentTarget).closest('.amount-inputs')
      $local.find("#tax_groups option[value='" + this.value + "']").attr('selected', 'selected')
      that._calc_payment(e)
    })
  },

  _calc_payment: function (e) {
    var $input = $(e.currentTarget)
    var $local = $input.closest('.amount-inputs')
    var $applyServiceCharge = $local.find('input[name=apply_service_charge]')
    var $service_charge_input = $local.find('input[name=service_charge_percent]')
    var $applyTax = $local.find('input[name=apply_tax]')
    var $applyGratuity = $local.find('input[name=apply_gratuity_charge]')
    var $tip_input = $local.find('input[name=tip_percent]')

    var service_charge_percent = 0
    if ($applyServiceCharge.is(':checked')) {
      $service_charge_input.show()
      service_charge_percent = parseFloat($service_charge_input.val() || 0)
    } else {
      $service_charge_input.hide()
      service_charge_percent = 0
    }

    var tax = 0
    if ($applyTax.is(':checked')) {
      $('.tax_groups').show()
      tax = parseFloat($local.find('#tax_groups option:selected').attr('tax-rate'))
    } else {
      $('#tax_groups').val('')
      $('.tax_groups').hide()
    }
    $local.find('input[name=apply_tax]').val(tax)

    var tip_percent = 0
    if ($applyGratuity.is(':checked')) {
      $tip_input.removeClass('disabled').show()
      tip_percent = parseFloat($tip_input.val() || 0)
    } else {
      $tip_input.addClass('disabled').hide()
      tip_percent = 0
    }

    var base = parseFloat($local.find('input[name=base_amount]').val() || 0)
    service_charge = (service_charge_percent / 100) * base
    tax = (tax / 100) * (base + service_charge)
    tip = (tip_percent / 100) * base

    var total = service_charge + tax + tip + base
    var display = total.toFixed(2)

    $local.find('input[name=service_charge]').val(service_charge_percent)
    $local.find('input[name=service_charge_amount]').val(service_charge)
    $local.find('input[name=gratuity_charge]').val(tip_percent)
    $local.find('input[name=gratuity_amount]').val(tip)
    $local.find('input[name=tax_amount]').val(tax)
    $local.find('input[name=payment_amount]').val(total)
    $local.find('input[name=payment_display]').val(display)
  },

  _now_by_zone: function () {
    var now = new Date()
    var formatted = moment(now).tz(this._timezone).format('YYYY/MM/DD HH:mm:ss')
    return new Date(formatted)
  },

  _updateDateButtons: function (val) {
    $('input[name=date_button]').prop('checked', false)
    $('#date-buttons .date_select').removeClass('checked')

    var select = $('input[name=date_button][value=' + val + ']')
    select.prop('checked', true)
    select.closest('.form-element').addClass('checked')
  },

  _updatePartyInterface: function (size) {
    $('.party_size_override_button').removeClass('checked')
    $('.party_size_override_button input').prop('checked', false)
    if (size < this._party_size_cutoff && size != 1) {
      $('#id_size_' + size).prop('checked', true)
      $('#id_size_' + size)
        .closest('.form-element')
        .addClass('checked')
    } else {
      $('#custom-party-size').val(size)
    }
    $('#the-real-party-size').val(size)
    if (this._table_ids_list) {
      var table_or_combo = this._lookupTableOrCombo(this._table_ids_list)
      this._capacity_mismatch = table_or_combo && table_or_combo['min'] <= size && size <= table_or_combo['max']
    }
  },

  setupValidator: function () {
    var that = this
    this.validator.onValidateFail = function (firstField) {
      that.onValidateFail(firstField)
    }
    this.validator.onShouldValidateGroup = function (groupName) {
      return that.onShouldValidateGroup(groupName)
    }
    this.validator.callback = function (field, validatorFn, isValid) {
      if (field.attr('name') === 'est_arrival_time') {
        if (that._isReservationRequestForm()) {
          return true
        }
      } else if ($.inArray(field.attr('name'), ['email', 'phone_number']) != -1) {
        // support editing when field is required but data not filled out
        // this is usually the result of an import integration
        if (!field.val() && that.isEditing()) {
          return true
        }
      } else if (field.attr('name') == 'send_reminder_email') {
        // skip this check when editing and no email exists
        if (!isValid && that.isEditing()) {
          return true
        }
      } else if (field.attr('name') == 'send_reminder_sms') {
        // skip this check when reminders not enabled
        if (!that._reminders_sms_enabled) {
          return true
        }
        // skip this check when editing and no phone number exists
        if (!isValid && that.isEditing()) {
          return true
        }
      }
      return isValid
    }
  },

  onValidateFail: function (firstField) {
    // expand the pane where the first error is
    var section = $(firstField).parents('.form-section')
    var isMain = section.hasClass('main-details')
    var isMore = section.hasClass('more-details')
    var isPayment = section.hasClass('payment-details')
    if (isMain) {
      $('#main-details').click()
    } else if (isMore) {
      $('#more-details').click()
    } else if (isPayment) {
      $('#payment-details').click()
    }
  },

  onShouldValidateGroup: function (groupName) {
    var shouldValidate = true
    if (groupName == 'payment-details') {
      shouldValidate = this.shouldProcessPayment()
    }
    return shouldValidate
  },

  shouldProcessPayment: function () {
    var amount = $.trim($('#payment_amount').val())
    var hasAmount = amount != '' && amount != this._currency_symbol
    var hasName = $.trim($('#cardholder_name').val()) != ''
    var hasCardNumber = $.trim($('#card_number').val()) != ''
    return hasAmount || hasName || hasCardNumber
  },

  updateTabHeight: function (is_new) {
    var height = $('#interface-status').height()

    var problem_res_details = $('#problem-res-details:visible')
    if (problem_res_details.length == 1) {
      height += problem_res_details.first().height()
    }

    if (!is_new) {
      height += $('#interface-client').height() + 61
    }

    $('#main-interface')
      .find('.tab')
      .css({ top: height + 'px' })
  },

  isEditing: function () {
    return $('#main-interface').hasClass('edit-reservation')
  },

  // Events for clients of this object
  onBookNew: function (successHandler) {
    var that = this
    $('#save-reservation').on('click', function (e) {
      if (!that.isEditing()) {
        return ReservationSlideOut.submitNew(function (response) {
          if (successHandler) {
            successHandler(response.data.actual, response)
            CustomerSuccessTracker.trackRequestInbox('Add Request')
          }
        }, null)
      }
      return false
    })
  },

  onEdit: function (successHandler) {
    var that = this
    $('#save-reservation').on('click', function (e) {
      if (that.isEditing()) {
        return ReservationSlideOut.submitEdit(function (original_actual_id, response, load_auto_assignments_and_actual_problems) {
          if (successHandler) {
            successHandler(original_actual_id, response.data.actual, response, load_auto_assignments_and_actual_problems)
          }
        })
      }
      return false
    })
  },

  onCharge: function (successHandler) {
    this.charge_handler = successHandler
  },

  _getClientOrSourceEmail: function () {
    var sourceEmail = $('#input-source-email').val()
    var clientEmail = $('#input-email').val()
    if (sourceEmail) {
      return sourceEmail
    }
    return clientEmail
  },

  onCancel: function (successHandler) {
    var that = this
    var showCancelMenuFn = function () {
      $('.cancel .container').addClass('show')
    }
    var hideCancelMenuFn = function () {
      $('.cancel .container').removeClass('show')
    }
    $('#cancel-res-button').on('click', showCancelMenuFn)
    $('#res-actions .cancel').on('mouseleave', hideCancelMenuFn)

    $('#send-cancellation-email').on('click', function () {
      if (!that._getClientOrSourceEmail()) {
        UserNotificationInterop.error('Please add an email to notify the client.')
        return
      }
      var id = $('#input-actual-id').val()
      ReservationSlideOut.cancelReservation(
        id,
        function () {
          if (successHandler) {
            successHandler(id, that._actual)
          }
        },
        true
      )
    })

    $('#just-cancel').on('click', function () {
      var id = $('#input-actual-id').val()
      ReservationSlideOut.cancelReservation(id, function () {
        if (successHandler) {
          successHandler(id, that._actual)
        }
      })
    })
  },

  onUncancel: function (successHandler) {
    var that = this
    $('#undo-cancellation-button').on('click', function () {
      if (that._is_dining_class) {
        that._uncancelFlow = true
        that._uncancelHandler = successHandler
        that._fetchAvailability(false, null, true)
        return
      }

      that._uncanceler(successHandler)
    })
  },

  _uncanceler: function (successHandler, desync) {
    var id = $('#input-actual-id').val()
    $('#res_status').val('NOT_RECONCILED')
    ReservationSlideOut.uncancelReservation(function () {
      if (successHandler) {
        successHandler(id)
      }
    }, desync)
  },

  cacheTimeOptions: function () {
    // eujern (1/17/15)
    // TODO to be cleaned up
    var full_eta_options = []
    $('#select-est-arrival-time')
      .find('option')
      .each(function () {
        full_eta_options.push($(this).val())
      })

    this.full_eta_options = full_eta_options
  },

  _isReservationBillingProfile: function () {
    return this._actual.payments_billing_profile != null && !this._prefillCreditCardOverride
  },

  bindHandlers: function () {
    var that = this
    var clickCancelLinkFn = function (event) {
      that._clickCancelLink(event)
    }
    var clickCompTableCboxFn = function (event) {
      that._clickCompTableCbox(event)
    }
    var clickNoMinTableCboxFn = function (event) {
      that._clickNoMinTableCbox(event)
    }
    var changeBookedByDropdownFn = function (event) {
      that._changeBookedByDropdown(event)
    }
    var changeOverrideMinTypeFn = function (event) {
      that._changeOverrideMinType(event)
    }
    var keyupOverrideMinPriceFn = function (event) {
      that._keyupOverrideMinPrice(event)
    }
    var changeClientProfileSearchFn = function (event) {
      that._changeClientProfileSearch(event)
    }
    var changeSourceProfileSearchFn = function (event) {
      that._changeSourceProfileSearch(event)
    }
    var clickSelectClientProfileFn = function (event) {
      that._clickSelectClientProfile(event, $(this))
    }
    var clickDeselectClientProfileFn = function (event) {
      that._clickDeselectClientProfile(event)
    }
    var pastePhoneNumberFn = function () {
      setTimeout(function () {
        that._pastePhoneNumber()
      }, 1)
    }
    var enforcePhoneMaxLengthFn = function () {
      that._enforcePhoneMaxLength()
    }

    $('#close-interface').click(function (event) {
      that._hideProblem()
      that.close()
    })

    $('#client-info-cancel-link').click(clickCancelLinkFn)
    //$('#comp-table-cbox').change(clickCompTableCboxFn);
    //$('#nomin-table-cbox').change(clickNoMinTableCboxFn);
    //$('#min-price-override-type').change(changeOverrideMinTypeFn);

    $('#book-res #booked-by').change(changeBookedByDropdownFn)
    $('#book-res #booked-by').on('click', changeBookedByDropdownFn)

    $('#book-res #input-firstname').keyup(changeClientProfileSearchFn)
    $('#book-res #input-firstname').change(changeClientProfileSearchFn)
    $('#book-res #input-lastname').keyup(changeClientProfileSearchFn)
    $('#book-res #input-lastname').change(changeClientProfileSearchFn)
    $('#book-res #input-phone').keyup(changeClientProfileSearchFn)
    $('#book-res #input-phone').change(changeClientProfileSearchFn)
    $('#book-res #input-email').keyup(changeClientProfileSearchFn)
    $('#book-res #input-email').change(changeClientProfileSearchFn)
    $('#book-res #profile-salutation-select').change(function (event) {
      that._changeProfileSalutationSelect(event)
    })

    $('#book-res #input-source-firstname').on('keyup change', changeSourceProfileSearchFn)
    $('#book-res #input-source-lastname').on('keyup change', changeSourceProfileSearchFn)
    $('#book-res #input-source-phone').on('keyup change', changeSourceProfileSearchFn)
    $('#book-res #input-source-email').on('keyup change', changeSourceProfileSearchFn)

    // Using existing convention
    $('#venue-public-notes-textarea').on('keyup change', function () {
      Pmp.Common.Reservation.Validator.trimPublicNotesTextArea()
    })

    $('#venue-private-notes-textarea').on('keyup change', function () {
      Pmp.Common.Reservation.Validator.trimPrivateNotesTextArea()
    })

    $('#profile-company')
      .off('keyup')
      .on('keyup', function (ev) {
        var selectedVal = $(ev.currentTarget).val(),
          matchingCompanies = _.filter(that._companies, function (companyName) {
            return companyName.trim().toLowerCase().lastIndexOf(selectedVal.toLowerCase(), 0) === 0
          }),
          matchingCompaniesTemplate = _.map(
            matchingCompanies,
            function (x) {
              return "<div class=company-lookup-option data-name='" + x + "'>" + x + '</div>'
            },
            this
          )
        $('#profile-company-lookup').empty().append(matchingCompaniesTemplate).show()
        $('#profile-company-lookup').find('.company-lookup-option').on('click', that._selectCompanyHandler)
      })

    $('#input-source-company')
      .off('keyup')
      .on('keyup', function (ev) {
        var selectedVal = $(ev.currentTarget).val(),
          matchingCompanies = _.filter(that._companies, function (companyName) {
            return companyName.trim().toLowerCase().lastIndexOf(selectedVal.toLowerCase(), 0) === 0
          }),
          matchingCompaniesTemplate = _.map(
            matchingCompanies,
            function (x) {
              return "<div class='company-lookup-option' data-name='" + x + "'>" + x + '</div>'
            },
            this
          )
        $('#source-company-lookup').empty().append(matchingCompaniesTemplate).show()
        $('#source-company-lookup').find('.company-lookup-option').on('click', that._selectSourceCompanyHandler)
      })

    // handle modify notification
    var modifyEmailWorthyFieldHasChangedFn = function () {
      that._modifyEmailWorthyFieldHasChanged()
    }
    $('#input-firstname').on('keyup change', modifyEmailWorthyFieldHasChangedFn)
    $('#input-lastname').on('keyup change', modifyEmailWorthyFieldHasChangedFn)
    $('#select-est-arrival-time').on('change', modifyEmailWorthyFieldHasChangedFn)
    $('#party-size-override').on('keyup change', modifyEmailWorthyFieldHasChangedFn)
    $('#min-price-override').on('keyup change', modifyEmailWorthyFieldHasChangedFn)
    $('#id_send_update_email').on('change', modifyEmailWorthyFieldHasChangedFn)
    $('#actual-date-submit').on('change', modifyEmailWorthyFieldHasChangedFn)
    $('#input-email').keyup(modifyEmailWorthyFieldHasChangedFn)
    $('#input-email').change(modifyEmailWorthyFieldHasChangedFn)
    $('#input-source-email').on('keyup change', modifyEmailWorthyFieldHasChangedFn)

    $('.notify_toggle').on('click', function () {
      var $input = $(this).closest('.form-element').next()
      $input.toggle()
      $input.find('input').attr('disabled', !$input.is(':visible'))

      that._updateTextAlts()
    })

    $('#send-notification').click(function (event) {
      if ($(this).hasClass('disabled')) {
        return
      }
      if (!that.notificationValidator.validate()) {
        return
      }

      $(this).addClass('disabled')

      var $email = $('#notification-email')
      var email = $email.hasClass('disabled') ? '' : $email.val()

      var actual_id = $('#input-actual-id').val()

      var $payment = $('#' + $('#notification-rid').val())

      var info = {
        email: email,
        actual_id: actual_id,
        client_id: $('#input-venue-group-client-id').val(),
        amount: $payment.attr('amount_raw'),
        last_4: $payment.attr('card_last_4'),
        brand: $payment.attr('card_brand'),
        transaction_id: $payment.attr('transaction_id'),
        history_id: $payment.attr('history_id'),
      }

      if ($payment.hasClass('info-request')) {
        info['is_info_request'] = true
      }

      if ($payment.hasClass('refund')) {
        info['is_refund'] = true
      }

      var deferred = MailService.paymentNotification(info)

      deferred
        .done(function (resp) {
          UserNotificationInterop.success('Notification sent')
        })
        .fail(function (error) {
          UserNotificationInterop.error('Notification failed to send. Please try again later.')
        })
        .always(function () {
          $('#send-notification').removeClass('disabled')
          BillingHistory.showCharges()
        })
    })

    $('#issue-refund').click(function (event) {
      if ($(this).hasClass('disabled')) {
        return
      }
      if (!that.refundValidator.validate()) {
        return
      }

      var charge_id = $('#input-charge_id').val()
      var client_id = $('#input-venue-group-client-id').val()
      var actual_id = that._actual.id
      var amount = $('#input-refund-amount').val()

      // Need to strip user input
      var amount_raw = amount.replace(/[^0-9\.]/g, '')
      var amount_float = parseFloat(amount_raw)

      var full = $('#input-refund-amount').attr('data-full-amount')

      if (amount_float > parseFloat(full.replace(/[^0-9\.]/g, ''))) {
        UserNotificationInterop.error('You cannot refund more than the remaining amount.')
        $('#input-refund-amount').val(full)
        return
      }

      $(this).addClass('disabled')

      var reason = $('#input-refund-reason').val()
      var deferred = BillingService.refund(client_id, actual_id, charge_id, amount, reason)

      var _error = function () {
        UserNotificationInterop.error('Refund failed. Please try again later.')
        $('#issue-refund').removeClass('disabled')
      }

      deferred
        .done(function (resp) {
          var payload = resp.payload
          if (payload.message == 'Error') {
            _error()
            return
          }

          if ($('#id_send_refund_email').is(':checked')) {
            var refund_data = payload.refund_data
            var $payment = $('#' + $('#notification-rid').val())

            var cents = amount_float.toFixed(2).replace('.', '')

            var $email = $('#refund-notification-email')
            var email = $email.hasClass('disabled') ? '' : $email.val()

            MailService.paymentNotification({
              email: email,
              actual_id: actual_id,
              client_id: client_id,
              amount: cents,
              transaction_id: refund_data.charge_id,
              history_id: refund_data.history_id,
              last_4: $payment.attr('card_last_4'),
              brand: $payment.attr('card_brand'),
              is_refund: true,
            }).resolve()
          }

          $('#issue-refund').removeClass('disabled')
          UserNotificationInterop.success('Card refunded. You may need to refresh your tab to view the refund.')
          BillingHistory.showCharges()

          if (that.charge_handler) {
            that.charge_handler(actual_id, resp.payload.prepayment_formatted)
          }
        })
        .fail(function (error) {
          _error()
        })
    })

    $('#add-charge').on('click', function () {
      $('#payments-history').hide()
      $('#payments-hold').hide()
      $('input[name=save_to_profile]').prop('checked', false)
      $('.save_to_profile').removeClass('checked')
      $('#ptabcharge_description, .new-card input, .new-card select').val('')
      $('#ptabreal_payment_amount').val('')
      $('#charge-form').show()
      that._setDefaultCard()
    })

    $('#id_send_refund_email').on('change', function () {
      var $addy = $('.notification-address')
      var on = $(this).is(':checked')
      var $emailinput = $addy.find('input')
      $addy.hide()
      if (on) {
        $addy.show()
      }
      if ($emailinput.hasClass('uneditable')) {
        return
      }
      $emailinput.prop('disabled', !on)
    })

    $('input[name=refund_radio]').on('click', function () {
      // remember to clear form
      var val = $(this).val()
      var $input = $('#input-refund-amount')
      $input.prop('disabled', true)
      if (val == 'PARTIAL') {
        $input.prop('disabled', false)
      } else {
        $input.val($input.attr('data-full-amount'))
      }
    })

    var _postPayment = function (actual_id, resp, advanced_payment, new_card) {
      $('#payments-history, #payments-hold').show()
      $('#charge-form, #refund-form, #notification-form').hide()
      $('#issue-charge').removeClass('disabled')

      BillingHistory.showCharges()
      if (that.charge_handler && resp) {
        that.charge_handler(actual_id, resp.payload.prepayment_formatted)
      }

      if (resp && resp.payload.message == 'Error') {
        var _failMessage = resp.payload.error || 'Operation failed'
        UserNotificationInterop.error(_failMessage)
        return false
      } else if (!advanced_payment && new_card) {
        UserNotificationInterop.success('Card saved.')
      } else {
        UserNotificationInterop.success('Card successfully charged. You may need to refresh your tab to view the charge.')
      }

      if (!advanced_payment || !$('#ptabnotify_toggle').is(':checked')) {
        return true
      }

      var $email = $('#ptabnotification_email_popup')
      var email = $email.hasClass('disabled') ? '' : $email.val()

      MailService.paymentNotification({
        client_id: $('#input-venue-group-client-id').val(),
        email: email,
        transaction_id: resp.payload.charge_id,
        history_id: resp.payload.charge.history_id,
        actual_id: actual_id,
      }).resolve()

      return true
    }

    var _chargeCard = function () {
      $('#issue-charge').addClass('disabled')

      var amount = $('#ptabreal_payment_amount').val()
      var base_amount = $('#ptabbase_amount').val()
      var gratuity = $('#ptabtip_percent').val()

      var $tax = $('#ptabapply_tax')
      var tax = $tax.is(':checked') ? parseFloat($tax.val()) : ''

      var notes = $('#ptabcharge_description').val()
      var venue_group_client_id = that._venue_group_client_id
      var actual_id = that._actual.id
      var selectedCard = $('#ptabchoose_card').val()
      var $cardOption = $('#ptabchoose_card option:selected')

      if (selectedCard) {
        var deferred = BillingService.chargeReservation(
          amount,
          base_amount,
          gratuity,
          tax,
          selectedCard,
          null,
          actual_id,
          venue_group_client_id,
          notes
        )
        deferred
          .done(function (resp) {
            _postPayment(actual_id, resp, true)
          })
          .fail(function (error) {
            $('#issue-charge').removeClass('disabled')

            var message = 'Charge failed. Please try again later.'
            if (error.message) {
              message = error.message
            }
            UserNotificationInterop.error(message)
          })
      } else {
        var onGetCard

        var name = $('#ptabcardholder_name').val()
        var number = $('#ptabcard_number').val()
        var exp_month = $('#ptabcc_expiration_month').val()
        var exp_year = $('#ptabcc_exp_year').val()
        var cvc = $('#ptabcard_cvv').val()

        var card_info = {
          client_id: venue_group_client_id,
          actual_id: actual_id,
          name: name,
          number: number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: cvc,
        }

        var advanced_payment = $('#ptabtake_payment').is(':checked')
        var adding_to_profile = false
        if ($('#ptabsave_to_profile').is(':checked')) {
          onGetCard = BillingService.addCard(card_info)
          adding_to_profile = true
        } else {
          if (that._isStripe) {
            if (advanced_payment) {
              var amount = $('#ptabpayment_display').val()
              onGetCard = BillingService.getPaymentIntent(that._stripe, that.stripeMount, name, amount)
            } else {
              onGetCard = BillingService.getSetupIntent(that._stripe, that.stripeMount, name)
            }
          } else {
            onGetCard = BillingService.getToken(name, number, exp_month, exp_year, cvc)
          }
        }

        onGetCard
          .done(function (token) {
            var deferred = $.Deferred()

            if (advanced_payment) {
              // This is all kinds of suck
              var card_id = null
              if (adding_to_profile) {
                card_id = token
                token = null
              }
              deferred = BillingService.chargeReservation(
                amount,
                base_amount,
                gratuity,
                tax,
                card_id,
                token,
                actual_id,
                venue_group_client_id,
                notes
              )
            } else if (!adding_to_profile) {
              deferred = BillingService.addCardToReservation(name, token, actual_id)
            }
            deferred
              .done(function (resp) {
                var success = _postPayment(actual_id, resp, advanced_payment, true)

                if (!success) {
                  return
                }

                if (adding_to_profile) {
                  that._loadCreditCards(that._actual.venue_group_client)
                } else if (!advanced_payment) {
                  var attached = that._createCardOption(
                    resp.payload.name,
                    resp.payload.card_type,
                    resp.payload.last_four,
                    'attached-card',
                    resp.payload.card_id
                  )
                  var $list = $('select[name=choose_card]')
                  $list.children('.attached-card').remove()
                  $list.prepend(attached)
                }
                $('input[value=advanced_payment]').trigger('click')
                that._setDefaultCard()
              })
              .fail(function (error) {
                $('#issue-charge').removeClass('disabled')

                var message = 'Charge failed. Please try again later.'
                if (error.message) {
                  message = error.message
                }
                UserNotificationInterop.error(message)
              })
              .always(function () {
                $('#issue-charge').removeClass('disabled')
              })

            if (!advanced_payment && adding_to_profile) {
              deferred.resolve()
            }
          })
          .fail(function (error) {
            // addCard failed
            $('#payments-history, #payments-hold').show()
            $('#charge-form, #refund-form, #notification-form').hide()
            $('#issue-charge').removeClass('disabled')
            BillingHistory.showCharges()
            var error = advanced_payment ? 'Charge failed' : 'There was a problem with the card.'
            UserNotificationInterop.error(error)
          })
      }
    }

    var _createLink = function ($local, actual_id) {
      var deferred = $.Deferred()
      var action = $local.find('input[name=request_action]:checked').val()
      var send = $('input[name=notify_toggle]').is(':checked')
      var email = send ? $('input[name=notification_email]').val() : ''

      if (action == 'request_card') {
        var args = {
          actual_id: actual_id,
          email: email,
        }
        deferred = BillingService.requestCard(args)
      } else {
        var args = {
          actual_id: actual_id,
          email: email,
          description: $local.find('input[name=card_request_description]').val(),
          amount: $local.find('input[name=payment_display]').val(),
          base_amount: $local.find('input[name=base_amount]').val(),
          service_charge: $local.find('input[name=service_charge]').val(),
          gratuity: $local.find('input[name=tip_percent]').val(),
          tax: $local.find('input[name=apply_tax]:checked').val(),
        }
        deferred = BillingService.requestPayment(args)
      }
      deferred.done(function (resp) {
        BillingHistory.showCharges()
      })
    }

    $('#issue-charge').click(function (event) {
      if (!that.chargeValidator.validate()) {
        return
      }
      if ($('#issue-charge').hasClass('disabled')) {
        return
      }

      var $local = $('#ptabnew_card_details').closest('form').find('.card-request-form')
      var action = $('#ptabnew_card_details').val()
      var actual_id = $('#input-actual-id').val()
      if (action == 'entry') {
        _chargeCard()
      } else {
        _createLink($local, actual_id)
      }
    })

    CostOptions.init('#edit-reservation')

    var $manager = $('#manager')
    $manager.on('click', '.select-client-profile-btn', clickSelectClientProfileFn)
    $manager.on('click', '.deselect-client-profile-btn', clickDeselectClientProfileFn)

    Pmp.Utils.InputOverlayPrompt('#new-promoter-name-container', '#new-promoter-name-prompt')
    this._loadBookedBy('#booked-by')
    if (globalInit.venueSettings.internal_ar_booking_enabled) {
      this._loadOffers('#experience_id', '#offers')
    }
    this._loadSeatingAreasAndTables('#seating-spinner')

    if (this._force_client_profile_search) {
      changeClientProfileSearchFn(null)
    }

    $('#expand-cc-link').click(function () {
      $(this).hide()
      $('.cc-input-area').removeClass('no-display')
    })

    $('#interface-navigation')
      .find('a')
      .on('click', function () {
        var nav_type = $(this).attr('id').split('-')[1]
        var props = {
          page: 'reservations',
          name: nav_type,
        }
        metric.track('Reservation.navSections', props)
        $('#main-interface').removeClass('editing')
      })

    var clickAddBookedByFn = function (event) {
      that._clickAddBookedBy(event)
    }
    $('#add-booked-by-link').click(clickAddBookedByFn)

    var onClickDocumentHideTagsPicker = function (event) {
      that._hideTagsPicker(event)
      that._hideCompaniesPicker(event)
      that._hideSourceCompaniesPicker(event)
    }
    $(document).mouseup(onClickDocumentHideTagsPicker)

    this.radioSetting = false

    $('#select-duration').on('change', function () {
      that._resetAvailability()
      that._fetchAvailability()
      that._modifyEmailWorthyFieldHasChanged()
    })
    $('input[name=party_size_override_button]').on('click', function (ee) {
      $('#the-real-party-size').val($(this).val())
      $('#custom-party-size').val('')
      var new_party_size = parseInt($('#the-real-party-size').val())
      var party_size_valid = false
      var tids = that.table_ids_list
      if (tids) {
        var table_or_combo = that._lookupTableOrCombo(tids)
        party_size_valid = table_or_combo && table_or_combo['min'] <= new_party_size && new_party_size <= table_or_combo['max']
      }
      $('#save-reservation').css({ background: '#347baf' })
      // check if the new party size fit the table
      // we also want to trigger search if the new party size is valid, but the previous party size was not
      if (!party_size_valid || that._capacity_mismatch) {
        that.EDIT_LOCK = false
        that.resetShiftDuration()
        that._resetAvailability()
      }
      that._fetchAvailability()
      that._capacity_mismatch = party_size_valid
      that._modifyEmailWorthyFieldHasChanged(ee)
    })

    $('#custom-party-size').on('focus', function () {
      if ($(this).val()) {
        return
      }
      that.radioSetting = $('input[type=radio][name=party_size_override_button]:checked').prop('id')
    })
    var delay = (function () {
      var timer = 0
      return function (callback, ms) {
        clearTimeout(timer)
        timer = setTimeout(callback, ms)
      }
    })()

    $('#custom-party-size').on('keydown', function (e) {
      if (
        !(e.which >= 48 && e.which <= 57) /* keyboard numbers */ &&
        !(e.which >= 96 && e.which <= 105) /* number pad */ &&
        !(e.which >= 37 && e.which <= 40) /* arrow keys */ &&
        e.which != 8 &&
        e.which != 9
      ) {
        e.preventDefault()
        return false
      }
    })
    $('#custom-party-size').on('keyup', function () {
      var $input = $(this)
      delay(function () {
        that.EDIT_LOCK = false
        if ($input.val()) {
          $('input[type=radio][name=party_size_override_button]').prop('checked', false)
          $('input[type=radio][name=party_size_override_button]').closest('.form-element').removeClass('checked')
          $('#the-real-party-size').val($input.val())
        } else {
          $('#' + that.radioSetting).trigger('click')
          $('#the-real-party-size').val($('#' + that.radioSetting).val())
        }
        var new_party_size = parseInt($('#the-real-party-size').val())
        var party_size_valid = false
        var tids = that.table_ids_list
        if (tids) {
          var table_or_combo = that._lookupTableOrCombo(tids)
          party_size_valid = table_or_combo && table_or_combo['min'] <= new_party_size && new_party_size <= table_or_combo['max']
        }
        $('#save-reservation').css({ background: '#347baf' })

        if (!party_size_valid || that._capacity_mismatch) {
          that.resetShiftDuration()
          that._resetAvailability()
        }
        that._fetchAvailability()
        that._capacity_mismatch = party_size_valid
      }, 700)
    })

    $('#res_shift').on('change', function () {
      this.EDIT_LOCK = false
      that._changeShift($(this).val(), 'reset_times')
      that._toggleReminderEmailDisplayForShift()
    })

    $('.status-switcher a').on('click', function () {
      var stat = $(this).prop('id').split('-')[1]
      $('#res_status').val(stat)
      $('#save-reservation').trigger('click')
    })

    $('#select-send-client-email-destination')
      .on('change', function () {
        $('#id_send_client_email').attr('sr-validate-inject', that._validateEmailDestinationFieldsList($(this)))
        that._repositionDownerForDestinationDropdown($(this))
      })
      .change()
    $('#select-send-update-email-destination')
      .on('change', function () {
        $('#id_send_update_email').attr('sr-validate-inject', that._validateEmailDestinationFieldsList($(this)))
        that._repositionDownerForDestinationDropdown($(this))
      })
      .change()
    $('#select-send-reminder-email-destination')
      .on('change', function () {
        $('#id_send_reminder_email').attr('sr-validate-inject', that._validateEmailDestinationFieldsList($(this)))
        that._repositionDownerForDestinationDropdown($(this))
      })
      .change()
    $('#select-send-reminder-sms-destination')
      .on('change', function () {
        $('#id_send_reminder_sms').attr('sr-validate-inject', that._validatePhoneDestinationFieldsList($(this)))
        that._repositionDownerForDestinationDropdown($(this))
      })
      .change()
    $('#select-send-client-sms-destination')
      .on('change', function () {
        $('#id_send_client_sms').attr('sr-validate-inject', that._validatePhoneDestinationFieldsList($(this)))
        that._repositionDownerForDestinationDropdown($(this))
      })
      .change()
    $('#select-send-update-sms-destination')
      .on('change', function () {
        $('#id_send_update_sms').attr('sr-validate-inject', that._validatePhoneDestinationFieldsList($(this)))
        that._repositionDownerForDestinationDropdown($(this))
      })
      .change()
  },

  _repositionDownerForDestinationDropdown: function (select_field) {
    var $dummy_span = $('<span>').sext(select_field.find('option:selected').text())
    $dummy_span.css({
      'font-family': select_field.css('font-family'),
      'font-size': select_field.css('font-size'),
      'font-weight': select_field.css('font-weight'),
    })
    $dummy_span.appendTo('body')
    var select_width = $dummy_span.width()
    $dummy_span.remove()
    var $downer = select_field.siblings('.downer')
    $downer.css({ left: select_width + 10 })
  },

  _validateEmailDestinationFieldsList: function (select_field) {
    var validate_fields = []
    if (this._isDestinationIncludesClient(select_field)) {
      validate_fields.push('#input-email')
    }
    if (this._isDestinationIncludesSource(select_field)) {
      validate_fields.push('#input-source-email')
    }
    return validate_fields.join()
  },

  _validatePhoneDestinationFieldsList: function (select_field) {
    var validate_fields = []
    if (this._isDestinationIncludesSource(select_field)) {
      validate_fields.push('#input-source-phone')
    }
    if (this._isDestinationIncludesClient(select_field)) {
      validate_fields.push('#input-phone')
    }
    return validate_fields.join()
  },

  _isDestinationIncludesSource: function (select_field) {
    return _.contains(['SOURCE', 'SOURCE_AND_CLIENT'], select_field.val())
  },

  _isDestinationIncludesClient: function (select_field) {
    return _.contains(['CLIENT', 'SOURCE_AND_CLIENT'], select_field.val())
  },

  _lookupTableOrCombo: function (id) {
    if (!id) {
      return null
    }
    if (id.indexOf(',') === -1) {
      var table = this._tableids_to_tables[id]
      if (table) {
        return { name: table.item_code, min: table.party_size_min, max: table.party_size_max }
      } else {
        return null
      }
    }

    var combo = this._table_combo_map[id]
    if (combo) {
      return { name: combo.label, min: combo.party_size_min, max: combo.party_size_max }
    } else {
      return null
    }
  },

  log: function (msg) {
    console.log('Pmp.Manager.Global: ' + msg)
  },

  debug: function (msg) {
    if (Pmp.Settings.DEBUG) {
      this.log(msg)
    }
  },

  _insertSingleBookedByAlt: function (booked_by_key) {
    // basic users don't have booked bys
    if (this._is_basic_user) {
      return
    }
    var booked_by_content = this._booked_by_content ? this._booked_by_content : { booked_by_users: [] }
    var options_html = Nightloop.Templates.Manager.Reservation.BookedByUsers({
      content: this._booked_by_content,
      booked_by_key: booked_by_key,
    })
    var select_html =
      "<div class='booked_by_wrap'><p class='input'><select name='booked_by_alt'>" +
      options_html +
      "</select><span class='downer'></span></p><a class='remover' href='javascript:void(0);' onclick='javascript:ReservationSlideOut.removeBookedBy(this);'>&times;</a></div>"
    $('.insert-booked-by').append(select_html)
  },

  _hideTagsPicker: function (e) {
    var container = $('#add-tag-results')
    if (container.has(e.target).length === 0) {
      container.hide()
    }
  },

  _hideCompaniesPicker: function (e) {
    var companyLookup = $('#profile-company-lookup'),
      companyInput = $('#profile-company')
    if ($(companyLookup).has(e.target).length === 0 && $(companyInput).has(e.target).length === 0) {
      $(companyLookup).hide()
    }
  },

  _hideSourceCompaniesPicker: function (e) {
    var companyLookup = $('#source-company-lookup'),
      companyInput = $('#input-source-company')
    if ($(companyLookup).has(e.target).length === 0 && $(companyInput).has(e.target).length === 0) {
      $(companyLookup).hide()
    }
  },

  _selectCompanyHandler: function (e) {
    $('#profile-company').val($(e.currentTarget).data('name'))
    $('#profile-company-lookup').hide()
  },

  _selectSourceCompanyHandler: function (e) {
    $('#input-source-company').val($(e.currentTarget).data('name'))
    $('#source-company-lookup').hide()
  },

  _clickAddBookedBy: function () {
    if (this._is_basic_user) {
      return
    }
    if (!this._booked_by_content) {
      return
    }
    this._insertSingleBookedByAlt(this._booked_by_key)
  },

  removeBookedBy: function (link_el) {
    $(link_el).parent().remove()
  },

  _loadOffers: function (select_id, block_id) {
    let self = this
    OfferService.getByVenue(
      this.venueId,
      'EVENT',
      function (offers) {
        let optionsHtml = Nightloop.Templates.Manager.OfferSelectOptions({
          offers: offers.filter(offer => offer.is_active),
          offer_id: self._experienceId,
        })
        $(select_id).html(optionsHtml)
      },
      () => {
        $(block_id).hide()
      },
      false
    )
  },

  _loadBookedBy: function (select_id) {
    if (this._is_basic_user) {
      return
    }
    var self = this
    BookedByService.getByVenue(
      this.base_url,
      function (response) {
        //Success
        if (self._booked_by_key) {
          response['booked_by_key'] = self._booked_by_key
        }
        self._booked_by_content = response.content
        var options_html = Nightloop.Templates.Manager.Reservation.BookedByUsers(response)
        self._booked_by_options = options_html
        $(select_id).append(options_html)
        $('#booked-by-spinner').addClass('no-display')
        $(select_id).removeClass('no-display')
        $('[name=booked_by]').val(self._booked_by_key)
        $('#add-booked-by-link').removeClass('no-display')
        self.followers.refreshFollowersSelect(self._booked_by_content.booked_by_users)
      },
      undefined, // error
      false
    ) // use cache FALSE because you can add booked by names
  },

  refreshBookedByNames: function () {
    $('#booked-by').empty()
    $('#booked-by').append('<option value="--new--">+ add new name</option>')
    $('#booked-by').append(this._booked_by_options)
  },

  _getActiveDateUrlParam: function () {
    return this._actualDateUrlParam
  },

  _loadSeatingAreasAndTables: function (select_id) {
    // disable save button until this loads
    $('#client-info-submit-btn').addClass('disabled')

    var system_class = this._system_class
    var url = this.base_url + '/data/seatingareastables?system_class=' + system_class

    // TODO: what is this used for?
    //if (this._venue_seating_area_id) {
    //	url = url + '&venue_seating_area_id=' + this._venue_seating_area_id;
    //}

    var _urlparam = this._getActiveDateUrlParam()
    if (_urlparam) {
      url = url + '&date=' + _urlparam
    }
    var self = this

    //var set_seating_areas = !(this._from_floorplan && this._has_selected_tables);
    // this should always be set since floorplan has its own slideout
    var set_seating_areas = true

    Pmp.Client.AsyncGet(url, function (data) {
      var payload = data.payload
      if (set_seating_areas) {
        var seating_areas_and_tables_html = Nightloop.Templates.Manager.Reservation.SeatingAreasAndTables(payload)
        $(seating_areas_and_tables_html).insertAfter(select_id)
      }

      // setup events
      if (self._system_class == 'TABLE') {
        var seatingareacodes_to_tables = data.payload.content.seatingareacodes_to_tables
        for (var i = 0; i < seatingareacodes_to_tables.length; i++) {
          var sac_to_t = seatingareacodes_to_tables[i]
          var tables = sac_to_t.tables
          for (var t = 0; t < tables.length; t++) {
            var table = tables[t]
            self._tableids_to_seatingareaids[table.id] = sac_to_t.id
          }
        }
        var allTables = data.payload.content.all_table_inventory_items
        for (var i = 0; i < allTables.length; i++) {
          var table = allTables[i]
          self._tableids_to_tables[table.id] = table
        }
      }
      if (set_seating_areas) {
        // Awkward switch, but better than slicing up the existing functions
        if (self._is_nightlife_class) {
          $('#select-seating-area').change(function (event) {
            self._changeSelectSeatingArea(event)
          })
          $('#select-seating-table').change(function (event) {
            self._changeSelectSeatingTable(event)
          })
        } else {
          $('#select-seating-area').change(function (event) {
            self._filterAvailableTables()
            self._fetchAvailability()
          })
          $('#preloaded-tables').change(function (event) {
            self._updateSeatingArea(this.value)
          })
        }
      } else {
        // display the tables
        var tableNames = []
        if (self._table_id_list_existing) {
          for (var i = 0; i < self._table_id_list_existing.length; i++) {
            var table_id = self._table_id_list_existing[i]
            tableNames.push(self._tableids_to_tables[table_id].item_code)
          }
        }
        tableNames.sort()
        var tableDisplay = ''
        for (var i = 0; i < tableNames.length; i++) {
          tableDisplay += tableNames[i]
          var lastTable = tableNames.length - 1 === i
          if (!lastTable) {
            tableDisplay += ', '
          }
        }
        var pre_selected_tables_html = Nightloop.Templates.Manager.Reservation.PreSelectedSeatingAreasAndTables({
          tableDisplay: tableDisplay,
        })
        $(pre_selected_tables_html).insertAfter(select_id)
      }

      var combos = data.payload.content.table_combos
      for (var i = 0; i < combos.length; ++i) {
        self._table_combo_map[combos[i].id] = combos[i]
      }

      $('#seating-spinner').addClass('no-display')

      var clickAddTableFn = function (event) {
        self._clickAddTable(event)
      }
      $('#add-table-link').click(clickAddTableFn)

      self._insertAndSelectTables()

      $('#client-info-submit-btn').removeClass('disabled')
      self._tables_loaded = true
      self._enableBookNewButtonsIfReady()
    })
  },

  _enableBookNewButtonsIfReady: function () {
    if (this._shifts_loaded && this._tables_loaded && !globalInit.venueSettings.use_supafly) {
      $('#book-new').removeClass('disabled')
      $('#add-request').removeClass('disabled')

      var callWhenReady = this._pending_show_reservation
      this._pending_show_reservation = $.noop
      callWhenReady()
    }
  },

  _clickAddTable: function () {
    this._insertSingleTable(undefined)
  },

  _insertSingleTable: function (table_id, is_required, is_custom_table) {
    var seating_area_id = $('#select-seating-area').val()
    var html = ''
    if (seating_area_id === '') {
      html = $('#sac_to_tables_all').html()
    } else {
      var sac_to_tables_select = $('#sac_to_tables_' + seating_area_id)
      if (sac_to_tables_select.length === 0) {
        sac_to_tables_select = $('#sac_to_tables_all')
      }
      html = sac_to_tables_select.html()
    }

    var div = $('<div/>').prop('class', 'form-element select table'),
      p = $('<p/>').prop('class', 'input'),
      remove = $('<a/>').prop('class', 'remover').html('&times;'),
      downer = $('<span/>').prop('class', 'downer'),
      select = $('<select/>').prop('id', 'table-added-' + this._table_added_count)

    select.prop('class', 'table-selector').prop('name', 'table_ids').html(html)

    remove.on('click', function (e) {
      div.remove()
    })

    p.append(select, downer)
    if (!is_required) {
      p.append(remove)
    }
    div.append(p)

    $('.insert-table').append(div)

    // This is for initial edit load for venues
    // using availability.
    var hidden = $('<input/>')
    hidden.prop('value', table_id).prop('name', 'table_ids').prop('type', 'hidden')

    $('#table-inputs').append(hidden)
    if (this._is_dining_class && !is_custom_table) {
      this._updateSeatingArea(table_id)
    }

    if (table_id) {
      $('#table-added-' + this._table_added_count).val(table_id)
    }
    this._table_added_count += 1
  },

  _insertAndSelectTables: function () {
    for (var i = 0; i < this._table_ids.length; i++) {
      var table_id = this._table_ids[i]
      if (i === 0) {
        // select the existing table
        $('.table-selector').val(table_id)
      } else {
        this._insertSingleTable(table_id)
      }
    }
  },

  _selectTables: function () {
    var self = this
    var table_selectors = $('.table-selector')
    var selector_idx = 0
    for (var i = 0; i < self._table_ids.length; i++) {
      var selector = table_selectors.eq(selector_idx)
      var table_id = self._table_ids[i]
      selector.val(table_id)
      // advance if something was actually selected
      if (selector.val() === table_id) {
        selector_idx += 1
      }
    }
  },

  _AUTO_ASSIGN_VAL: 'auto',
  _CUSTOM_ASSIGN_VAL: 'custom',

  _filterAvailableTables: function () {
    var area_id = $('#select-seating-area').val()
    $('#preloaded-tables option').css('display', 'block')

    if (!area_id) {
      return
    }

    var table_set = table_area_dict[area_id]

    var selected_table_in_area = false
    var that = this
    $('#preloaded-tables option')
      .filter(function () {
        var option_val = $(this).val()
        var is_auto_assign = option_val === that._AUTO_ASSIGN_VAL
        var is_custom_assign = option_val === that._CUSTOM_ASSIGN_VAL
        var val = option_val.split(',')[0]
        var in_set = $.inArray(val, table_set) !== -1
        if ((in_set || is_auto_assign || is_custom_assign) && $(this).prop('selected')) {
          selected_table_in_area = true
        }
        // we want to keep the "no assignment" option
        return !in_set && option_val && !is_auto_assign && !is_custom_assign
      })
      .css('display', 'none')

    if (selected_table_in_area) {
      return
    }

    $('#preloaded-tables').val('')
    $('#table-inputs').empty()
  },

  _updateSeatingArea: function (table_id) {
    if (!table_id || table_id === this._AUTO_ASSIGN_VAL || table_id === this._CUSTOM_ASSIGN_VAL) {
      return
    }

    var id = table_id.split(',')[0]
    var val

    for (var set in table_area_dict) {
      if ($.inArray(id, table_area_dict[set]) === -1) {
        continue
      }
      val = set
      break
    }

    $('#select-seating-area').val(val)
  },

  _filterSeatingAreasByTables: function (tables) {
    var table_array = []

    for (var iter = 0; iter < tables.length; iter++) {
      table_array = table_array.concat(tables[iter].split(','))
    }

    var val = $('#select-seating-area').val()

    $('#select-seating-area option')
      .filter(function () {
        if (!$(this).val()) {
          return false
        }

        var area = table_area_dict[$(this).val()]
        var hide = !_.intersection(table_array, area).length
        if (hide && val === $(this).val()) {
          val === ''
        }

        return hide
      })
      .css('display', 'none')

    $('#select-seating-area').val(val)
  },

  _changeSelectSeatingArea: function (event) {
    var seating_area_id = $('#select-seating-area').val()

    var html = ''
    if (seating_area_id === '') {
      html = $('#sac_to_tables_all').html()
    } else {
      var sac_to_tables_select = $('#sac_to_tables_' + seating_area_id)
      if (sac_to_tables_select.length === 0) {
        sac_to_tables_select = $('#sac_to_tables_all')
      }
      html = sac_to_tables_select.html()
    }

    $('.table-selector').each(function (idx, el) {
      var prev_val = $(el).val()
      $(el).html(html)
      $(el).val(prev_val) // Try to retain table selection if it is still in the list
    })

    this._selectTables()
  },

  _changeSelectSeatingTable: function (event) {
    var seating_table_id = $('#select-seating-table').val()
    var seating_area_id = this._tableids_to_seatingareaids[seating_table_id]
    if (seating_area_id !== undefined) {
      $('#select-seating-area').val(seating_area_id)
    } else {
      $('#select-seating-area').val('')
    }
  },

  isInCompedMode: function () {
    return $('#comp-table-cbox').is(':checked')
  },

  isInNoMinMode: function () {
    return $('#nomin-table-cbox').is(':checked')
  },

  updatePsAndPricing: function () {
    $('#comp-display').addClass('no-display')
    $('#nomin-display').addClass('no-display')
    $('#min-price-override-container').addClass('no-display')
    if (this.isInCompedMode()) {
      $('#comp-display').removeClass('no-display')
    } else if (this.isInNoMinMode()) {
      $('#nomin-display').removeClass('no-display')
    } else {
      $('#min-price-override-container').removeClass('no-display')
    }
    this._modifyEmailWorthyFieldHasChanged()
  },

  _clickCompTableCbox: function (event) {
    if (this.isInCompedMode()) {
      $('#min-price-override').val('') // Clear this field out to avoid validation issues
      $('#nomin-table-cbox').removeAttr('checked')
    }
    this.updatePsAndPricing()
  },

  _clickNoMinTableCbox: function (event) {
    if (this.isInNoMinMode()) {
      $('#min-price-override').val('') // Clear this field out to avoid validation issues
      $('#comp-table-cbox').removeAttr('checked')
    }
    this.updatePsAndPricing()
  },

  _show_new_promoter_name: function () {
    $('#new-promoter-name-container').show()
  },

  _hide_new_promoter_name: function () {
    $('#new-promoter-name-container').hide()
  },

  _require_booked_by_fields: function () {
    $('.booked-by-unrequired').hide()
  },

  _unrequire_booked_by_fields: function () {
    $('.booked-by-unrequired').show()
  },

  _changeBookedByDropdown: function (event) {
    var booked_by_val = $('#booked-by').val()
    if (booked_by_val === '--self--') {
      this._hide_new_promoter_name()
      this._unrequire_booked_by_fields()
    } else if (booked_by_val === '--new--') {
      this._show_new_promoter_name()
      this._require_booked_by_fields()
    } else {
      this._hide_new_promoter_name()
      this._require_booked_by_fields()
    }
  },

  _changeOverrideMinType: function (event) {
    var min_val = $('#min-price-override').val()
    if ($('#min-price-override-type').val() === 'dollar') {
      $('#min-price-override').val(this._currency_symbol + min_val)
    } else {
      $('#min-price-override').val(Pmp.Utils.Currency.StripSymbol(min_val, this._currency_symbol))
    }
  },

  _changeProfileSalutationSelect: function (event) {
    var salutation_select_val = $('#profile-salutation-select').val()
    $('#profile-custom-salutation-container').toggle(salutation_select_val === 'custom')
  },

  _keyupOverrideMinPrice: function (event) {
    if ($('#min-price-override-type').val() === 'dollar') {
      var input = $(event.target)
      Pmp.Utils.Currency.AddSymbolToElementVal(input, this._currency_symbol)
    }
  },

  _changeClientProfileSearch: function (event) {
    if (!this.HAS_SLIDEOUT_OPENED) {
      return
    }
    this._source_search = false
    // don't trigger search when editing an existing actual
    if ($('#input-actual-id').val() || this.isClientProfileSelected()) {
      return
    }
    if (this._last_client_search_hash === this._createClientSearchHash()) {
      return
    }
    this._setDoSearchTimeout()
  },

  _changeSourceProfileSearch: function (event) {
    if (!this.HAS_SLIDEOUT_OPENED) {
      return
    }
    this._source_search = true
    // don't trigger search when editing an existing actual
    if (this.isSourceProfileSelected()) {
      return
    }
    if (this._last_client_search_hash === this._createClientSearchHash()) {
      return
    }
    this._setDoSearchTimeout()
  },

  isClientProfileSelected: function () {
    return this._is_client_profile_selected
  },

  isSourceProfileSelected: function () {
    return !!this._source_venue_group_client_id
  },

  clearClientProfileSearchTimeout: function () {
    if (this._do_client_search_timeout_obj !== undefined) {
      clearTimeout(this._do_client_search_timeout_obj)
      this._do_client_search_timeout_obj = undefined
    }
  },

  showLoadingClientProfileSpinner: function () {
    var modifier = this._source_search ? 'source-' : ''
    $('#' + modifier + 'client-profile-area #cpm-loading-div.no-display').removeClass('no-display')
  },

  _setDoSearchTimeout: function () {
    this.clearClientProfileSearchTimeout()
    this.showLoadingClientProfileSpinner()
    var self = this
    var doClientProfileSearchFn = function () {
      self._doClientProfileSearch(false)
    }
    this._do_client_search_timeout_obj = setTimeout(doClientProfileSearchFn, 300)
  },

  _getClientSearchName: function () {
    return this._getClientSearchFirstName() + ' ' + this._getClientSearchLastName()
  },

  _getClientSearchFirstName: function () {
    return $('#input' + (this._source_search ? '-source' : '') + '-firstname').val()
  },

  _getClientSearchLastName: function () {
    return $('#input' + (this._source_search ? '-source' : '') + '-lastname').val()
  },

  _getClientSearchPhone: function () {
    return $('#input' + (this._source_search ? '-source' : '') + '-phone')
      .val()
      .replace(/[^\d]/gi, '')
  },

  _getClientSearchEmail: function () {
    return $('#input' + (this._source_search ? '-source' : '') + '-email').val()
  },

  _createClientSearchHash: function () {
    return (
      this._getClientSearchName() +
      '#' +
      this._getClientSearchPhone() +
      '#' +
      this._getClientSearchEmail() +
      '#' +
      (this._source_search ? '1' : '0')
    )
  },

  _doClientProfileSearch: function (suppress) {
    this.clearClientProfileSearchTimeout()
    this.showLoadingClientProfileSpinner()
    var client_search_hash = this._createClientSearchHash()
    this._last_client_search_hash = client_search_hash

    var that = this
    var onLoadFn = function (data) {
      that._handleLoadClientProfileMatches(data, client_search_hash, suppress, that._source_search)
    }

    var searchFirstName = this._getClientSearchFirstName()
    var searchLastName = this._getClientSearchLastName()
    var searchPhone = this._getClientSearchPhone()
    var searchEmail = this._getClientSearchEmail()
    var url =
      this.base_url +
      '/clients/match?fn=' +
      encodeURIComponent(searchFirstName) +
      '&ln=' +
      encodeURIComponent(searchLastName) +
      '&p=' +
      encodeURIComponent(searchPhone) +
      '&e=' +
      encodeURIComponent(searchEmail) +
      (this._source_search ? '&is_source=1' : '')

    Pmp.Client.AsyncGet(url, onLoadFn)
  },

  _handleLoadClientProfileMatches: function (data, client_search_hash, suppress, is_source) {
    this.debug('_handleLoadClientProfileMatches')

    var load_area_clients = '#client-profile-area'
    var load_area_source = '#source-client-profile-area'

    var load_area = is_source ? load_area_source : load_area_clients

    // Make sure we didn't do another search in the meantime
    if (this._last_client_search_hash !== client_search_hash) {
      return
    }
    if (!data.payload.venue_group_clients) {
      $(load_area).hide()
      if (!is_source) {
        RequestSlideout._handleLoadClientProfileMatches(false)
      }
    } else {
      if (is_source) {
        data.payload['wrap_id'] = 'source-client-profile-area'
      }
      Pmp.Client.Static.Page.RenderPart($(load_area), data.template, data.payload)
      if (suppress) {
        $(load_area).hide()
      }

      // Highlight matches.
      // Highlighting was broken, and also terrible code that
      // would have gotten worse with the source piggback.
      // Punt to saner installation, but don't forget about it.

      $(load_area).css('display', 'block')

      Pmp.Common.TagCache.renderTags()
      Pmp.Common.TagCache.sortTags('.tag-display')
      $(load_area)
        .find('.groups-prepend.member')
        .each(function () {
          var $area = $(this)
          if ($area.attr('member_ids')) {
            var ids = JSON.parse($area.attr('member_ids'))
            GroupManager.showMembers(ids, $area)
          }
        })

      // On successful loading of data, apply font color contrast to all the tags
      _.map($('[data-needscontrast]'), function (x) {
        $(x).css('color', sr.formatter.getContrastYIQ($(x).attr('data-needscontrast')))
        $(x)
          .find('span')
          .css('color', sr.formatter.getContrastYIQ($(x).attr('data-needscontrast')))
      })

      if (!is_source) {
        RequestSlideout._handleLoadClientProfileMatches(true)
      }
    }
    this._setClientSlideoutPosition()
  },

  _setClientSlideoutPosition: function () {
    var load_area_clients = '#client-profile-area'
    var load_area_source = '#source-client-profile-area'
    var clientVisible = $(load_area_clients).is(':visible')
    var sourceVisible = $(load_area_source).is(':visible')
    if (clientVisible && sourceVisible) {
      $(load_area_clients).css('right', '851px')
    } else {
      $(load_area_clients).css('right', '451px')
    }
  },

  selectVenueGroupClientId: function (venue_group_client_id, suppress, request_callback, onLoadCallBack, isSource) {
    this.debug('selectVenueGroupClientId')
    this.clearClientProfileSearchTimeout()
    if (!isSource) {
      this._is_client_profile_selected = true
      this._source_search = false
    } else {
      this._source_search = true
    }

    this.showLoadingClientProfileSpinner()

    var self = this
    var onLoadFn = function (data) {
      self._handleLoadSelectedClientProfile(data, suppress, isSource)
      if (onLoadCallBack) {
        onLoadCallBack(data.payload)
      }
    }
    var url = this.base_url + '/clients/matched/' + venue_group_client_id
    if (request_callback) {
      request_callback()
    }
    Pmp.Client.AsyncGet(url, onLoadFn)
  },

  _clickSelectClientProfile: function (event, element) {
    this.debug('_clickSelectClientProfile')
    var $btn_elem = $(element)
    var clicked_result_depth = parseInt($btn_elem.attr('data-result-index'), 10)
    var venue_group_client_id = $.trim($btn_elem.attr('venue_group_client_id'))
    var isSource = $btn_elem.parents('#source-client-profile-area').length > 0

    var is_firstname_populated = false,
      is_lastname_populated = false,
      is_email_populated = false,
      is_phone_populated = false

    var is_field_populated = function (selector) {
      var $field = $(selector)
      if ($field.size() <= 0) {
        return false
      }

      var field_val = $field.val()

      return field_val.length > 0
    }

    if (isSource) {
      is_firstname_populated = is_field_populated('#input-source-firstname')
      is_lastname_populated = is_field_populated('#input-source-lastname')
      is_email_populated = is_field_populated('#input-source-email')
      is_phone_populated = is_field_populated('#input-source-phone')
    } else {
      is_firstname_populated = is_field_populated('#input-firstname')
      is_lastname_populated = is_field_populated('#input-lastname')
      is_email_populated = is_field_populated('#input-email')
      is_phone_populated = is_field_populated('#input-phone')
    }

    metric.track('Slideout.selectClientProfile', {
      page: 'Reservations',
      depth: clicked_result_depth,
      is_source: isSource,
      is_firstname_populated: is_firstname_populated,
      is_lastname_populated: is_lastname_populated,
      is_email_populated: is_email_populated,
      is_phone_populated: is_phone_populated,
    })

    this.selectVenueGroupClientId(venue_group_client_id, undefined, undefined, undefined, isSource)
  },

  _resetPrivatePhone: function (should_hide, phone_number) {
    this._resetPrivatePhoneField('#input-phone', '#id_select-phone_locale', should_hide, phone_number)
  },

  _resetPrivatePhoneAlt: function (should_hide, phone_number) {
    this._resetPrivatePhoneField('#input-phone-alt', '#id_select-phone-locale-alt', should_hide, phone_number)
  },

  _resetPrivatePhoneField: function (phoneInput, phoneLocaleInput, should_hide, phone_number) {
    if (should_hide) {
      $(phoneInput).addClass('disabled')
      $(phoneInput).val(phone_number)
      if (phone_number) {
        $(phoneInput).prop('placeholder', phone_number)
      }
      $(phoneLocaleInput).hide()
    } else {
      $(phoneInput).removeClass('disabled')
      $(phoneInput).prop('placeholder', '')
      $(phoneLocaleInput).show()
    }
  },

  _resetPrivateEmail: function (should_hide, email_address) {
    this._resetPrivateEmailField('.email-input', should_hide, email_address)
  },

  _resetPrivateEmailAlt: function (should_hide, email_address) {
    this._resetPrivateEmailField('.email-input-alt', should_hide, email_address)
  },

  _resetPrivateEmailField: function (emailAddressField, should_hide, email_address) {
    var $field = $(emailAddressField)
    if (should_hide) {
      $field.addClass('disabled uneditable')
      $field.val(email_address)

      if (email_address) {
        $field.prop('placeholder', email_address)
      }
    } else {
      $field.prop('placeholder', '')
      $field.removeClass('disabled uneditable')
    }
  },

  _handleLoadSelectedClientProfile: function (data, suppress, isSource) {
    this.debug('_handleLoadSelectedClientProfile')
    var venue_group_client = data.payload.venue_group_client
    var DEFAULT_PHONE_LOCALE = 'US'
    if (isSource) {
      this._source_venue_group_client_id = venue_group_client.id
      $('#input-source-venue-group-client-id').val(venue_group_client.id)
      this.populateSourceDetailsForm(
        venue_group_client.first_name,
        venue_group_client.last_name,
        venue_group_client.phone_number_formatted,
        venue_group_client.phone_number_locale,
        venue_group_client.email_address,
        venue_group_client.company,
        venue_group_client.tags_group_display
      )
    } else {
      this._prev_firstname = $('#input-firstname').val()
      this._prev_lastname = $('#input-lastname').val()
      this._prev_phone = $('#input-phone').val()
      this._prev_email = $('#input-email').val()
      this._prev_phone_alt = $('#input-phone-alt').val()
      this._prev_email_alt = $('#input-email-alt').val()
      this._prev_status = $('select[name="status"]').val()
      this._prev_gender = $('input[name="gender"]:checked').val()

      this._venue_group_client_id = venue_group_client.id
      $('#input-venue-group-client-id').val(venue_group_client.id)

      // check for payment data, tab, etc.
      this._loadCreditCards(venue_group_client)

      // link- added for request linking form
      if (venue_group_client.first_name !== '') {
        $('#input-firstname,#link-input-firstname').val(venue_group_client.first_name)
      }
      if (venue_group_client.last_name !== '') {
        $('#input-lastname,#link-input-lastname').val(venue_group_client.last_name)
      }

      this._resetPrivatePhone(false)
      this._resetPrivateEmail(false)
      this._resetPrivatePhoneAlt(false)
      this._resetPrivateEmailAlt(false)

      if (venue_group_client.is_contact_info_viewable) {
        if (venue_group_client.phone_number_formatted) {
          var phoneLocale = venue_group_client.phone_number_locale === DEFAULT_PHONE_LOCALE ? DEFAULT_PHONE_LOCALE : 'INTL'
          $('#input-phone,#link-input-phone').val(venue_group_client.phone_number_formatted)
          $('#select-phone-locale,#link-select-phone-locale').val(phoneLocale)
        }
        if (venue_group_client.email_address) {
          $('.email-input').val(venue_group_client.email_address)
        }
        if (venue_group_client.phone_number_alt_formatted) {
          $('#input-phone-alt,#link-input-phone').val(venue_group_client.phone_number_alt_formatted)
          $('#select-phone-locale-alt').val(venue_group_client.phone_number_alt_locale)
        }
        if (venue_group_client.email_address_alt) {
          $('.email-input-alt').val(venue_group_client.email_address_alt)
        }
      } else {
        if (venue_group_client.phone_number_formatted) {
          this._resetPrivatePhone(true, venue_group_client.phone_number_formatted)
        }
        if (venue_group_client.email_address) {
          this._resetPrivateEmail(true, venue_group_client.email_address)
        }
        if (venue_group_client.phone_number_alt_formatted) {
          this._resetPrivatePhoneAlt(true, venue_group_client.phone_number_alt_formatted)
        }
        if (venue_group_client.email_address_alt) {
          this._resetPrivateEmailAlt(true, venue_group_client.email_address_alt)
        }
      }
      this.populateClientDetailsForm(venue_group_client)
    }

    var loadAreaClients = '#client-profile-area'
    var loadAreaSource = '#source-client-profile-area'
    var loadArea = isSource ? loadAreaSource : loadAreaClients

    if (isSource) {
      data.payload['wrap_id'] = 'source-client-profile-area'
    }
    Pmp.Client.Static.Page.RenderPart($(loadArea), data.template, data.payload)
    if (!isSource) {
      this.populateClientDisplayAreas(venue_group_client, isSource)
    }
    if (!suppress) {
      $(loadArea).css('display', 'block')
    }
    this._setClientSlideoutPosition()
  },

  _clickDeselectClientProfile: function (event) {
    this.debug('_clickDeselectClientProfile')
    var isSource = $(event.target).parents('#source-client-profile-area').length > 0
    if (isSource) {
      this.clearSourcedBy()
      this._source_search = true // force search on source
    } else {
      this._is_client_profile_selected = false
      this._source_search = false // force search on clients
      this._resetPrivatePhone(false)
      this._resetPrivateEmail(false)
      this._resetPrivatePhoneAlt(false)
      this._resetPrivateEmailAlt(false)
      $('#input-venue-group-client-id').val('')
      $('#input-firstname,#link-input-firstname').val(this._prev_firstname)
      $('#input-lastname,#link-input-lastname').val(this._prev_lastname)
      $('#input-phone,#link-input-phone').val(this._prev_phone)
      $('.email-input').val(this._prev_email)
      $('#input-phone-alt,#link-input-phone-alt').val(this._prev_phone_alt)
      $('.email-input-alt').val(this._prev_email_alt)
      $('select[name="status"]').val(this._prev_status)

      $('input[name=gender]').prop('checked', false)
      $('input[name=gender]').closest('.form-element').removeClass('checked')
      if (this._prev_gender === 'MALE' || this._prev_gender === 'FEMALE') {
        $('input[name=gender][value=' + this._prev_gender + ']').trigger('click')
      }

      $('#groups-area .closer').trigger('click')

      if (this._can_edit_client_tags) {
        $('#add-client-tags-container').find('.tag-item-container').remove()
      }

      // clear one liner, company, title, anniversary, and birthday
      $('#profile-one-liner').val('')
      $('#profile-company').val('')
      $('#profile-title').val('')
      $('#birthday-month').val('')
      $('#birthday-day').val('')
      $('#anniversary-month').val('')
      $('#anniversary-day').val('')
      this._updateProfileSalutation('')
      $('.client-codes #find-tags-input span').empty()

      $('select[name=choose_card]').children('.client-card').remove()

      this._setDefaultCard()
    }
    this._doClientProfileSearch()
  },

  populateClientDisplayAreas: function (venue_group_client) {
    // add tags
    $('#tags').empty()
    $('.client-codes #find-tags-input span').empty()
    if (venue_group_client.tags_group_display) {
      // populate editable tag inputs
      var client_tag_groups = _.map(venue_group_client.tags_group_display, function (x) {
        return _.extend({}, x, { font_color: sr.formatter.getContrastYIQ(x['tag_color']) })
      })
      var html = Nightloop.Templates.Widget.GenericTagsByGroupDisplay({
        tag_groups: client_tag_groups,
        can_view_private: true, // doesn't matter
        close_func: 'Pmp.Manager.Generic.Tags.onCloseTag(this)',
      })
      $('.client-codes #find-tags-input span').html(html)

      // add client tags to display on top of slideout
      for (var i in venue_group_client.tags_display) {
        var tag = venue_group_client.tags_display[i]
        $('#tags').append(
          $('<p/>')
            .css('text-shadow', 'none')
            .css('font-weight', 'bold')
            .css('color', sr.formatter.getContrastYIQ(tag.tag_color))
            .css('background-color', tag.tag_color)
            .sext(tag.tag_name_display)
        )
      }
    }

    // render stuff correctly
    if (Pmp.Manager.ReservationTagsLookup) {
      Pmp.Manager.ReservationTagsLookup.renderTags()
    }
    if (Pmp.Manager.ClientTagsLookup) {
      Pmp.Manager.ClientTagsLookup.renderTags()
      Pmp.Manager.ClientTagsLookup.sortTags('.tag-display')
    }

    // Initiate member group load
    GroupManager.load()
    GroupManager.showMembers(JSON.parse(venue_group_client.member_groups_json), $('.groups-prepend.member'))
  },

  populateSourceDetailsForm: function (firstName, lastName, phone, phoneLocale, email, company, tagGroupList) {
    var sourceTagGroups = _.map(tagGroupList, function (x) {
      return _.extend({}, x, { font_color: sr.formatter.getContrastYIQ(x['tag_color']) })
    })
    var html = Nightloop.Templates.Widget.GenericTagsByGroupDisplay({
      tag_groups: sourceTagGroups,
      can_view_private: true, // doesn't matter
      close_func: 'Pmp.Manager.Generic.Tags.onCloseTag(this)',
    })
    $('.source-codes #find-source-tags-input span').html(html)
    if (Pmp.Manager.SourceTagsLookup) {
      Pmp.Manager.SourceTagsLookup.renderTags()
      Pmp.Manager.SourceTagsLookup.sortTags('.tag-display')
    }
    $('#input-source-firstname').val(firstName)
    $('#input-source-lastname').val(lastName)
    $('#input-source-phone').val(phone)
    $('#input-source-phone-locale').val(phoneLocale)
    $('#input-source-email').val(email)
    $('#input-source-company').val(company)
  },

  populateClientDetailsForm: function (venue_group_client) {
    // NOTE: populateClientDisplayAreas already populates client group list
    // display tags
    if (this._can_edit_client_tags) {
      $('#add-client-tags-container').find('.tag-item-container').remove()
      for (var i = 0; i < venue_group_client.tags_display.length; i++) {
        var tag = venue_group_client.tags_display[i]
        var tag_name = tag['tag_name']
        var tag_group_id = tag['tag_group_id']
        var tag_group_name = tag['tag_group_name']
        var privacy = tag['is_private']
        var html = Nightloop.Templates.Widget.Tag({
          tag_name: tag_name,
          tag_group_id: tag_group_id,
          tag_group_name: tag_group_name,
          tag_color: 'white',
          is_private: privacy,
          close_func: 'Pmp.Common.TagCache._onCloseTag(this)',
          full_hash_only: true,
          full_hash_prefix: 'add',
        })
        $(html).insertBefore($('#add-client-tags-here'))
      }
    }

    if (venue_group_client.status) {
      $('[name="status"]').val(venue_group_client.status)
    }

    // populate gender
    $('input[name=gender]').prop('checked', false)
    $('input[name=gender]').closest('.form-element').removeClass('checked')
    if (venue_group_client.gender === 'MALE' || venue_group_client.gender === 'FEMALE') {
      $('input[name=gender][value=' + venue_group_client.gender + ']').trigger('click')
    }

    // populate birthday and anniversary
    $('#birthday-day').val(venue_group_client.birthday_day)
    $('#birthday-month').val(venue_group_client.birthday_month)
    $('#anniversary-day').val(venue_group_client.anniversary_day)
    $('#anniversary-month').val(venue_group_client.anniversary_month)

    // company title and one liner
    $('#profile-company').val(venue_group_client.company)
    $('#profile-title').val(venue_group_client.title)
    $('#profile-one-liner').val(venue_group_client.notes)
    this._updateProfileSalutation(venue_group_client.salutation)
  },

  _updateProfileSalutation: function (value) {
    value = value || ''
    var $salutation_select = $('#profile-salutation-select')
    if ($salutation_select.find('option[value="' + value + '"]').length > 0) {
      $salutation_select.val(value)
    } else {
      $salutation_select.val('custom')
    }
    $('#profile-custom-salutation').val(value)
    $salutation_select.change()
  },

  _replaceValidatorField: function (validatorList, field, newField) {
    for (var i = 0; i < validatorList.length; i++) {
      if (validatorList[i] === field) {
        validatorList[i] = newField
        break
      }
    }
  },

  _createCardOption: function (name, brand, last_4, className, value) {
    var card = $('<option/>')
    card.addClass(className)
    card.prop('value', value)

    var text = brand + ' ***** ' + last_4
    /*
       * At some point, this would be a nice feature,
       * so leaving the basic code. -HW
       *
      if (name) {
        text = name + ' - ' + brand + ' ****' + last_4;
      }
      */

    card.sext(text)
    return card
  },

  _setDefaultCard: function () {
    var $lists = $('select[name=choose_card]')
    $lists.closest('.form-element').show()

    // Need to differentiate between forms, hence .each
    $lists.each(function () {
      var $list = $(this)

      if ($list.children().length === 1) {
        $list.closest('.form-element').hide()
        $list.trigger('change')
        return
      }

      if ($list.val()) {
        return
      }

      $list.find('option:first-child').prop('selected', true)
      $list.trigger('change')
    })
  },

  _loadCreditCards: function (vgc) {
    var deferred = BillingService.listCards(vgc.id)
    this._card_ids = []
    var that = this
    deferred.done(function (cards) {
      var _card, _name
      var $list = $('select[name=choose_card]')
      $list.children('.client-card').remove()
      // Attached card should take precedence, and not
      // be doubled if it's both saved and attached to
      // the current reservation.
      var attached_id = null
      if ($list.children('.attached-card').length) {
        attached_id = $list.children('.attached-card').eq(0).prop('value')
      }
      for (var index in cards) {
        card = cards[index]
        that._card_ids.push(card.card_id)
        if (card.card_id === attached_id) {
          continue
        }
        _name = vgc.first_name + ' ' + vgc.last_name
        _card = that._createCardOption(_name, card.brand, card.last_4, 'client-card', card.card_id)
        $list.prepend(_card)
      }
      that._setDefaultCard()
    })
  },

  setSelectedTables: function (table_id_list) {
    var seating_area_map = {}
    if (table_id_list) {
      $('#select-seating-table').val(table_id_list[0])

      var istart = 1
      if ($('#table-inputs').length) {
        istart = 0
      }

      for (var i = istart; i < table_id_list.length; ++i) {
        this._insertSingleTable(table_id_list[i])
      }

      for (var i = 0; i < table_id_list.length; ++i) {
        seating_area_map[this._tableids_to_seatingareaids[table_id_list[i]]] = true
      }
    }
    return Object.keys(seating_area_map)
  },

  setSelectedCustomTables: function (table_id_list) {
    var seating_area_map = {}
    if (table_id_list) {
      for (var i = 0; i < table_id_list.length; ++i) {
        var is_required = i === 0
        this._insertSingleTable(table_id_list[i], is_required, 'is_custom_table')
        seating_area_map[this._tableids_to_seatingareaids[table_id_list[i]]] = true
      }
    }
    return Object.keys(seating_area_map)
  },

  getShiftDurationByPartySize: function (shift) {
    // get selected party size
    var partySize = parseInt($('#the-real-party-size').val())
    if (!partySize) {
      partySize = 0
    }

    // either 0, the default, or fixed party size
    if (partySize in shift.duration_minutes_by_party_size) {
      return shift.duration_minutes_by_party_size[partySize]
    }

    // greater than max party size in the dict
    return shift.duration_minutes_by_party_size[-1]
  },

  resetShiftDuration: function () {
    if (this._shiftDict) {
      var shift_persistent_id = $('#res_shift').val()
      var shift = this._shiftDict[shift_persistent_id]
      if (shift) {
        $('#select-duration').val(this.getShiftDurationByPartySize(shift))
      } else if (!$('#select-duration option[value=""]').length) {
        $('#select-duration').val(60)
      }
    }
  },

  bookNew: function (date, table_id_list, preload, force_request, shift_persistent_id) {
    var minDate = $.datepicker.parseDate('mm-dd-yy', this._venue_today_url_param)
    if (date < minDate) {
      date = minDate
    }
    this.HAS_SLIDEOUT_OPENED = true
    this.clear()
    this._doBookNew(date, table_id_list, preload, force_request, shift_persistent_id)
  },

  _doBookNew: function (date, table_id_list, preload, force_request, shift_persistent_id) {
    var that = this
    var actualDateUrlParam = dateFormat(date, 'mm-dd-yyyy')
    if (actualDateUrlParam) {
      this._actualDateUrlParam = actualDateUrlParam
    }
    this._setAndFormatDate(date)
    this._updateDateButtons(actualDateUrlParam)

    if (!that._isShiftsAppliedForDay(actualDateUrlParam)) {
      that._loadShifts(actualDateUrlParam, 'skip_change_shift', function () {
        that._doBookNew(date, table_id_list, preload, force_request, shift_persistent_id)
      })
      return
    }

    if (force_request || this._reservations_require_approval) {
      $('.form-element.reservation-codes').hide()
      $('#more-details').hide()
      $('.form-element.select.duration').hide()
      $('.form-element.select.departure').hide()
      $('.form-element.select.table').hide()
      $('.form-element.public-notes').hide()
      if (!this._reservations_require_approval) {
        $('[name="force_request"]').val(1)
      }

      $('#save-reservation .new').sext('Request')

      $('.send-client-email-label').sext('Send request notification to above email')
      $('.form-element.textarea.venue_notes > label > p.label').sext(
        'Request notes (will NOT be included on request notification to guest)'
      )
      $('#main-interface').addClass('new-request no-bank')
      // request notification email is unchecked by default
      if ($('#id_send_client_email').prop('checked')) {
        $('#id_send_client_email').trigger('click')
      }
      $('#source-interface').hide()

      this._toggleReminderEmailDisplayForShift()

      // Hide SMS booking notification checkbox when booking a request. We currently support this only on resses.
      $('#send_client_sms_row').hide()
    } else {
      $('#main-interface').addClass('new-reservation')
      if (this._send_confirmation_email_by_default) {
        if (!$('#id_send_client_email').prop('checked')) {
          $('#id_send_client_email').trigger('click')
          $('.form-element.public-notes').show()
        }
      } else {
        $('.form-element.public-notes').hide()
      }
      if (this._send_confirmation_sms_by_default) {
        if (!$('#id_send_client_sms').prop('checked')) {
          $('#id_send_client_sms').trigger('click')
        }
      }
      $('#source-interface').show()
    }

    $('#save-reservation').removeClass('disabled pending valid')
    Interface.clear('#main-interface')

    // set locale
    var phone_locale = this._locale === 'en_US' ? 'US' : 'INTL'
    $('#select-phone-locale').val(phone_locale)

    this.LOAD_LOCK = true

    if (preload) {
      if (this._is_dining_class) {
        $('#res_shift').val(preload.res_shift_preload)
        this._changeShift(preload.res_shift_preload)
      }
      this.table_ids_list = preload.table_id
      $('#table-inputs').html('')
      $('input[name="table_ids"]').val(preload.table_id)
      this._select_time(preload.time_selected)
      this._updatePartyInterface(preload.max_party_preload)
    } else {
      this._updatePartyInterface(2)
    }

    if (this.time_selected) {
      this.EDIT_LOCK = true
      $('#time-display').show()
      $('#time-editor').hide()
      $('#time-display strong').sext(this.time_selected)
    }

    // important that this happens early
    const haveShiftPreload = preload && preload.res_shift_preload
    if (!haveShiftPreload && (shift_persistent_id || $('#shift-select').val())) {
      $('#res_shift').val(shift_persistent_id ? shift_persistent_id : $('#shift-select').val())
      $('#res_shift').trigger('change')
    }

    // shift default duration gets flushed by clear; reset here
    this.resetShiftDuration()

    $('#is_concierge').val('')
    $('#res_status').val('NOT_RECONCILED')
    $('#find-tags-input span').html('')
    $('#profile-company-lookup').empty()
    $('#source-company-lookup').empty()

    var seating_area_ids = this.setSelectedTables(table_id_list)
    var seating_area_to_select = seating_area_ids.length === 1 ? seating_area_ids[0] : this._default_seating_area

    Interface.openslide('#main-interface')

    $('#edit-reservation').scrollTop(0)

    this._populate_select_options('#select-est-arrival-time', this.full_eta_options)

    CostOptions.update('#edit-reservation')

    $('#select-seating-area').val(seating_area_to_select)
    if (this._is_nightlife_class) {
      $('#select-seating-area').change()
    }

    this._toggleSmsRemindersDisplay()
    // hacky css
    this.updateTabHeight(true)
    this.LOAD_LOCK = false

    this._setDefaultCard()
    this._fetchAvailability(null, preload)

    if (this._isReservationRequestForm()) {
      $('#time-range-slider-container').show()
    } else {
      $('#time-range-slider-container').hide()
    }

    this._toggleReminderEmailDisplayForShift()
    this._mountStripe()

    // select booked by default
    $('#booked-by').val(this._booked_by_key)

    // for nightlife venues

    if (preload && this._is_nightlife_class) {
      if (preload.time_selected) {
        $('#select-est-arrival-time').val(preload.time_selected)
      }
      if (preload.duration) {
        $('#select-duration').val(preload.duration)
      }
    }
  },

  _setAndFormatDate: function (date) {
    if (!$('#res-date-selector').length) {
      return
    }

    if (date) {
      $('#res-date-selector').datepicker('setDate', date)
    }

    // NOTE: This formats the date without a year, but allows dates for future years.
    Pmp.Utils.formatDatePicker('#res-date-selector', true)
  },

  // where options is an array, and the options have the same value and text
  _populate_select_options: function (selector, options) {
    $(selector).find('option').remove()
    for (var i = 0; i < options.length; ++i) {
      var option = $('<option/>').val(options[i]).sext(options[i])
      $(selector).append(option)
    }
  },

  _toggleSmsRemindersDisplay: function () {
    $('#send-reminders-checkbox-container').find('.send-reminder-sms-options').toggle(this._reminders_sms_enabled)
  },

  renderPosActuals: function (actual) {
    var renderRow = function (title, value, classes) {
      var title = $('<em />').sext(title)
      var value = $('<span />').sext(value)
      var row = $('<p />').addClass('info').append(title).append(value)
      if (classes) {
        row.addClass(classes)
      }
      return row
    }
    var pos_block = $('#tab-display-res .actual-pos')
    $('#tab-display-res .actual-nonpos').hide()
    pos_block.empty()
    var onsiteRows = [
      renderRow('Check No.', actual.check_numbers, 'sub'),
      renderRow('Served by', actual.served_by, 'sub'),
      renderRow('Subtotal (net)', actual.pos_aggregate_items.subtotal, 'sub'),
      renderRow('Tax', actual.pos_aggregate_items.tax, 'sub'),
      renderRow('Tip', '(' + actual.pos_aggregate_items.tip_percentage + ') ' + actual.pos_aggregate_items.tip, 'sub'),
    ]
    if (actual.pos_aggregate_items.admin_fee) {
      onsiteRows.push(
        renderRow('Admin Fee', '(' + actual.pos_aggregate_items.admin_fee_percentage + ') ' + actual.pos_aggregate_items.admin_fee, 'sub')
      )
    }
    onsiteRows.push(renderRow('Total', actual.pos_aggregate_items.total, 'sub'))

    var itemsOrderedRows = []
    for (var i = 0; i < actual.pos_aggregate_items.items.length; i++) {
      var item = actual.pos_aggregate_items.items[i]
      var row = renderRow('(' + item.quantity + ') ' + item.name, item.price, 'sub')
      itemsOrderedRows.push(row)
    }
    pos_block.append(renderRow('Onsite spend', ''))
    pos_block.append(onsiteRows)
    pos_block.append(renderRow('Items ordered', ''))
    pos_block.append(itemsOrderedRows)
    pos_block.show()
  },

  _modifyEmailWorthyFieldHasChanged: function (e) {
    if (e && !e.hasOwnProperty('originalEvent')) {
      return
    }

    const { sms_booking_notification_enabled: isSmsNotificationsEnabled } = globalInit.venueSettings
    const fullSizeClass = isSmsNotificationsEnabled ? 'modify-res-full-with-sms' : 'modify-res-full'
    const smallSizeClass = isSmsNotificationsEnabled ? 'modify-res-small-with-sms' : 'modify-res-small'
    if ($('#id_send_update_email').is(':checked')) {
      $('#main-interface').addClass(fullSizeClass)
      $('#main-interface').removeClass(smallSizeClass)
    } else {
      $('#main-interface').addClass(smallSizeClass)
      $('#main-interface').removeClass(fullSizeClass)
    }
  },

  _showProblem: function (problem) {
    this._hideProblem()
    if (problem) {
      this.$res_problem_desc.text(problem.problem_name)
      if (problem.is_major) {
        this.$res_problem_alert.addClass('major-problem')
      } else {
        this.$res_problem_alert.addClass('minor-problem')
      }
    }
  },

  _hideProblem: function () {
    this.$res_problem_alert.removeClass('major-problem minor-problem')
  },

  getAutoAssignmentAndProblemResPromise: function (actual_id_to_force_update, shiftPersistentId) {
    return JQueryAssignmentsAndProblemsService.getAssignmentsAndProblems(
      this._venue_url_key,
      this._getActiveDateUrlParam(),
      $('#shift-select').val() || shiftPersistentId,
      Pmp.Manager.Global._actual_problems_enabled,
      Pmp.Manager.Global._auto_assign_enabled,
      actual_id_to_force_update
    )
  },

  showReservation: function (
    actual_id,
    actual,
    load_auto_assignments_and_actual_problems,
    shiftPersistentId,
    successHandler,
    actualDateUrlParam
  ) {
    // disable this for dining until tables and shifts are loaded
    if (this._is_dining_class && (!this._tables_loaded || !this._shifts_loaded)) {
      this._pending_show_reservation = function () {
        ReservationSlideOut.showReservation(actual_id, actual)
      }
      return
    }

    if (actualDateUrlParam) {
      this._actualDateUrlParam = actualDateUrlParam
    }

    //  Retained to preserve API
    if (!load_auto_assignments_and_actual_problems) {
      load_auto_assignments_and_actual_problems = this.getAutoAssignmentAndProblemResPromise(undefined, shiftPersistentId)
    }

    this.HAS_SLIDEOUT_OPENED = true
    this.clear()

    this._populate_select_options('#select-est-arrival-time', this.full_eta_options)

    $('#save-reservation').removeClass('disabled pending valid')
    $('#main-interface').removeClass('editing')

    const url = `/api-yoa/reservation/${this.venueId}/actual/${actual_id}`

    var that = this

    function _showActual(actual, is_past_res, disable_edit) {
      var date = $.datepicker.parseDate('mm-dd-yy', actual.date_urlparam)

      that._setAndFormatDate(date)

      if (!that._isShiftsAppliedForDay(actual.date_urlparam)) {
        that._loadShifts(actual.date_urlparam, 'skip_change_shift', function () {
          _showActual(actual, is_past_res, disable_edit)
        })
        return
      }

      ReservationSlideOut._hideProblem()

      that.EDIT_LOCK = true
      that.LOAD_LOCK = true

      that._venue_group_client_id = actual.venue_group_client.id
      that._actual_is_auto_assign = actual.is_auto_assign
      that._actual_is_custom_assign = actual.is_custom_assign

      that.followers.entity_id = actual.id
      that.followers.kind = 'Actual'
      Interface.sploosh(actual, '#main-interface')
      Interface.sploosh(actual, '#tab-reservation')
      Interface.sploosh(actual.venue_group_client, '#main-interface')
      that.updateTabHeight()
      $.when(load_auto_assignments_and_actual_problems).done(function (auto_assignments_and_actual_problems) {
        var auto_assignments = auto_assignments_and_actual_problems && auto_assignments_and_actual_problems.assignments
        var actual_problems = auto_assignments_and_actual_problems && auto_assignments_and_actual_problems.problems
        var is_auto_assignment_available = auto_assignments && auto_assignments[actual.id]
        if (
          Pmp.Manager.Global._auto_assign_enabled &&
          (actual.is_auto_assign || (is_auto_assignment_available && auto_assignments[actual.id].is_reassigned))
        ) {
          var auto_assign_display = ''
          var $img_src = $('<img />').attr('src', Pmp.Settings.MEDIA_URL + 'images/icons/shuffle-icon-dark_2x.png')
          $img_src.addClass('slideout-shuffle-icon')

          if (is_auto_assignment_available) {
            auto_assign_display = auto_assignments[actual.id].table_codes.join(', ')
          }
          var $tables_display = $('.auto.-table_codes_display')
          $tables_display.empty()
          $tables_display.append($img_src).append(auto_assign_display)
        }
        if (Pmp.Manager.Global._actual_problems_enabled && actual_problems && actual_problems[actual.id]) {
          ReservationSlideOut._showProblem(actual_problems[actual.id])
          that.updateTabHeight()
        }
      })

      if (actual.billing_profile_expired) {
        $('#charge-form-container').hide()
      } else {
        $('#charge-form-container').show()
        that._mountStripe('ptabstripe-card-mount')
      }

      $('#input-actual-id').val(actual.id)
      $('#input-venue-group-client-id').val(actual.venue_group_client.id)

      // eujern (12/16/16): due to how we're currently handling table combos
      that.table_ids_list = actual.table_ids_list.split(',').sort().join(',')

      if (actual.shift_persistent_id && that._shiftDict) {
        that._select_time(actual.est_arrival_time_display)
        $('#select-shift').val(actual.shift_persistent_id)
        that._changeShift(actual.shift_persistent_id)
        $('#time-display span.overbooking').hide()
      }

      $('input[name=party_size_override_button]').prop('checked', false)
      $('input[name=party_size_override_button]').closest('.form-element').removeClass('checked')

      that._updatePartyInterface(actual.total_guests)

      // disable this for now, may enable later
      //that._loadEventNotes(actual.date_urlparam);

      // Set the calendar date to the reservation date (else will default to current url param)
      that._setAndFormatDate(date)

      that._updateDateButtons(actual.date_urlparam)

      $('#time-display span.pacing').html('')
      $('#time-display span.possible-pacing').html('')

      // configure main interface statuses
      if (!actual.conversation) {
        $('#main-interface').addClass('no-chat')
      }

      var display_status = actual.status
      if ($.inArray(display_status, Pmp.Manager.Global._statuses['in-service']) !== -1) {
        display_status = 'COMPLETE'
      }
      $('#main-interface').addClass(display_status.toLowerCase())

      if (is_past_res) {
        $('#main-interface').addClass('past_day')
      } else {
        $('#main-interface').removeClass('past_day')
      }

      $('.client-profile-link').attr('href', that.base_url + '/clients/profile/' + actual.venue_group_client.id)

      $('#new-promoter-name').val('')
      $('#new-promoter-name-container').hide()
      $('#booked-by-input').find('select[name=booked_by_alt]').parent().remove()
      $('#res_status').val(actual.status)
      $('.status-switcher').show()
      $('#status-' + actual.status)
        .parent()
        .hide()

      var table_index = 0
      while (true) {
        var add_table_el = $('#table-added-' + table_index)
        if (!add_table_el.length) {
          break
        }
        add_table_el.parent().remove()
      }

      if (actual.client_photo && actual.client_photo.medium) {
        $('#default-thumb').hide()
        $('#client-thumb').attr('src', '/.h/download/' + actual.client_photo.medium)
        $('#client-thumb').show()
      } else {
        $('#default-thumb').show()
        $('#client-thumb').attr('src', '')
        $('#client-thumb').hide()
      }

      // render pos data
      if (actual.has_pos_tickets) {
        that.renderPosActuals(actual)
      } else {
        $('#tab-display-res .actual-pos').hide()
        $('#tab-display-res .actual-nonpos').show()
      }

      // render sourced by info and edit forms
      if (actual.source_client_id) {
        that._source_venue_group_client_id = actual.source_client_id
        $('#input-source-venue-group-client-id').val(actual.source_client_id)
        $('.sourced-by-container').show()
        that.populateSourceDetailsForm(
          actual.source_client_first_name,
          actual.source_client_last_name,
          actual.source_client_phone,
          actual.source_client_phone_locale,
          actual.source_client_email,
          actual.source_client_company,
          actual.source_client_tags_group
        )
        $('#interface-client .phone-display').html('Call source')
        $('.source_client_link').attr('href', that.base_url + '/clients/profile/' + actual.source_client_id)
        that.expandSource()
      } else {
        that.clearSourcedBy(true)
      }

      that._toggleReminderEmailDisplayForShift()
      that._toggleSmsRemindersDisplay()

      Interface.openslide('#main-interface')

      $('#tab-display-res').scrollTop(0)

      $('[name=res_type]').val(actual.system_class)

      if (actual.is_concierge_reservation) {
        $('#is_concierge').val(true)
      } else {
        $('#is_concierge').val('')
      }

      if (actual.bookedby_view !== undefined) {
        if (!actual.is_concierge_reservation) {
          if ($('[name=booked_by] option[value="' + actual.bookedby_view.key + '"]').length == 0) {
            // if the booked by name is not in the list, add it to the options (could have been deleted)
            var option = $('<option selected/>').val(actual.bookedby_view.key).sext(actual.bookedby_view.full_name)
            $('[name=booked_by]').append(option)
          }
          that._booked_by_key = actual.bookedby_view.key
          $('[name=booked_by]').val(actual.bookedby_view.key)
        }
      } else {
        $('[name=booked_by]').val(actual.via)
      }

      if (actual.bookedby_alt_view !== undefined && actual.bookedby_alt_view.length > 0) {
        for (var i = 0; i < actual.bookedby_alt_view.length; ++i) {
          that._insertSingleBookedByAlt(actual.bookedby_alt_view[i].key)
        }
      }

      // followers
      that.followers.refresh(actual.followers, that._booked_by_content)

      $('#select-est-arrival-time').val(actual.arrival_time_display)
      $('#select-duration').val(actual.duration)

      // only set to non null or non empty shifts.
      // otherwise use what's already there:
      // - for dining, this would just pick the last used shift
      // - for nightlife, will pick the default shift
      // - assumes template is initialized with a shift id
      if (actual.shift_persistent_id && actual.shift_persistent_id.length > 0) {
        $('[name=shift_persistent_id]').val(actual.shift_persistent_id)
      }

      // TODO enable/disable DELETE button
      if (!actual.is_non_3p_reservation) {
        $('#booked-by-interface').hide()
        $('#booked-by-interface-3p').show()
      } else {
        $('#booked-by-interface').show()
        $('#booked-by-interface-3p').hide()
      }

      // sploosh() breaks down here... both actual and venue_group_client has a notes field;
      // same with first_name and last_name.  We want the values from the actuals for these fields.
      $('#venue-private-notes-textarea').val(actual.private_notes)
      $('#venue-public-notes-textarea').val(actual.public_notes)

      $('p.notes').sext(actual.private_notes)
      $('#venue-update-message-textarea').val(actual.public_notes)

      // Formatting of data specific to the view
      // is a front end task.

      var spend = Pmp.Utils.spendToNum(actual.venue_group_client.total_spend_formatted_no_decimals)
      spend = that._currency_symbol + Pmp.Utils.formatBigNum(spend)
      $('.-total_spend_formatted_no_decimals').sext(spend)

      var $notes = $('#main-interface p.notes')
      $notes.show()
      if (!$notes.sext()) {
        $notes.hide()
      }

      var $createPayment = $('#main-interface #add-charge-div')
      $createPayment.show()
      if (actual.payments_expired) {
        $createPayment.hide()
      }

      $('#input-firstname').val(actual.first_name)
      $('#input-lastname').val(actual.last_name)
      Pmp.Common.Reservation.Validator.trimPrivateNotesTextArea()
      Pmp.Common.Reservation.Validator.trimPublicNotesTextArea()

      $('#main-interface').removeClass('new-reservation new-request').addClass('edit-reservation no-bank')

      // Update Email and SMS Destinations
      if (actual.send_client_email_destination) {
        $('#select-send-update-email-destination').val(actual.send_client_email_destination)
      }
      if (actual.send_client_sms_destination) {
        $('#select-send-update-sms-destination').val(actual.send_client_sms_destination)
      }

      // Reminder Emails
      var $sendReminderEmailCheckbox = $('#id_send_reminder_email')
      if (actual.send_reminder_email !== $sendReminderEmailCheckbox.prop('checked')) {
        $sendReminderEmailCheckbox.trigger('click')
      }
      if (actual.send_reminder_email_destination) {
        $('#select-send-reminder-email-destination').val(actual.send_reminder_email_destination)
      }

      // Reminder SMS
      var $sendReminderSmsCheckbox = $('#id_send_reminder_sms')
      if (actual.send_reminder_sms !== $sendReminderSmsCheckbox.prop('checked')) {
        $sendReminderSmsCheckbox.trigger('click')
      }
      if (actual.send_reminder_sms_destination) {
        $('#select-send-reminder-sms-destination').val(actual.send_reminder_sms_destination)
      }

      if (disable_edit) {
        $('#main-interface').addClass('view-only')
      }

      CostOptions.update('#edit-reservation', actual)

      if (actual.comps_price_type) {
        $('.comp-options input[value=' + actual.comps_price_type + ']').trigger('click')
      }

      // for chat - swoosh breaks down.
      $('#chat-entity_id').val(actual.id)
      if (actual.reservation_tags) {
        var reservation_tags = _.map(actual.reservation_tags, function (x) {
          return _.extend({}, x, { font_color: sr.formatter.getContrastYIQ(x['tag_color']) })
        })
        var html = Nightloop.Templates.Widget.GenericTagsByGroupDisplay({
          tag_groups: reservation_tags,
          can_view_private: true, // doesn't matter,
          close_func: 'Pmp.Manager.Generic.Tags.onCloseTag(this)',
          can_manage_restricted_tags: Pmp.Manager.Global._can_manage_restricted_tags,
        })
        $('.reservation-codes #find-tags-input span').html(html)
        if (Pmp.Manager.ReservationTagsLookup) {
          Pmp.Manager.ReservationTagsLookup.renderTags()
        }
      }
      if (actual.venue_group_client.tags_group_display) {
        var client_tag_groups = _.map(actual.venue_group_client.tags_group_display, function (x) {
          return _.extend({}, x, { font_color: sr.formatter.getContrastYIQ(x['tag_color']) })
        })
        var html = Nightloop.Templates.Widget.GenericTagsByGroupDisplay({
          tag_groups: client_tag_groups,
          can_view_private: true, // doesn't matter
          close_func: 'Pmp.Manager.Generic.Tags.onCloseTag(this)',
          can_manage_restricted_tags: Pmp.Manager.Global._can_manage_restricted_tags,
        })
        $('.client-codes #find-tags-input span').html(html)
        if (Pmp.Manager.ClientTagsLookup) {
          Pmp.Manager.ClientTagsLookup.renderTags()
          Pmp.Manager.ClientTagsLookup.sortTags('.tag-display')
        }
      }

      if (actual.is_res_editable) {
        $('#edit-res-button').show()
      } else {
        $('#edit-res-button').hide()
      }
      // over. breath now

      if (!$('#display-reservation p.note span').html()) {
        $('#display-reservation p.note').hide()
      } else {
        $('#display-reservation p.note').show()
      }

      if (!$('#tab-profile p.note').html()) {
        $('#tab-profile p.note').hide()
      } else {
        $('#tab-profile p.note').show()
      }

      if (actual.payments_billing_profile) {
        var _card = that._createCardOption(
          actual.payments_name_on_card,
          actual.payments_card_type,
          actual.payments_last_four,
          'attached-card',
          actual.payments_card_id
        )
        var $list = $('select[name=choose_card]')
        $list.prepend(_card)

        that._setDefaultCard()
      }
      that._loadCreditCards(actual.venue_group_client)

      that.populateResFormContactInfo(actual)
      that.populateClientDetailsForm(actual.venue_group_client)
      that.populateClientDisplayAreas(actual.venue_group_client)

      // do a preliminary fetch
      Chatter.fetch(true)

      Attachments.initWithContext(Pmp.Settings.MEDIA_URL, actual.concierge_or_client_name)
      Attachments.fetch()

      // For dining, at least, this section only matters due to the change status form post and
      // initially what you see when edit form first appears.
      // Then, the table area gets fully repopulated as part of availability callback to _loadTableMenu
      var seating_area_ids = []
      // Before selecting tables, first reset the table list to all, so they are all showing
      $('#select-seating-area').val('').change()
      var $tables = $('#preloaded-tables')
      $('#override_table_combos').toggle(actual.is_custom_assign)
      if (Pmp.Manager.Global._auto_assign_enabled && actual.is_auto_assign) {
        $tables.append($('<option value="' + that._AUTO_ASSIGN_VAL + '" selected>Auto Assign</option>'))
        $('#table-inputs').append(
          $('<input/>').prop({
            value: that._AUTO_ASSIGN_VAL,
            name: 'table_ids',
            type: 'hidden',
          })
        )
      } else if (actual.is_custom_assign) {
        $tables.append($('<option value="' + that._CUSTOM_ASSIGN_VAL + '" selected>Custom</option>'))
        $('#table-inputs').append(
          $('<input/>').prop({
            value: that._CUSTOM_ASSIGN_VAL,
            name: 'table_ids',
            type: 'hidden',
          })
        )
        if (actual.table_ids_list !== undefined && actual.table_ids_list !== '') {
          var table_id_strings = actual.table_ids_list.split(',')
          that.setSelectedCustomTables(table_id_strings)
        }
      } else if (actual.table_ids_list !== undefined && actual.table_ids_list !== '') {
        var table_id_strings = actual.table_ids_list.split(',')
        seating_area_ids = that.setSelectedTables(table_id_strings)
      } else {
        that.setSelectedTables(null)
      }

      var seating_area_to_select = seating_area_ids.length === 1 ? seating_area_ids[0] : actual.venue_seating_area_id
      $('#select-seating-area').val(seating_area_to_select)
      if (that._is_nightlife_class) {
        $('#select-seating-area').change()
      }

      // This is required otherwise validation occurs unnecessarily
      if ($('#id_send_client_email').prop('checked')) {
        $('#id_send_client_email').trigger('click')
      }

      that.LOAD_LOCK = false

      if (successHandler) {
        successHandler()
      }
    }

    var booked_row = $('#actual-row-' + actual_id)
    if (actual) {
      that._actual = actual
      _showActual(actual, false, false)
      $('.list-block .standard-row').removeClass('selected')
      $(booked_row).addClass('selected')
    } else {
      $.ajax({
        url: url,
        dataType: 'json',
        success: function (response) {
          // you probably don't have access to this reservation or there was a server error
          if (!response.data) {
            return
          }

          const actual = response.data.actual
          const is_past_res = response.data.is_past_res
          const disable_edit = response.data.disable_edit
          that._actual = actual
          _showActual(actual, is_past_res, disable_edit)
          $('.list-block .standard-row').removeClass('selected')
          $(booked_row).addClass('selected')
        },
      }).then(function () {
        // setTimeout(function(){
        //   var intro_controller= Pmp.Utils.IntroFactory('reservation_slide');
        //   intro_controller.start();
        // }, 500);
      })
    }
  },

  populateResFormContactInfo: function (actual) {
    const DEFAULT_PHONE_LOCALE = 'US'

    var $emailInput = $('.email-input')
    var $emailAltInput = $('.email-input-alt')
    var $phoneLocaleSel = $('#select-phone-locale')
    var $phoneAltLocaleSel = $('#select-phone-locale-alt')
    var $phoneLocaleContainer = $('#phone-locale')
    var $phoneAltLocaleContainer = $('#phone-locale-alt')
    var $phoneInput = $('#input-phone')
    var $phoneAltInput = $('#input-phone-alt')
    var $emailInputPrimary = $('#input-email')
    $emailInput.val(actual.venue_group_client.email_address)
    $emailAltInput.val(actual.venue_group_client.email_address_alt)
    $phoneLocaleSel.val(actual.venue_group_client.phone_number_locale === DEFAULT_PHONE_LOCALE ? DEFAULT_PHONE_LOCALE : 'INTL')
    $phoneAltLocaleSel.val(actual.venue_group_client.phone_number_alt_locale === DEFAULT_PHONE_LOCALE ? DEFAULT_PHONE_LOCALE : 'INTL')
    if (!actual.venue_group_client.is_email_address_editable) {
      $emailInput.addClass('disabled')
      $emailAltInput.addClass('disabled')
    }
    if (!actual.venue_group_client.is_email_address_viewable) {
      $emailInput.addClass('disabled')
      $emailInput.prop('placeholder', $emailInputPrimary.val())
      $emailAltInput.addClass('disabled')
      $emailAltInput.prop('placeholder', $emailAltInput.val())
    }
    if (!actual.venue_group_client.is_phone_editable) {
      $phoneLocaleContainer.hide()
      $phoneInput.addClass('disabled')
      $phoneAltLocaleContainer.hide()
      $phoneAltInput.addClass('disabled')
    }
    if (!actual.venue_group_client.is_phone_viewable) {
      $phoneInput.prop('placeholder', $phoneInput.val())
      $phoneInput.val('')
      $phoneAltInput.prop('placeholder', $phoneAltInput.val())
      $phoneAltInput.val('')
    }
  },

  showProfile: function (venue_id, venue_group_client_id) {
    $.ajax({
      url: '/manager/' + venue_id + '/clients/profile/' + venue_group_client_id + '/actuals',
      dataType: 'json',
      success: function (response) {
        var cancels = 0
        var no_shows = 0

        $('#reservation-history').empty()

        if (response.error) {
          return
        }

        for (var i in response.payload.content.actual_dicts) {
          actual = response.payload.content.actual_dicts[i].actual
          if (actual.status === 'NO_SHOW') {
            ++no_shows
          } else if (actual.status === 'CANCELED') {
            ++cancels
          }
          var p = $('<p/>').prop('class', 'history-item')
          var em = $('<em/>').sext(actual.date_formatted_short)
          var span_name = $('<span/>').prop('class', 'name').sext(actual.venue_name)

          var via = actual.via
          if (actual.via) {
            via = 'via ' + via
          }
          var span_via = $('<span/>').prop('class', 'via').sext(via)

          var min = actual.min_price_formatted
          if (min !== 'COMP' && min !== 'No Min' && min) {
            min += ' min'
          }
          var span_min = $('<span/>').prop('class', 'min').sext(min)

          var spend = actual.final_bill_formatted_no_decimals
          if (spend) {
            spend += ' spend'
          }
          var span_spend = $('<span/>').prop('class', 'spend').sext(spend)
          var span_note = $('<span/>').prop('class', 'note').sext(actual.notes)
          p.append(em, span_name, span_via, span_min, span_spend, span_note)
          $('#reservation-history').append(p)
        }

        var no_shows_plural = no_shows > 1 ? 's' : ''
        var cancels_plural = cancels > 1 ? 's' : ''
        $('#no-shows').html('<em>' + no_shows + '</em> no show' + no_shows_plural)
        $('#cancels').html('<em>' + cancels + '</em> cancel' + cancels_plural)
      },
    })
  },

  submitNew: function (handler, params) {
    var isBookingRequest = $('[name="force_request"]').val() === '1' || this._reservations_require_approval
    var bookActualUrl = '/api-yoa/reservation/' + this.venueId + '/book'
    var bookRequestUrl = '/api-yoa/request/' + this.venueId + '/book'
    var url = isBookingRequest ? bookRequestUrl : bookActualUrl
    var extra_params = {}
    if (this._is_dining_class) {
      extra_params = {
        start_time: dateFormat(new Date(this.ts_from_selected), this._timeFormat),
        end_time: dateFormat(new Date(this.ts_to_selected), this._timeFormat),
      }
    }

    if (params) {
      Object.keys(params).forEach(key => {
        extra_params[key] = params[key]
      })
    }

    this._logNewReservationFields()
    return this._submit(
      url,
      function (response) {
        if (handler) {
          handler(response)
        }
        var responseData = response.payload || response.data
        if (responseData.actual && responseData.actual.id) {
          var newactual = responseData.actual
          ReservationSlideOut.showReservation(newactual.id, newactual)
        }
      },
      $.param(extra_params)
    )
  },

  _logNewReservationFields: function () {
    var form_params = $('#book-res').serializeObject()
    metric.track('Slideout.saveNewReservation', {
      hasAnniversary: form_params.anniversary_month !== '',
      hasSalutation: form_params.salutation !== '',
      hasBirthday: form_params.birthday_month !== '',
      hasCompany: form_params.company !== '',
      hasEmail: form_params.email !== '',
      hasArrivalTime: form_params.est_arrival_time !== '',
      hasFirstName: form_params.first_name !== '',
      hasLastName: form_params.last_name !== '',
      hasGender: form_params.gender !== undefined,
      hasMinimum: form_params.min_price_override !== '',
      minimumType: form_params.min_price_override_type,
      hasOneLiner: form_params.one_liner !== '',
      partySize: form_params.party_size_override,
      hasPhone: form_params.phone_number !== '',
      phoneType: form_params.phone_number_locale,
      hasPersonalMessage: form_params.public_notes !== '',
      isBar: form_params.res_type === 'BAR',
      sendClientEmail: form_params.send_client_email === '1',
      sendClientEmailDestination: form_params.send_client_email_destination,
      sendClientSms: form_params.send_client_sms === '1',
      sendClientSmsDestination: form_params.send_client_sms_destination,
      sendReminderEmail: form_params.send_reminder_email === '1',
      sendReminderEmailDestination: form_params.send_reminder_email_destination,
      sendReminderSMS: form_params.send_reminder_sms === '1',
      sendReminderSMSDestination: form_params.send_reminder_sms_destination,
      sendMarketingOptInEmail: form_params.send_marketing_opt_in_email,
      hasTables: form_params.table_ids && form_params.table_ids.length && form_params.table_ids[0].length > 0,
      hasReservationTags: form_params.tag_full_hash !== undefined,
      hasJobTitle: form_params.title !== '',
      hasExistingClientSelected: form_params.venue_group_client_id !== '',
      hasReservationNotes: form_params.venue_notes !== '',
      hasSeatingArea: form_params.venue_seating_area_id !== '',
    })
  },

  submitEdit: function (handler, params) {
    var actual_id = $('#input-actual-id').val()
    var url = '/api-yoa/reservation/' + this.venueId + '/actual/' + actual_id
    var self = this
    var extra_params = {}
    if (params) {
      Object.keys(params).forEach(key => {
        extra_params[key] = params[key]
      })
    }
    return this._submit(
      url,
      function (response) {
        // id changes if we changed the date, because the actual is rebooked
        var load_auto_assignments_and_actual_problems
        var responseData = response.payload || response.data
        if (actual_id === responseData.actual.id) {
          load_auto_assignments_and_actual_problems = self.getAutoAssignmentAndProblemResPromise(actual_id)
          ReservationSlideOut.showReservation(actual_id, responseData.actual, load_auto_assignments_and_actual_problems)
        } else {
          load_auto_assignments_and_actual_problems = self.getAutoAssignmentAndProblemResPromise()
          ReservationSlideOut.close()
        }
        if (handler) {
          handler(actual_id, response, load_auto_assignments_and_actual_problems)
        }
      },
      $.param(extra_params),
      'PUT'
    )
  },

  newHandler: function (actual, response, assignments_and_actual_problems_promise, cancel_ids) {
    if (response.status === 200 && (response.data.actual !== undefined || response.data.needs_approval || response.data.request_added)) {
      var date_url = $('#current_date').val()
      var current_date = $.datepicker.parseDate('mm-dd-yy', date_url)
      if (response.data.needs_approval) {
        Interface.alertSuccess('Reservation request created. Awaiting approval.')
        ReservationSlideOut.close()
        return
      }

      if (response.data.request_added) {
        Interface.alertSuccess('Reservation request added.')
        ReservationSlideOut.close()
        return
      }

      if (response.data.charge_status === 'FAILED') {
        Interface._alert('Reservation was booked, but charge failed!')
      }

      var date = $.datepicker.parseDate('mm/dd/yy', actual.date)
      if (date.getTime() !== current_date.getTime()) {
        ReservationSlideOut.close()
        return
      }

      var spid = $('#shift-select').val()
      // if no shift is selected or the actual somehow does not have a shift persistent id, then we do want to show it
      if (spid && actual.shift_persistent_id && spid !== actual.shift_persistent_id) {
        ReservationSlideOut.close()
        return
      }
      var assignmentsAndActualProblemsPromise = Pmp.Manager.Reservations.Day.AssignmentsAndActualProblemsPromise()

      if (cancel_ids) {
        this._cancel_actuals(cancel_ids)
      }
      Pmp.Manager.Reservations.Day.updateAssignmentsAndProblems(assignmentsAndActualProblemsPromise)
      Pmp.Manager.Reservations.Day._updateActualCache(actual)
      Pmp.Manager.Reservations.Day._rerenderActuals(true, true)

      // fade away the just added color
      var el = $('#actual-row-' + actual.id)
      el.addClass('just-added')
      el.animate({ backgroundColor: '#ffffff' }, 5000)
    } else {
      Interface._alert('Something went wrong!')
    }
  },

  editHandler: function (original_actual_id, actual, response, assignments_and_actual_problems_promise, cancel_ids) {
    if (response.status === 200 && response.data.actual !== undefined) {
      var old_row = $('#actual-row-' + original_actual_id)
      if (old_row.length === 0) {
        return
      }
      if (cancel_ids) {
        this._cancel_actuals(cancel_ids)
      }
      Pmp.Manager.Reservations.Day._updateActualCache(actual, original_actual_id)
      if ($('#search-results').is(':visible')) {
        ReservationSearchBar._performSearch()
      } else {
        Pmp.Manager.Reservations.Day._rerenderActuals(true, true)
      }

      Pmp.Manager.Reservations.Day.updateAssignmentsAndProblems(assignments_and_actual_problems_promise, actual)
    }
  },

  _cancel_actuals: function (cancel_ids) {
    var clonedElements = $()
    var existingElements = $()

    cancel_ids.forEach(id => {
      clonedElements = clonedElements.add($('#actual-row-' + id).clone())
      existingElements = existingElements.add($('#actual-row-' + id))
    })
    clonedElements.removeClass('non-canceled-rows')
    existingElements.remove()
    $('#canceled-rows').append(clonedElements)

    // A little weird this to be so manual, but
    // the canceled row doesn't rerender.
    clonedElements.find('.col-status').sext('Canceled')
    Pmp.Manager.Reservations.Day._cancelActualInCache(cancel_ids)

    Pmp.Manager.Reservations.Day._cancelActualInCache([id])
    Pmp.Manager.Reservations.Day._rerenderActuals(true, true)
    $('#daily-results').find('#canceled-block').show()
    $('.show-canceled-link').click()
  },

  _submit: function (url, handler, extra_params, method = null) {
    if (!this.validator.validate()) {
      var parent_position = $('.validator:visible').first().parent().position()
      if (parent_position === undefined) {
        console.error("Error: unable to locate form validator's invalid position to scroll to")
        Interface._alert('Form validation error encountered, please take a screenshot and contact support')
      } else {
        var scroll_error = parent_position.top
        $('#edit-reservation').scrollTop(scroll_error - 150)
      }
      return false
    }

    if ($('#save-reservation').hasClass('disabled')) {
      return false
    }

    //disable save button
    $('#save-reservation').addClass('disabled')

    var handler_wrapper = function (response) {
      handler(response)
    }

    var deferred = $.Deferred()
    var processPayments = this.shouldProcessPayment()
    var selectedCard = $('#choose_card').val()
    var $cardOption = $('#choose_card option:selected')

    if (processPayments && !selectedCard) {
      $form = $('#book-res')
      var name = $form.find('input[name="cardholder_name"]').val()
      if (this._isStripe) {
        var advanced_payment = !$('#save_for_later').is(':checked')
        if (advanced_payment) {
          var amount = $('#payment_display').val()
          deferred = BillingService.getPaymentIntent(this._stripe, this.stripeMount, name, amount)
        } else {
          deferred = BillingService.getSetupIntent(this._stripe, this.stripeMount, name)
        }
      } else {
        var number = $form.find('input[name="card_number"]').val()
        var exp_month = $form.find('select[name="cc_expiration_month"]').val()
        var exp_year = $form.find('select[name="cc_exp_year"]').val()
        var cvc = $form.find('input[name="card_cvv"]').val()
        deferred = BillingService.getToken(name, number, exp_month, exp_year, cvc)
      }
    }

    if (this._uncancelFlow) {
      this._uncanceler(this._uncancelHandler, true)
      this._uncancelFlow = false
      this._uncancelHandler = null
    }

    var that = this

    deferred
      .done(function (card_token) {
        var data
        if (that.isEditing()) {
          var updated_message_field = $.trim($('#venue-update-message-textarea.message').val())
          $('#venue-public-notes-textarea.message').val(updated_message_field)
        }
        var form_input_selectors =
          '#book-res input:not(.disabled), #book-res select, #book-res textarea, ' +
          '.update-confirmation input:not(.disabled), .update-confirmation select, .update-confirmation textarea'
        data = $(form_input_selectors)
          .filter(function (index, element) {
            return $(element).attr('name') !== 'tag_full_hash'
          })
          .filter(function (index, element) {
            if ($(element).prop('name') !== 'apply_tax') {
              return true
            }
            if (!$(element).is(':checked')) {
              return false
            }
            return true
          })
          .serialize()

        data = data + '&tax_group_id=' + $('#tax_groups').val()

        data =
          data +
          '&' +
          $('.reservation-codes [name=tag_full_hash]')
            .map(function (index, element) {
              element['name'] = 'reservation_tag_full_hash'
              return element
            })
            .serialize()

        data =
          data +
          '&' +
          $('.client-codes [name=tag_full_hash]')
            .map(function (index, element) {
              element['name'] = 'client_tag_full_hash'
              return element
            })
            .serialize()
        data =
          data +
          '&' +
          $('.source-codes [name=tag_full_hash]')
            .map(function (index, element) {
              element['name'] = 'source_tag_full_hash'
              return element
            })
            .serialize()

        if (card_token) {
          data = data + '&card_token=' + card_token
        }

        if (selectedCard) {
          data = data + '&card_id=' + selectedCard
        }

        if (extra_params) {
          data = data + '&' + extra_params
        }

        var requestMethod = method || $('#book-res').prop('method')

        $.ajax({
          url: url,
          method: requestMethod,
          data: data,
          dataType: 'json',
          success: handler_wrapper,
          error: function (response) {
            var errors = ['Something went wrong.']
            var duplicateResError
            var responseData = $.parseJSON(response.responseText)
            try {
              // IE9 fix
              var responseError = responseData.payload || responseData.data
              if (responseError && responseError.hasOwnProperty('overlapping_res_ids')) {
                duplicateResError = responseError
              } else {
                errors = Array.isArray(responseData.msg) ? responseData.msg : [responseData.msg]
              }
            } catch (e) {}
            if (duplicateResError) {
              const isEdit = ReservationSlideOut.isEditing()
              const overlappingResIds = duplicateResError.overlapping_res_ids
              const book_and_cancel_params = {
                override_existing_booking: true,
                overlapping_res_ids: JSON.stringify(overlappingResIds),
              }

              const book_params = {
                override_existing_booking: true,
              }

              const book_and_cancel_handler = unmount => {
                unmount()
                if (isEdit) {
                  ReservationSlideOut.submitEdit(
                    (original_actual_id, response, load_auto_assignments_and_actual_problems) =>
                      ReservationSlideOut.editHandler(
                        original_actual_id,
                        response.data.actual,
                        response,
                        load_auto_assignments_and_actual_problems,
                        overlappingResIds
                      ),
                    book_and_cancel_params
                  )
                } else {
                  ReservationSlideOut.submitNew(
                    response => ReservationSlideOut.newHandler(response.data.actual, response, null, overlappingResIds),
                    book_and_cancel_params
                  )
                }
                $('#warning-modal').hide()
              }
              const book_handler = unmount => {
                unmount()
                if (isEdit) {
                  ReservationSlideOut.submitEdit(
                    (original_actual_id, response, load_auto_assignments_and_actual_problems) =>
                      ReservationSlideOut.editHandler(
                        original_actual_id,
                        response.data.actual,
                        response,
                        load_auto_assignments_and_actual_problems
                      ),
                    book_params
                  )
                } else {
                  ReservationSlideOut.submitNew(response => ReservationSlideOut.newHandler(response.data.actual, response), book_params)
                }
                $('#warning-modal').hide()
              }
              $('#warning-modal').show()
              window.SvrManager.Components.mountWarningModal(
                'warning-modal',
                'This client has another booking during this time.',
                duplicateResError.overlapping_res_array,
                duplicateResError.can_cancel
                  ? `Book and Cancel Other Booking${duplicateResError.overlapping_res_array.length > 1 ? 's' : ''}`
                  : null,
                'Book Anyway',
                duplicateResError.can_cancel
                  ? unmount => {
                      book_and_cancel_handler(unmount)
                    }
                  : null,
                unmount => {
                  book_handler(unmount)
                },
                unmount => {
                  unmount()
                  $('#warning-modal').hide()
                }
              )
            } else {
              var msg = errors.join(' ')
              UserNotificationInterop.error(msg)
            }

            $('#save-reservation').removeClass('disabled')
          },
        })
      })
      .fail(function (error) {
        // addCard failed
        UserNotificationInterop.error(error)
        $('#save-reservation').removeClass('disabled')
      })

    // only force resolve if we didn't kick off payments first
    if (!processPayments || selectedCard) {
      deferred.resolve()
    }

    return false
  },

  openActual: function () {
    window.location = this.base_url + '/actuals/' + this._getActiveDateUrlParam()
  },

  sendEmailConfirmation: function (actual_id) {
    $.ajax({
      url: this.base_url + '/actual/' + actual_id + '/resend-email',
      method: 'post',
      data: {
        send_client_email: true,
      },
    })
      .done(function (response) {
        UserNotificationInterop.success('Successfully resent confirmation email')
        ReservationSlideOut.showReservation(actual_id)
      })
      .fail(function (response) {
        var errors = ['Something went wrong.']
        try {
          // IE9 fix
          errors = $.parseJSON(response.responseText).errors
        } catch (e) {}
        var msg = errors.join(' ')
        UserNotificationInterop.error(msg)
      })
  },

  // This is really cancel! boo naming
  cancelReservation: function (actual_id, handler, send_email) {
    $.ajax({
      url: this.base_url + '/actual/' + actual_id + '/cancel',
      method: 'post',
      data: {
        send_client_email: send_email,
      },
      success: function (response) {
        handler(response)
        ReservationSlideOut.showReservation(actual_id)
        $('#actual-row-' + actual_id)
          .find('.col-client')
          .removeClass('NOT_RECONCILED')
          .addClass('CANCELED')
      },
    })
  },

  uncancelReservation: function (handler, desync) {
    var actual_id = $('#input-actual-id').val()
    $.ajax({
      url: this.base_url + '/actual/' + actual_id + '/uncancel',
      method: 'post',
      async: !desync,
      data: {
        confirm: true,
      },
      success: function (response) {
        handler(response)
        if (!desync) {
          ReservationSlideOut.showReservation(actual_id)
        }
      },
    })
  },

  expandSource: function () {
    $('#source-form').show()
    $('#add-source').hide()
    $('#remove-source').show()
    $('#source-form').find('[sr-validate], input, select').prop('disabled', false)
    $('.send-source-destination-control').show()
    $('.send-source-destination-control').find('select').val('SOURCE').change()
    this._setContactValidationOptional()
  },

  collapseSource: function () {
    $('#source-form').hide()
    $('#add-source').show()
    $('#remove-source').hide()
    $('#source-form').find('[sr-validate], input, select').prop('disabled', true)
    $('.send-source-destination-control').hide()
    $('.send-source-destination-control').find('select').val('CLIENT').change()
    this._setContactValidationSetting()
  },

  _setContactValidationOptional: function () {
    var $phone = $('#input-phone')
    $phone.attr('sr-validate-previous', $phone.attr('sr-validate'))
    $phone.attr('sr-validate', 'phone:empty')
    var $email = $('#input-email')
    $email.attr('sr-validate-previous', $email.attr('sr-validate'))
    $email.attr('sr-validate', 'email:empty')
  },

  _setContactValidationSetting: function () {
    var $phone = $('#input-phone')
    $phone.attr('sr-validate', $phone.attr('sr-validate-previous'))
    var $email = $('#input-email')
    $email.attr('sr-validate', $email.attr('sr-validate-previous'))
  },

  clearSourcedBy: function (collapseSource) {
    this._source_search = null
    this._source_venue_group_client_id = null
    $('.sourced-by-container').hide()
    $('#source-client-profile-area').hide()
    $('#input-source-venue-group-client-id').val('')
    $('#input-source-firstname').val('')
    $('#input-source-lastname').val('')
    $('#input-source-phone').val('')
    $('#input-source-phone-locale').val('')
    $('#input-source-email').val('')
    $('#input-source-company').val('')
    $('.source-codes #find-source-tags-input span').empty()
    if (collapseSource) {
      this.collapseSource()
    }
  },

  clear: function () {
    this.followers.entity_id = null
    this.followers.kind = null
    this.followers.clear()
    $('#find-tags-results').show() // scrollTop doesn't work if element is hidden
    $('.tag-table-container').scrollTop(0)
    $('#find-tags-results').hide()
    $('.form-element.reservation-codes').show()
    Chatter.num_messages = 0
    this.clearSourcedBy(true)
    $('#chat-conversation_id').val('')
    $('#client-profile-area').hide()
    $('#more-details').show()
    this.validator.clear()
    this._actual = null
    this._uncancelFlow = false
    this._uncancelHandler = null

    var target = $('#main-interface')

    this.refreshBookedByNames()

    const updateEmailCheckbox = $('#id_send_update_email')
    if (updateEmailCheckbox.is(':checked')) {
      updateEmailCheckbox.trigger('click')
    }
    const updateSmsCheckbox = $('#id_send_update_sms')
    if (updateSmsCheckbox.is(':checked')) {
      updateSmsCheckbox.trigger('click')
    }

    // Specific to ressies
    // This got pretty mad clunky, yo.
    this._is_client_profile_selected = false
    $('#client-profile-area').html('')

    $('#flyout-notes-area').hide()

    $('#booked-by-3p').addClass('disabled')

    // select offer default
    $('#experience_id').val(this._experienceId)

    target.find('#booked-by').val(Pmp.Manager.Global._user_id)
    target
      .find('#input-phone,.email-input, #input-phone-alt, .email-input-alt')
      .prop('placeholder', '')
      .removeClass('disabled uneditable')
      .parent()
      .show() // :(
    target.find('#id_select-phone-locale, #id_select-phone-locale-alt').show()
    target.find('#select-phone-locale').val('US').parent().show()
    target.find('#select-phone-locale-alt').val('US').parent().show()

    // This is the true weight of sin. Look upon what ye
    // have sown and despair, for hereth lie the chains that
    // have drug ye to the abyss of the liar's lair, where
    // ye shall burn.
    target.removeClass(
      'not_reconciled wrong_number left_message confirmed late no_answer no_show no_entry arrived arrived_partial canceled complete past_time'
    )
    target
      .removeClass(
        'new-reservation new-request no-bank edit-reservation table bar editing accepted hold declined offermade trashed expired'
      )
      .addClass('table')
    target.removeClass('modify-res-full modify-res-small modify-res-full-with-sms modify-res-small-with-sms')

    target.find('.booked_by_wrap').remove()
    target.find('#res_type').val('TABLE')

    //target.find('.groups-prepend').empty();

    target.find('.form-element.gender').removeClass('checked')
    target.find('.form-element.gender input').prop('checked', false)

    $('#venue-update-message-textarea').val('')

    $('#default-thumb').show()
    $('#client-thumb').attr('src', '')
    $('#client-thumb').hide()

    // And so much worse
    CostOptions.clear()

    $('#payments-history').show()
    $('#charge-form').hide()
    $('#charge-form input[type=text]').val('')
    $('#charge-form select').val('')
    $('input[name=save_to_profile]').prop('checked', false)
    $('.save_to_profile').removeClass('checked')

    this._primary_card = null

    $('select[name=choose_card]').children('.client-card,.attached-card').remove()
    $('select[name=choose_card]').trigger('change')
    $('input[value=advanced_payment]').trigger('click')
    $('input[value=advanced_payment]').prop('disabled', false)
    $('input[value=save_for_later]').prop('disabled', false)
    $('.new-card').find('input,select').prop('disabled', false)
    $('#payment_amount').prop('readonly', false)
    $('.form-element.override_payment_requirement').hide()

    $('#groups-area .closer').trigger('click')

    $('#input-venue-group-client-id').val('')
    $('#input-actual-id').val('')
    $('#select-seating-area').val(this._default_seating_area)
    if (this._is_nightlife_class) {
      $('#select-seating-area').change()
    }
    $('div.insert-table').empty()
    if (Pmp.Manager.Reservations.Day._is_nightlife_class) {
      // dining classes are always TABLE
      $('[name=res_type]').val('')
    }
    $('[name=phone_number_locale]').val('')
    $('[name=phone_number_alt_locale]').val('')
    $('[name=status]').val('')
    // $('[name=booked_by]').val(''); Maybe no need to default here
    $('#select-est-arrival-time').val('')
    $('#booked-by-interface').show()
    $('#booked-by-interface-3p').hide()

    $('#venue-private-notes-textarea').val('')
    $('#venue-public-notes-textarea').val('')
    $('#new-promoter-name').val('')

    var action = this._reservations_require_approval ? 'Request' : 'Book'

    $('#save-reservation .new').sext(action)
    $('#save-reservation').css({ background: '#347baf' })

    this._resetTimesAndTables()
    this._require_booked_by_fields()
    this._hide_new_promoter_name()

    this._actual_is_auto_assign = null
    this._actual_is_custom_assign = null

    $('.booked-by-unrequired').hide()

    $('#new-promoter-name-container').hide()

    Pmp.Common.Reservation.Validator.trimPublicNotesTextArea()
    Pmp.Common.Reservation.Validator.trimPrivateNotesTextArea()

    var table_index = 0
    while (true) {
      var add_table_el = $('#table-added-' + table_index)
      if (!add_table_el.length) {
        break
      }
      add_table_el.parent().remove()
    }

    //modify reservations
    if (updateEmailCheckbox.is(':checked')) {
      updateEmailCheckbox.click()
    }
    if (updateSmsCheckbox.is(':checked')) {
      updateSmsCheckbox.click()
    }

    $('#profile-one-liner').val('')
    $('#profile-company').val('')
    $('#profile-title').val('')
    $('#birthday-month').val('')
    $('#birthday-day').val('')
    $('#anniversary-month').val('')
    $('#anniversary-day').val('')
    this._updateProfileSalutation('')
    $('.payment-details input[type=text]').val('')
    $('.payment-details select').val('')

    target.find('.credit-card-input-group').removeClass('request-card')
    target.find('.form-element.payment_type').removeClass('checked')
    target.find('.form-element.payment_type input').prop('checked', false)

    $('#save-reservation').removeClass('disabled')
    $('.comp-options input').first().trigger('click')

    // KP: This triggers shift and availability fetch unnecessarily,
    // so set load lock to prevent availability fetch
    this.LOAD_LOCK = true
    $('input[name=date_button]').first().trigger('click')
    $('input[name=date_button]').first().trigger('mouseup')
    this.LOAD_LOCK = false

    $('#add-client-tags-container').find('.tag-item-container').remove()
    $('#main-interface').removeClass('view-only')
    this.validator.clear()

    $('.form-element.select.duration').show()
    $('.form-element.select.departure').show()
    $('.form-element.select.table').show()
    $('.form-element.public-notes').show()

    $('#availability').show()
    $('#save-reservation .new').sext('Book')
    $('[name="force_request"]').val('')
    $('#custom-party-size').val('')

    $('.send-client-email-label').sext('Send booking notification email now')
    $('.form-element.textarea.venue_notes > label > p.label').sext('Reservation notes (will NOT be included on booking notification email)')

    $('.payments-cc-form').scrollTop(0)
    $('.payment-info input[name=payment_amount]').val('')

    $('#main-details').click()
  },

  _loadDateButtons: function (day) {
    var that = this
    // This .replace() brought to you by Safari
    // and a wasted Friday.
    var date_object = new Date(day.replace(/-/g, '/'))
    var days = []
    var display_dow, display_day, value
    for (var iter = 0; iter < 7; iter++) {
      display_dow = dateFormat(date_object, 'ddd')
      display_day = dateFormat(date_object, 'd')
      value = dateFormat(date_object, 'mm-dd-yyyy')

      // Soy wouldn't take html string
      // and I got tired of struggling.
      // Also simpler to add actions.
      var el = $('<div class="form-element radio date_select" />')
      var label = $('<label />')
      var pi = $('<p class="input" />')
      var pl = $('<p class="label post" />')
      var input = $('<input name="date_button" type="radio" />')
      var span = $('<span />')
      var em = $('<em />')

      if (iter == 0) {
        el.addClass('first checked')
        input.prop('checked', true)
      } else if (iter == 6) {
        el.addClass('last')
      }

      span.sext(display_dow)
      em.sext(display_day)
      input.val(value)

      pl.append(span, em)
      pi.append(input)
      label.append(pi, pl)
      el.append(label)

      // KP: if you bind this to 'click',
      // it gets called twice!! wtf. something is triggering a click event
      // but alas we know not where.
      el.on('mouseup', function (ee) {
        var val = $(this).find('input').val()
        var val = val.replace(/-/g, '/')
        var date = new Date(val)
        var display_format = $.datepicker.formatDate(Pmp.Utils.dateFormat(that._locale), date)

        that._setAndFormatDate(date)

        // TODO: This will have to be sorted
        // $('#req-date-display').sext(dateText);

        that.EDIT_LOCK = false
        var input_format = dateFormat(date, 'mm-dd-yyyy')

        $('#actual-date-submit,#tab-offer .request_date').val(input_format)
        that._loadShifts($('#actual-date-submit').val())
        that._loadEventNotes($('#actual-date-submit').val())
        that._resetAvailability()
        that._modifyEmailWorthyFieldHasChanged(ee)
      })

      $('#date-buttons').append(el)
      date_object.setDate(date_object.getDate() + 1)
      that._setAndFormatDate()
    }
  },

  /**
   * Load event notes for concierge
   * @param val date
   * @private
   */
  _loadEventNotes: function (val) {
    if (this.LOAD_LOCK || this._isReservationRequestForm() || !this._is_dining_class) {
      return
    }

    $.get('/manager/' + this._venue_url_key + '/manage/program/json', { date: val }, function (response) {
      // Safety check
      if (Interface.isClosed('main-interface')) {
        return
      }
      if (!response.payload) {
        return
      }
      if (!response.payload.events.length && !response.payload.note) {
        return
      }

      var flyoutHtml = Nightloop.Templates.Manager.Reservation.FlyoutNotes({
        events: response.payload.events,
        note: response.payload.note,
      })

      $('#flyout-notes-wrapper').html(flyoutHtml)
      $('#flyout-notes-area').show()

      var flyoutNotesArea = $('#flyout-notes-area'),
        flyoutNotesWrapper = $('#flyout-notes-wrapper')

      /// If the generated div is scorollable, then add more buttons
      if ($(flyoutNotesArea)[0].scrollHeight > $('#flyout-notes-area').height()) {
        $(flyoutNotesWrapper)
          .find('.flyout-note-area')
          .each(function (i, area) {
            var height = $(area).height(),
              notes = $(area).find('.notes'),
              less = $(area).find('.less')
            more = $(area).find('.more')
            if ($(notes).height() > 200) {
              $(notes).css('max-height', '200px')
              $(more).show()
            }
            $(less).hide()
          })
      }

      // Handler for Less link on event
      $(flyoutNotesWrapper).on('click', '.less', function (e) {
        var area = $(this).closest('.flyout-note-area'),
          more = $(area).find('.more'),
          notes = $(area).find('.notes')
        $(this).hide()
        $(more).show()
        $(notes).css('max-height', '200px')
      })

      // Hander for more link -- bring the selected event up top
      $(flyoutNotesWrapper).on('click', '.more', function (e) {
        var notes = $(this).siblings('.notes'),
          area = $(this).closest('.flyout-note-area'),
          less = $(area).find('.less'),
          currentScrollTop = $('#flyout-notes-area').scrollTop()
        $(notes).css('max-height', '100%')
        $(less).show()
        $(this).hide()
      })
    })
  },

  _loadShifts: function (day, skip_change_shift, onCompleteFn) {
    var that = this

    function _applyShifts(day_key) {
      that._shiftDict = {} // For easier reference later
      var $select = $('#res_shift')
      var $opt, shift
      var prevShiftPid = $select.val()
      that._current_shift_day_key = day_key
      // Create shift options
      $select.html('')
      for (iter = 0; iter < that._shifts.length; iter++) {
        shift = that._shifts[iter]
        $opt = $('<option/>').sext(shift.name).val(shift.persistent_id)
        $select.append($opt)
        that._shiftDict[shift.persistent_id] = shift
      }
      // Find best shift to auto-select
      var bestShiftPid = undefined
      var prevShiftCategory = ShiftSelector.getCategoryFromPersistentId(prevShiftPid)
      var pad = dateFormat(new Date(), 'm/d/yyyy')
      var startOfDayDate = new Date(pad + ' ' + Pmp.Manager.Global.start_of_day_hour + ':00')
      for (iter = 0; iter < that._shifts.length; iter++) {
        shift = that._shifts[iter]
        if (that.time_selected) {
          // If time selected, match shift on time
          var start = timeutils.sort_order_to_time(shift.start_time_sort_order, this._is_military_time)
          var end = timeutils.sort_order_to_time(shift.end_time_sort_order, this._is_military_time)

          var realstart = new Date(pad + ' ' + start)
          var realend = new Date(pad + ' ' + end)
          var realselect = new Date(pad + ' ' + that.time_selected)
          if (realstart < startOfDayDate) {
            realstart = realstart.addDays(1)
          }
          if (realend < startOfDayDate) {
            realend = realend.addDays(1)
          }
          if (realselect < startOfDayDate) {
            realselect = realselect.addDays(1)
          }

          if (realselect >= realstart && realselect <= realend) {
            bestShiftPid = shift.persistent_id
            break
          }
        } else if (shift.persistent_id === prevShiftPid) {
          // If same persistent id found, use that
          bestShiftPid = shift.persistent_id
          break
        } else {
          // Fallback to category match
          var shiftCategory = ShiftSelector.getCategoryFromPersistentId(shift.persistent_id)
          if (shiftCategory === prevShiftCategory) {
            bestShiftPid = shift.persistent_id
          }
          if (bestShiftPid === undefined) {
            // Fallback to first shift
            bestShiftPid = shift.persistent_id
          }
        }
      }
      if (bestShiftPid) {
        $select.val(bestShiftPid)
      }
      if (!skip_change_shift) {
        that._changeShift($select.val())
      }
    }

    var day_key = $.datepicker.formatDate('mm/dd/yy', new Date(Date.parse(day)))

    // cache shifts by day
    if (day_key in that._shiftsByDay) {
      that._shifts = that._shiftsByDay[day_key]
      _applyShifts(day_key)
      if (onCompleteFn) {
        onCompleteFn()
      }
      return
    }

    // uncached shift
    $.ajax({
      url: '/api-yoa/shifts/schedule',
      data: {
        venue: that._venue_url_key,
        start_date: day_key,
        end_date: day_key,
      },
      success: function (response) {
        if (!response) {
          return
        }
        that._shiftDict = {}
        that._shifts = response.data.shifts[day_key]
        that._shiftsByDay[day_key] = response.data.shifts[day_key]
        _applyShifts(day_key)
        if (onCompleteFn) {
          onCompleteFn()
        }
      },
    })
  },

  _toggleReminderEmailDisplayForShift: function () {
    var shouldShowReminders
    if (this._reservations_require_approval || $('.new-request').is(':visible')) {
      // just disable this for request only folks.
      shouldShowReminders = false
    } else if (this._is_dining_class) {
      var shift_persistent_id = $('#res_shift').val()
      var shift = this._shiftDict[shift_persistent_id]
      if (shift === undefined) {
        shouldShowReminders = false
      } else {
        var shiftCategory = shift.category
        shouldShowReminders = this._reminderTimesExistDining[shiftCategory]
      }
    } else {
      // Nightlife
      shouldShowReminders = this._reminder_email_time_for_nightlife_exists
    }
    this._toggleReminderEmailDisplay(shouldShowReminders)
  },

  _toggleReminderEmailDisplay: function (showIt) {
    $('#send-reminders-checkbox-container').toggle(showIt)
    var $allReminderCheckboxes = $('#id_send_reminder_email, #id_send_reminder_sms')
    $allReminderCheckboxes.prop('disabled', !showIt)
    $allReminderCheckboxes.filter(':checked').trigger('click')
    if (showIt) {
      if (this._send_reminder_default_method === 'SMS' || this._send_reminder_default_method === 'EMAIL_AND_SMS') {
        $('#id_send_reminder_sms').trigger('click')
      }
      if (this._send_reminder_default_method === 'EMAIL' || this._send_reminder_default_method === 'EMAIL_AND_SMS') {
        $('#id_send_reminder_email').trigger('click')
      }
    }
  },

  _isShiftsAppliedForDay: function (day) {
    var day_key = $.datepicker.formatDate('mm/dd/yy', new Date(Date.parse(day)))
    return this._current_shift_day_key === day_key
  },

  _resetTimesAndTables: function () {
    this._select_time(null)
    this.time_selected_attrs = null
    $('#preloaded-tables').html('')
    $('#table-inputs').html('')
    $('[name=table_ids]').val('')
    this.table_ids_list = undefined
    this._capacity_mismatch = false
  },

  _resetAvailability: function () {
    if (this.EDIT_LOCK) {
      return
    }
    var action = this._reservations_require_approval || this._isReservationRequestForm() ? 'Request' : 'Book'

    $('#save-reservation .new').sext(action)
    $('#save-reservation').css({ background: '#347baf' })
  },

  shiftTimes: [],
  startSlide: false,
  endSlide: false,
  minSlide: false,
  timeSlider: null,
  _range: null,
  _shift: null,

  // NEW PARADIGM
  ts_min: 0,
  ts_max: 0,
  ts_from: 0,
  ts_to: 0,
  ms_step: 0,
  ts_from_selected: 0,
  ts_to_selected: 0,

  _setupTimeSelecting: function () {
    if (this._is_nightlife_class) {
      return
    }
    var that = this

    if (this.timeSlider) {
      this.timeSlider.destroy()
    }

    $('#time-range-slider').ionRangeSlider({
      type: 'double',
      grid: true,
      grid_num: 6,
      min: that.ts_min,
      max: that.ts_max,
      from: that.ts_from,
      to: that.ts_to,

      //values: that.shiftTimes,
      //from: that.startSlide,
      //from_min: that.minSlide,
      //to: that.endSlide,
      //hide_from_to: true,

      force_edges: true,
      drag_interval: true,
      hide_min_max: true,

      step: that.ms_step,

      prettify: function (num) {
        var d = new Date(num)
        return dateFormat(d, that._timeFormat)
      },

      onFinish: function (range) {
        if (that.startSlide === range.from && that.endSlide === range.to) {
          return
        }

        that.startSlide = range.from
        that.endSlide = range.to

        that.ts_from_selected = range.from
        that.ts_to_selected = range.to

        that._shift = that.shiftTimes
        that._range = range
        that._fetchAvailability()
        $('.irs-to,.irs-from').hide()
      },

      onChange: function () {
        $('.irs-to,.irs-from').show()
      },

      onStart: function (range) {
        that._shift = that.shiftTimes
        that._range = range
        that._shifts_loaded = true

        that.ts_from_selected = range.from
        that.ts_to_selected = range.to

        that._enableBookNewButtonsIfReady()
        // prevent extra availability lookups if you haven't even opened the slideout
        if (that.HAS_SLIDEOUT_OPENED) {
          that._fetchAvailability()
        }
        $('.irs-to,.irs-from').hide()
      },
    })

    this.timeSlider = $('#time-range-slider').data('ionRangeSlider')
    if (this._isReservationRequestForm()) {
      $('#time-range-slider-container').show()
    } else {
      $('#time-range-slider-container').hide()
    }
  },

  _isReservationRequestForm: function () {
    return $('#main-interface').hasClass('new-request')
  },

  _fetchAvailability: function (showAll, preload, from_edit_button) {
    if (this.LOAD_LOCK || this._isReservationRequestForm() || !this._is_dining_class) {
      return
    }

    $('#save-reservation').addClass('disabled')

    var that = this

    var date = $('#actual-date-submit').val()
    var party_size = $('#the-real-party-size').val()

    if (!date) {
      date = this._getActiveDateUrlParam()
    }

    // Deals with a pseudo race condition
    if (!party_size) {
      this.LOAD_LOCK = false
      return
    }

    this._rid = Math.floor(Math.random() * 100 + 1)
    // Nab dat shit yo
    var data = {
      actual_id: $('#input-actual-id').val(),
      shift_persistent_id: $('#res_shift').val(),
      venue_seating_area_id: $('#select-seating-area').val(),
      party_size: party_size,
      duration: $('#select-duration').val(),
      date: date,
      vgc_id: this._venue_group_client_id,
      rid: this._rid,
    }

    $('#availability').html('')
    $('#with-access-blocks-discl').hide()
    $('#times-spinner').show()

    $.ajax({
      url: that.base_url + '/availability/' + date,
      data: data,
      global: false,
      success: function (xhr) {
        // only care about the newest rid, discard other results
        if (data['rid'] === that._rid) {
          that._updateTimeList(xhr, from_edit_button, preload)
          that._setPaymentRequirements(party_size, xhr)
        }
        if (that._uncancelFlow) {
          var check = null
          var overbooked = false
          var no_tables = false
          var needs_table = false
          for (var _time in xhr.availability) {
            check = xhr.availability[_time]

            if (check.time === that.time_selected) {
              if (check.status === 'no_tables') {
                no_tables = true
                break
              }
              if (check.status === 'overbooked') {
                overbooked = true
                break
              }
              if (check.booked_possible_covers + parseInt(data.party_size) > check.max_covers) {
                overbooked = true
                break
              }
              if (!$('#preloaded-tables').val()) {
                needs_table = true
                break
              }
            }
          }

          if (!overbooked && !no_tables && !needs_table) {
            that._uncanceler(that._uncancelHandler)
            that._uncancelFlow = false
            that._uncancelHandler = null
          } else {
            $('#edit-res-button').trigger('click')
            // Usually this avail call takes care of this after
            // the click event, but the edit avail call is supressed
            // by the uncancel flow. Don't want to move those style
            // changes because everything's so fragile, so doing it
            // here. Happily, moratorium is going into effect.
            $('#save-reservation').css({ background: '#c00' })
            if (overbooked) {
              $('#time-display span.overbooking').show()
            }
            if (no_tables) {
              $('#time-display span.no_tables').show()
            }
          }
        }

        that._filterAvailableTables()
        that.LOAD_LOCK = false
        $('#save-reservation').removeClass('disabled')
      },
      error: function (response) {
        // eat it. nom nom nom.
        that.LOAD_LOCK = false
        $('#save-reservation').removeClass('disabled')
      },
    })
  },

  _prependCutoffMessage: function (internal_cutoff) {
    if (!internal_cutoff) {
      return
    }

    var date = moment(internal_cutoff)
    var day = date.format('LL')
    var time = date.format('LT')

    var text = ['Reservations for this shift can be booked starting on', day, 'at', time].join(' ')

    var warning = $('<li/>').addClass('limit-warning').text(text)

    $('#availability').append(warning)
  },

  _updateTimeList: function (data, from_edit_button, from_grid_click) {
    $('#times-spinner').hide()
    $('#availability').html('')
    $('#with-access-blocks-discl').hide()

    var recommendations = data['recommendations']
    for (var i = 0; i < recommendations.length; ++i) {
      this.debug(
        'Recommending table ' + recommendations[i].table_codes + ' for ' + recommendations[i].name + ' at ' + recommendations[i].time
      )
    }

    this._prependCutoffMessage(data['internal_cutoff'])

    var is_override = false
    var $main_interface = $('#main-interface')
    var is_new_reservation = $main_interface.hasClass('new-reservation') || $main_interface.hasClass('type-requests')
    var that = this
    var results = data['availability']
    // whether the selected time is found in the results
    var time_slot_found = false
    // whether the selected time is found in the results and has inventory/tables
    var time_slot_available = false
    var has_possible_pacing_item = false
    var show_seating_area_covers = this._show_availability_seatingarea_covers && $('#select-seating-area').val()
    for (var iter = 0; iter < results.length; iter++) {
      var slot = results[iter]
      var time = slot['time']
      var status = slot['status']
      var sub_status = slot['sub_status']
      var booked_covers = parseInt(slot['booked_covers'])
      var booked_possible_covers = parseInt(slot['booked_possible_covers'])
      var booked_seating_area_covers = parseInt(slot['booked_seating_area_covers'])
      var booked_possible_seating_area_covers = parseInt(slot['booked_possible_seating_area_covers'])
      var max_covers = parseInt(slot['max_covers'])
      var bookable = true
      var party_size = parseInt($('#the-real-party-size').val())
      var pacing_seating_area_prefix = show_seating_area_covers ? '(' + booked_seating_area_covers + ') ' : ''
      var pacing = pacing_seating_area_prefix + booked_covers + ' / ' + max_covers
      var show_possible_pacing = booked_possible_covers > booked_covers
      var possible_pacing_seating_area_prefix = show_seating_area_covers ? '(' + booked_possible_seating_area_covers + ') ' : ''
      var possible_pacing = show_possible_pacing ? possible_pacing_seating_area_prefix + booked_possible_covers + ' / ' + max_covers : ''
      if (show_possible_pacing) {
        has_possible_pacing_item = true
      }

      var li = $('<li />')
      var time_strong = $('<strong />')
      var pacing_span = $('<span />').attr('class', 'pacing')
      var possible_pacing_span = $('<span />').attr('class', 'possible-pacing')

      time_strong.sext(time)
      pacing_span.sext(pacing)
      possible_pacing_span.sext(possible_pacing)
      li.append(time_strong, pacing_span, possible_pacing_span)

      if (iter === results.length - 1) {
        li.addClass('last')
      }

      var new_booked_covers = booked_covers + party_size
      var new_booked_seating_area_covers = booked_seating_area_covers + party_size
      var pacing_update_seating_area_prefix = show_seating_area_covers ? '(' + new_booked_seating_area_covers + ') ' : ''
      var pacing_update = pacing_update_seating_area_prefix + new_booked_covers + ' / ' + max_covers
      var new_booked_possible_covers = booked_possible_covers + party_size
      var new_booked_possible_seating_area_covers = booked_possible_seating_area_covers + party_size
      var possible_pacing_update_seating_area_prefix = show_seating_area_covers ? '(' + new_booked_possible_seating_area_covers + ') ' : ''
      var possible_pacing_update = show_possible_pacing
        ? possible_pacing_update_seating_area_prefix + new_booked_possible_covers + ' / ' + max_covers
        : ''
      var block_name = null

      if (status === 'overbooked' || status === 'overbooking') {
        li.addClass('overbooked')
        var em = $('<em />').sext(status)
        li.append(em)
        bookable = false
      } else if (status === 'available') {
        bookable = true
      } else if (status === 'blocked') {
        // check if there are any blocked tables the user can book
        var blocked_tables = slot['blocked']
        var has_bookable_block = false
        for (var i = 0; i < blocked_tables.length; ++i) {
          if (blocked_tables[i]['blocks'].length) {
            block_name = blocked_tables[i]['blocks'][0]['name']
          }
          if (blocked_tables[i]['has_permissions']) {
            has_bookable_block = true
          }
        }

        if (has_bookable_block) {
          li.addClass('bookable-block')
          bookable = true
        } else {
          var has_bookable_hold = false
          var held = slot['held']
          if (held.length) {
            block_name = held[0]['hold_name']
            // check if there are bookable held tables
            if (Pmp.Manager.Global._can_overbook) {
              has_bookable_hold = true
            }
          }
          if (has_bookable_hold) {
            li.addClass('bookable-block')
            bookable = true
          } else {
            li.addClass('blocked')
            bookable = false
          }
        }

        em = $('<em />').sext(block_name)
        li.append(em)
      } else if (status === 'overbook_enforced_shift_party_size') {
        li.addClass('bookable-block')
        em = $('<em />').sext('overbooking shift party size')
        li.append(em)
        bookable = false
      } else if (status === 'no_tables') {
        li.addClass('no-tables')
        if (sub_status === 'only_larger_tables') {
          em = $('<em />').sext('only larger tables available')
        } else if (sub_status === 'only_larger_blocked_tables') {
          em = $('<em />').sext('only larger blocked tables available')
        } else if (sub_status === 'only_smaller_tables') {
          em = $('<em />').sext('only smaller tables available')
        } else if (sub_status === 'only_smaller_blocked_tables') {
          em = $('<em />').sext('only smaller blocked tables available')
        } else {
          em = $('<em />').sext('no tables available')
        }
        li.append(em)
        bookable = false
      } else {
        li.addClass('no-tables')
        em = $('<em />').sext('no tables available')
        li.append(em)
        bookable = false
      }

      if (time === this.time_selected) {
        time_slot_found = true
        time_slot_available = bookable
        if (bookable || from_grid_click || from_edit_button) {
          // choose recommended table if this was not from a click on the grid
          // and is either editing an actual is auto assigned or if it's a new reservation
          var choose_recommended =
            !this.table_ids_list && this._autoselect_table && !from_grid_click && (this._actual_is_auto_assign || is_new_reservation)
          var choose_custom = this._actual_is_custom_assign
          is_override = this._loadTableMenu(slot, choose_recommended, choose_custom)

          this._filterAvailableTables()
          this._timeClicked(pacing_update, possible_pacing_update, status, sub_status, block_name)
        }
      }

      if (Pmp.Manager.Global._can_overbook) {
        li.addClass('override')
      }

      if (bookable || Pmp.Manager.Global._can_overbook) {
        li.addClass('can_overbook') // Don't think this is used?  Only .override is used in slideout.styl
        // Dammit jquery, you're supposed to
        // take care of this shit for me.
        ;(function (li, time, pacing_update, possible_pacing_update, slot, status, sub_status, block_name) {
          var choose_recommended = that._autoselect_table && (that._actual_is_auto_assign || is_new_reservation)
          var choose_custom = that._actual_is_custom_assign
          li.on('click', function (ee) {
            that._select_time(time)
            that._timeClicked(pacing_update, possible_pacing_update, status, sub_status, block_name)
            that._loadTableMenu(slot, choose_recommended, choose_custom)
            that._filterAvailableTables()
            var scrolltop = $('div.time-select').position().top - 20
            if ($('#edit-reservation').scrollTop() > scrolltop) {
              $('#edit-reservation').scrollTop(scrolltop)
            }
            that._modifyEmailWorthyFieldHasChanged(ee)
          })
        })(li, time, pacing_update, possible_pacing_update, slot, status, sub_status, block_name)
      }

      $('#availability').append(li)
    }

    $('#with-access-blocks-discl').toggle(has_possible_pacing_item)

    // scenarios where there is some sort of conflict between what is selected and what's available
    // 1) time slot selected was not returned by the results (e.g. when clicking on grid on time that is not available)
    // 2) table selected doesn't fit the party size selected (e.g. editing a superuser booking that was an override)
    // 3) time slot returned by results, but no inventory available (e.g. editing party size after selecting a table)
    if ((!time_slot_found && this.time_selected) || is_override || (time_slot_found && !time_slot_available)) {
      // on_edit is if we're on the edit interface, whereas from_edit_button indicates this method
      // was originally triggered by a click on the EDIT button on an existing reservation
      // ie if someone opens the slide, then proceeds to edit search params, from_edit_button would be false,
      // but on_edit would be true
      var on_edit = this.isEditing()

      if ((Pmp.Manager.Global._can_overbook && on_edit && time_slot_available) || from_edit_button || from_grid_click || is_override) {
        $('#time-display span.pacing').html('')
        $('#time-display span.possible-pacing').html('')
        $('#time-display span.overbooking').hide()
        if (!time_slot_found) {
          $('#time-display span.no_tables').show()
        }
        $('#time-display').show()
        $('#time-editor').hide()
        $('#save-reservation').css({ background: '#c00' })
        $('#save-reservation .new').sext('Overbook ' + this.time_selected.toLowerCase())
        $('#time-display span.overbooking').show()
        $('#time-display span.no_tables').hide()

        // only do this if the time selected was not found,
        // since there wouldn't be any tables returned for the dropdown
        if (!time_slot_found && (from_edit_button || from_grid_click) && this.table_ids_list) {
          var $tables = $('#preloaded-tables')
          $tables.append($('<option />'))
          var option = $('<option />')
          option.val(this.table_ids_list)
          var table_or_combo = this._lookupTableOrCombo(this.table_ids_list)
          if (table_or_combo) {
            option.sext(table_or_combo['name'])
            option.prop('selected', true)
            $tables.append(option)
          }
        }
      } else {
        this._resetTimesAndTables()
      }
    }
  },

  _setPaymentRequirements: function (party_size, data) {
    $('.form-element.override_payment_requirement').hide()
    $('#payment_amount').val('')
    $('#payment_amount').prop('readonly', false)
    $('input[value=take_payment]').prop('disabled', false)
    $('input[value=save_for_later]').prop('disabled', false)
    var require_credit_card = data['require_credit_card']
    var cc_party_size_min = data['cc_party_size_min']
    if (cc_party_size_min) {
      cc_party_size_min = parseInt(cc_party_size_min)
    } else {
      cc_party_size_min = 0
    }

    if (!require_credit_card || cc_party_size_min >= party_size) {
      return
    }
    var can_override = Pmp.Manager.Global._can_override_payment_requirement
    if (can_override) {
      $('.form-element.override_payment_requirement').show()
      if ($('#override_payment_requirement').is(':checked')) {
        $('#override_payment_requirement').trigger('click')
      }
    }
    var save_for_later = data['cc_payment_rule'] === 'save_for_later'

    if (save_for_later) {
      $('input[value=save_for_later]').trigger('click')
      $('label[for=save_for_later]').trigger('click')
      $('input[value=take_payment]').prop('disabled', !can_override)
    } else {
      $('input[value=take_payment]').trigger('click')
      $('label[for=take_payment]').trigger('click')
      $('input[value=save_for_later]').prop('disabled', !can_override)
    }
    var cost = parseFloat(data['cc_cost'])
    var total_cost = cost
    if (data['cc_charge_type'] && data['cc_charge_type'].startsWith('person')) {
      total_cost *= party_size
    }
    if (data['cc_charge_type'] && data['cc_charge_type'].endsWith('slot')) {
      total_cost *= parseInt($('#select-duration').val(), 10) / 15
    }
    $('#base_amount').val(total_cost)
    if (data['apply_service_charge']) {
      $('#apply_service_charge').trigger('click')
    }
    $('#service_charge_percent').val(data['service_charge'])
    if (data['cc_apply_tax_rate']) {
      $('#apply_tax').trigger('click')
    }
    $('#tax_groups').val(data['tax_group_id'])
    if (data['apply_gratuity_charge']) {
      $('#apply_gratuity_charge').trigger('click')
    }
    $('#tip_percent').val(data['gratuity'])
    $('#base_amount').trigger('keyup') // forces the _calc_payment function
    $('#base_amount').prop('readonly', !can_override)
  },

  _select_time: function (time) {
    this.time_selected = time
    if (time) {
      $('#real-est-arrival-time').val(time)
      $('#time-display strong').sext(time)
      $('#time-display').show()
      $('#time-editor').hide()
    } else {
      $('#real-est-arrival-time').val('')
      $('#time-display').hide()
      $('#time-editor').show()
    }
  },

  _timeClicked: function (pacing_update, possible_pacing_update, status, sub_status, block_name) {
    $('#time-display span.pacing').sext(pacing_update)
    $('#time-display span.possible-pacing').sext(possible_pacing_update)
    $('#time-display').show()
    $('#time-editor').hide()
    $('#invalid-est-arrival').hide()
    this.time_selected_attrs = { status: status, sub_status: sub_status, block_name: block_name }
    this._updateSaveButtonForSelectedTimeAndTable()
  },

  _updateSaveButtonForSelectedTimeAndTable: function () {
    // Extract the dew
    var time_selected_attrs = this.time_selected_attrs ? this.time_selected_attrs : {}
    var time_status = time_selected_attrs['status']
    var time_sub_status = time_selected_attrs['sub_status']
    var time_block_name = time_selected_attrs['block_name']
    var selected_table_option = $('#preloaded-tables').find(':selected')
    var table_is_blocked = selected_table_option ? selected_table_option.attr('is_blocked') : false
    var table_block_name = selected_table_option ? selected_table_option.attr('block_name') : ''
    var table_is_table_size_too_large = selected_table_option ? selected_table_option.attr('is_table_size_too_large') : false
    var table_is_table_size_too_small = selected_table_option ? selected_table_option.attr('is_table_size_too_small') : false
    var time_display = this.time_selected ? this.time_selected.toLowerCase() : ''

    // Prep the dew
    var save_res_background_color = '#347baf'
    var show_time_overbooking = false
    var show_time_no_tables = false
    var time_block_sext = undefined
    var time_no_tables_sext = ''
    var new_book_prefix_sext = 'Book at'
    var new_book_suffix_sext = ''
    // Compute the dew
    if (time_status === 'overbooked' || time_status === 'overbooking') {
      show_time_overbooking = true
      save_res_background_color = '#c00'
      new_book_prefix_sext = 'Overbook'
    } else if (time_status === 'no_tables') {
      show_time_no_tables = true
      save_res_background_color = '#c00'
      new_book_prefix_sext = 'Overbook'
      if (time_sub_status === 'only_larger_tables') {
        time_no_tables_sext = 'only larger tables available'
      } else if (time_sub_status === 'only_larger_blocked_tables') {
        time_no_tables_sext = 'only larger blocked tables available'
      } else {
        time_no_tables_sext = 'no tables available'
      }
    } else if (time_status === 'blocked' || time_status === 'held') {
      time_block_sext = time_block_name
      save_res_background_color = '#c00'
      new_book_suffix_sext = '(blocked)'
    } else if ($('[name="force_request"]').val() === '1' || this._reservations_require_approval) {
      new_book_prefix_sext = 'Request'
    }

    if (table_is_blocked) {
      time_block_sext = table_block_name
      save_res_background_color = '#c00'
      if (table_is_table_size_too_large) {
        new_book_suffix_sext = '(larger table size & blocked)'
      } else if (table_is_table_size_too_small) {
        new_book_suffix_sext = '(smaller table size & blocked)'
      } else {
        new_book_suffix_sext = '(blocked)'
      }
    } else if (table_is_table_size_too_large) {
      save_res_background_color = '#c00'
      new_book_suffix_sext = '(larger table size)'
    } else if (table_is_table_size_too_small) {
      save_res_background_color = '#c00'
      new_book_suffix_sext = '(smaller table size)'
    }

    // Do the dew
    $('#save-reservation').css({ background: save_res_background_color })
    $('#time-display span.overbooking').toggle(show_time_overbooking)
    $('#time-display span.no_tables').toggle(show_time_no_tables)
    $('#time-display span.no_tables').sext(time_no_tables_sext)
    $('#time-display span.block').toggle(time_block_sext !== undefined)
    $('#time-display span.block').sext(time_block_sext)
    $('#save-reservation .new').sext(''.concat(new_book_prefix_sext, ' ', time_display, ' ', new_book_suffix_sext))
  },

  _loadTableMenu: function (slot, choose_recommended, choose_custom) {
    var that = this

    var $tables = $('#preloaded-tables')

    $('#table-inputs').html('')
    $tables.html('')

    var unassigned_section_label = this._autoselect_table ? 'Currently Auto-Assigned' : 'Not Recommended'
    var recs = $('<optgroup label="Recommended" />')
    var unassigned = $('<optgroup label="' + unassigned_section_label + '" />')
    var blocked = $('<optgroup label="Blocked" />')
    var larger_table_size = $('<optgroup label="Larger Table Size" />')
    var smaller_table_size = $('<optgroup label="Smaller Table Size" />')
    var larger_and_blocked = $('<optgroup label="Larger Table Size &amp; Blocked" />')
    var smaller_and_blocked = $('<optgroup label="Smaller Table Size &amp; Blocked" />')

    var all_table_array = []
    var found_preassigned = false

    function render(item) {
      var option = $('<option />')
      var block_name = ''
      option.val(item['id'])
      if (item.is_hold) {
        option.attr('is_blocked', 1)
        block_name = item.hold_name
      } else if (item.is_blocked) {
        option.attr('is_blocked', 1)
        block_name = item.blocks[0].name
      }
      if (item.is_table_size_too_large) {
        option.attr('is_table_size_too_large', 1)
      }
      if (item.is_table_size_too_small) {
        option.attr('is_table_size_too_small', 1)
      }
      all_table_array.push(item['id'])

      if (that.table_ids_list === item['id'] && !choose_recommended) {
        option.prop('selected', true)
        found_preassigned = true
      }

      var option_text = item['name'].concat(' (', item['party_size_range'], ')')
      if (block_name.length > 0) {
        option.attr('block_name', block_name)
        option_text = option_text.concat(' ', block_name)
      }
      option.sext(option_text)
      return option
    }
    var iter

    for (iter = 0; iter < slot['recommended'].length; iter++) {
      recs.append(render(slot['recommended'][iter]))
    }

    for (iter = 0; iter < slot['unassigned'].length; iter++) {
      unassigned.append(render(slot['unassigned'][iter]))
    }

    var has_larger_and_blocked = false
    var has_smaller_and_blocked = false
    var has_bookable_blocks = false
    var tid_list
    if (this.table_ids_list) {
      tid_list = this.table_ids_list.split(',')
    } else {
      tid_list = []
    }

    for (iter = 0; iter < slot['blocked'].length; iter++) {
      var table = slot['blocked'][iter]
      var is_selected = _.contains(tid_list, table['id'])
      if (Pmp.Manager.Global._can_overbook || table['has_permissions'] || is_selected) {
        if (table.is_table_size_too_large) {
          larger_and_blocked.append(render(table))
          has_larger_and_blocked = true
        } else if (table.is_table_size_too_small) {
          smaller_and_blocked.append(render(table))
          has_smaller_and_blocked = true
        } else {
          blocked.append(render(table))
          has_bookable_blocks = true
        }
      }
    }

    for (iter = 0; iter < slot['held'].length; iter++) {
      var table = slot['held'][iter]
      var is_selected = _.contains(tid_list, table['id'])
      if (Pmp.Manager.Global._can_overbook || is_selected) {
        if (table.is_table_size_too_large) {
          larger_and_blocked.append(render(table))
          has_larger_and_blocked = true
        } else if (table.is_table_size_too_small) {
          smaller_and_blocked.append(render(table))
          has_smaller_and_blocked = true
        } else {
          blocked.append(render(table))
          has_bookable_blocks = true
        }
      }
    }

    var is_override = false
    var has_larger_table_size = false
    var has_smaller_table_size = false
    for (iter = 0; iter < slot['table_size_too_large'].length; iter++) {
      var table = slot['table_size_too_large'][iter]
      var is_selected = this.table_ids_list === table['id']
      if (Pmp.Manager.Global._can_overbook || is_selected) {
        larger_table_size.append(render(table))
        has_larger_table_size = true
        if (is_selected) {
          // selected table is not a recommended one (ie party size min/max mismatch)
          is_override = true
        }
      }
    }
    for (iter = 0; iter < slot['table_size_too_small'].length; iter++) {
      var table = slot['table_size_too_small'][iter]
      var is_selected = this.table_ids_list === table['id']
      if (Pmp.Manager.Global._can_overbook || is_selected) {
        smaller_table_size.append(render(table))
        has_smaller_table_size = true
        if (is_selected) {
          // selected table is not a recommended one (ie party size min/max mismatch)
          is_override = true
        }
      }
    }

    $tables.append($('<option />'))

    if (slot['recommended'].length > 0) {
      $tables.append(recs)
    }

    if (slot['unassigned'].length > 0) {
      $tables.append(unassigned)
    }

    if (has_bookable_blocks === true) {
      $tables.append(blocked)
    }

    if (has_larger_table_size === true) {
      $tables.append(larger_table_size)
    }

    if (has_smaller_table_size === true) {
      $tables.append(smaller_table_size)
    }

    if (has_larger_and_blocked === true) {
      $tables.append(larger_and_blocked)
    }

    if (has_smaller_and_blocked === true) {
      $tables.append(smaller_and_blocked)
    }

    $tables.removeClass('disabled')
    var no_tables = !slot['unassigned'].length && !slot['recommended'].length && has_bookable_blocks === false
    var option_text = ''
    var option_val = ''
    // eujern (5/20/2016)
    // This code is shit, but the intention is to always show the "blank" option (ie no assignment),
    // show "Auto Assign" if auto assign is turned on, and have it selected if choose_recommended is true.
    // If there are no tables, we show the 'no tables' option, and disable the select menu.
    if (this._autoselect_table) {
      option_text = 'Auto Assign'
      option_val = this._AUTO_ASSIGN_VAL
    } else if (no_tables) {
      option_text = 'no tables'
      option_val = ''
    }
    if (option_text) {
      var option_el = $('<option value="' + option_val + '"/>').sext(option_text)
      option_el.prop('selected', choose_recommended)
      $tables.children().eq(0).after(option_el) // Insert after the empty option
    }

    if (tid_list.length && !found_preassigned) {
      // Custom combo may have been selected on mobile, so we want to retain that assignment
      this._actual_is_custom_assign = true
      choose_custom = true
      $('.insert-table').html('')
      this.setSelectedCustomTables(tid_list)
    }
    // show 'Custom' if user can override table combos, or if the res already has custom selected
    if (this._can_override_table_combos || choose_custom) {
      var option_el = $('<option value="' + this._CUSTOM_ASSIGN_VAL + '">Custom</option>')
      option_el.prop('selected', choose_custom)
      $tables
        .children()
        .eq(option_text ? 1 : 0)
        .after(option_el) // Insert after the recommended or empty option
    }

    if (no_tables && !Pmp.Manager.Global._can_overbook) {
      $tables.addClass('disabled')
    }
    $tables.trigger('change')

    // Seating area is now an important input,
    // so don't mess with it in results.
    if (this._is_nightlife_class) {
      this._filterSeatingAreasByTables(all_table_array)
    }

    // check to see if the party size is within the chosen table's max/min restrictions
    if (!is_override && this.table_ids_list) {
      var party_size = parseInt($('#the-real-party-size').val())
      var tids = this.table_ids_list
      var table_or_combo = this._lookupTableOrCombo(tids)
      is_override = table_or_combo && (table_or_combo['min'] > party_size || party_size > table_or_combo['max'])
    }
    return is_override
  },

  _changeShift: function (id, reset_times) {
    var that = this
    var shift = this._shiftDict[id]

    if (!shift) {
      return
    }

    // This might be better done backside. If it stays in JS,
    // move it to _loadShifts to prepop the objects.

    var start_order = shift.start_time_sort_order
    var start = timeutils.sort_order_to_time(start_order, this._is_military_time)
    var end_order = shift.end_time_sort_order
    var end = timeutils.sort_order_to_time(end_order, this._is_military_time)
    var interval_orders = shift.interval_minutes / 15

    var grabtime = $('#actual-date-submit').val()
    if (!grabtime) {
      grabtime = this._getActiveDateUrlParam()
    }

    var pad = dateFormat(this._now_by_zone(), grabtime.replace(/-/g, '/'))
    var realtime = new Date(pad + ' ' + start)
    var endtime = new Date(pad + ' ' + end)

    if (!that.time_selected || reset_times) {
      this._resetTimesAndTables()
      if ($('[name="force_request"]').val() === '1' || that._reservations_require_approval) {
        $('#save-reservation .new').sext('Request')
      } else {
        $('#save-reservation .new').sext('Book')
      }
      $('#edit-time-button').trigger('click')
    } else {
      $('#real-est-arrival-time').val(that.time_selected)
    }

    var times = []
    var currenttime = this._now_by_zone()
    var difference

    this.startSlide = false
    this.minSlide = false

    // test for future
    var selectedDate = $('#actual-date-submit').val()
    var check = this._now_by_zone()
    var future = false
    check.setHours(0, 0, 0, 0)
    if (new Date(selectedDate).getTime() > check.getTime()) {
      future = true
    }

    var time = null
    var iter = 0
    var loading = true
    var matched = false
    var order_cursor = start_order

    // NEW PARADIGM, can potentially phase out values set
    this.ts_min = realtime.getTime()
    this.ts_from = realtime.getTime()
    this.ts_max = 0
    this.ts_to = 0
    this.ms_step = shift.interval_minutes * 60 * 1000

    if (this._is_nightlife_class) {
      return
    }
    while (loading) {
      time = dateFormat(realtime, this._timeFormat)

      if (this.startSlide === false) {
        this.startSlide = 0
      }

      difference = Math.abs(currenttime.getTime() - realtime.getTime()) / 60 / 1000

      if (difference < shift.interval_minutes && !matched && !future) {
        matched = true
        this.minSlide = iter

        if (!this.startSlide) {
          this.startSlide = iter
          this.ts_from = realtime.getTime()
        }
      }

      times.push(time)

      if (order_cursor >= end_order) {
        loading = false
        this.ts_max = realtime.getTime()
      }
      order_cursor += interval_orders
      realtime.setMinutes(realtime.getMinutes() + shift.interval_minutes)
      iter++
    }

    // Signifies shift is over

    if (currenttime.getTime() > realtime.getTime() && $('#res_shift')[0] && !that.isEditing()) {
      // Look for next shift
      var index = $('#res_shift')[0].selectedIndex

      if (index !== $('#res_shift option').length - 1) {
        $('#res_shift')[0].selectedIndex += 1
        $('#res_shift').trigger('change')
        return
      }
    }

    if (that.startSlide < that.minSlide) {
      if (that._is_dining_class) {
        $('#main-interface').addClass('past_time')
      }
      return
    }

    this.ts_to = this.ts_max

    this.endSlide = times.length - 1
    this.shiftTimes = times

    // NOTE: duration must be reset prior to fetching availability
    // _setupTimeSelecting will trigger an availability fetch
    this.resetShiftDuration()
    this._setupTimeSelecting()
  },

  close: function () {
    this.clear()
    Interface.closeslide('#main-interface')
    return false
  },
}
