var ReservationSearchBar = {
  initialize: function () {
    var that = this
    this.$searchType = $('.search-interface.res-page .search-type')
    this.$dropDown = $('.search-interface.res-page .search-type-dropdown')
    this.$current = $('.search-interface.res-page .search-type .current')
    this.$item = $('.search-interface.res-page .search-type-dropdown .item')
    this.$searcher = $('#search-reservations')
    this.currentSearchType = 'all'

    // toggle dropdown
    this.$searchType.on('click', function () {
      that.$dropDown.toggleClass('no-display')
    })

    // clicking dropdown item selects search type
    this.$item.on('click', function () {
      that.$item.removeClass('selected')
      $(this).addClass('selected')
      if ($(this).hasClass('all')) {
        that.$current.sext('all')
        that.currentSearchType = 'all'
      } else {
        that.$current.sext('today')
        that.currentSearchType = 'today'
      }
      that.$dropDown.addClass('no-display')
      that._performSearch()
    })

    // document clicks hide dropdown
    $(document).on('click', function (e) {
      var c1 = $('.search-interface.res-page')
      if (c1.has(e.target).length === 0) {
        that.$dropDown.addClass('no-display')
      }
    })

    var raw_search_func = function () {
      that._performSearch()
    }

    var debounced_search = _.debounce(raw_search_func, 100)
    // perform a search on keypress
    this.$searcher.on('keyup', function () {
      debounced_search()
    })
  },

  _performSearch: function () {
    if (!this.$searcher || !this.$searcher.length) {
      return
    }

    var seek = this.$searcher.val().toLowerCase()

    // Without this, double id situation shows up
    $('#search-results').find('.booked-row').remove()

    if ($('#canceled-rows').children().length) {
      $('#daily-results').find('#canceled-block').show()
      $('#show-hide-canceled').show()
    }

    if (!seek) {
      this._hideSearchResults()
      this._showDailyResults()
      $('#stats-bar').show()
      return
    }

    if (this.currentSearchType === 'today') {
      this._hideSearchResults()
      this._showDailyResults()
      $('#stats-bar').show()
    } else {
      this._showSearchResults()
      this.search(seek)
    }
  },

  // In the bottom two functions, daily-list list-block
  // hits the canceled block
  _showDailyResults: function () {
    var $daily_results = $('#daily-results')
    $daily_results.find('.table-header').show()
    $daily_results.find('.list-block').show()
    if (!$('#canceled-rows').children().length) {
      $daily_results.find('#canceled-block').hide()
    }
    $('#no-res-text').hide()
  },

  _showSearchResults: function () {
    var $daily_results = $('#daily-results')
    $daily_results.find('.table-header').hide()
    $daily_results.find('.list-block').hide()
  },

  _hideSearchResults: function () {
    $('#booked-box').find('#search-results').addClass('no-display')
    $('#loading-row').hide()
  },

  search: function (searchText) {
    var $target = $('#booked-box')
    $target.find('.standard-row').show()
    if (searchText.length < 2) return
    $('#loading-row').show()
    $('#stats-bar').hide()
    ReservationSearchService.search(searchText)
  },

  showResults: function (actuals) {
    $('#loading-row').hide()

    // Prevents a kind of race condition
    if ($('#daily-results').find('.booked-block').is(':visible')) {
      return
    }

    var $search_results = $('#booked-box').find('#search-results')
    var upcomingResults = $search_results.find('.list-block.upcoming')
    var upcomingHeader = $('.table-header.upcoming')
    var pastResults = $search_results.find('.list-block.past')
    var pastHeader = $('.table-header.past')
    var results = $('#booked-box').find('#search-results')
    upcomingResults.empty()
    pastResults.empty()
    var dayRes = Pmp.Manager.Reservations.Day

    // Massage data
    for (var i = 0; i < actuals.length; i++) {
      var actual = actuals[i]
      if (actual.table_codes_display) {
        actual.table_codes_display = 'Table ' + actual.table_codes_display
      }

      var html = Nightloop.Templates.Manager.Reservations.ActualRow({
        MEDIA_URL: dayRes._media_url,
        actual: actual,
        isEven: i % 2 === 0,
        isCanceled: actual.is_canceled,
        use_full_dining_backend: dayRes._use_full_dining_backend,
        is_nightlife_class: dayRes._is_nightlife_class,
        using_total_dollars_cost_option: dayRes._using_total_dollars_cost_option,
        show_cost_options: dayRes._show_cost_options,
        payout_profile: dayRes._payout_profile,
        is_search_row: true,
        show_time_column: dayRes._res_display_grouping,
      })
      if (actual.is_upcoming_res) {
        upcomingResults.append(html)
      } else {
        pastResults.append(html)
      }
      $('#search-results').find('.col-client, .col-venuenote, .client-info-source').highlight($('#search-reservations').val())
      results.removeClass('no-display')
    }

    // only show headers if they have results
    if (!upcomingResults.children().length) {
      upcomingHeader.hide()
    } else {
      upcomingHeader.show()
    }

    if (!pastResults.children().length) {
      pastHeader.hide()
    } else {
      pastHeader.show()
    }
  },
}

var ReservationsDropDownHelper = {
  bindEvents: function () {
    $('.reservation-button .button').click(function (e) {
      e.stopPropagation()
      $('.reservation-drop-down-wrapper').css('visibility', 'visible')
      $('.reservation-button .collapsed').css('visibility', 'hidden')
    })

    $(document).click(function (e) {
      $('.reservation-drop-down-wrapper').css('visibility', 'hidden')
      $('.reservation-button .collapsed').css('visibility', 'visible')
    })
  },
}

$(function () {
  // only load if it's the reservations page
  if ($('#booked-box').length === 0) {
    return
  }

  var date_url = $('#current_date').val()
  var base_url = $('#base_url').val()
  var current_date = $.datepicker.parseDate('mm-dd-yy', date_url)
  var seating_areas_map = {}
  var seating_area_name_map = {}
  var seating_area_name_to_id_map = {}
  var id_to_seating_area_name_map = {}
  var table_code_to_id_map = {}
  var id_to_table_code_map = {}
  var seating_area_tables
  var all_tables_party_size_max = 0
  var table_max_party_size = {}

  //remove selected highlight
  $('#close-interface').on('click', function () {
    $('.standard-row').removeClass('selected')
  })

  // blackout date

  $('#blackout-toggle-link').on('click', function () {
    var toggle = $('#blackout-toggle-link').find('span.blackout')
    var enabled = toggle.hasClass('enabled')
    var deferred

    if (enabled) {
      $('#blackout-confirm').show()
      SvrManager.Components.mountConfirmModal(
        'blackout-confirm',
        'Are you sure?',
        'Removing a blackout will open back up external bookings for this entire day',
        'Remove Blackout',
        function (unmount) {
          unmount()
          setTimeout(() => {
            $('#blackout-confirm').hide()
          }, 0)
        },
        function (unmount) {
          unmount()

          deferred = BlackoutDateService.unblackoutDateReservation(
            Pmp.Manager.Reservations.Day._managerBaseUrl,
            Pmp.Manager.Reservations.Day._dateUrlParam
          )
          deferred.done(function (isEnabled) {
            $('.ui-datepicker-current-day').removeClass('blacked-date')
            toggle.removeClass('enabled')
            $('p.blackout').hide()
            $('#blackout-confirm').hide()
            metric.track('Reservations.toggleBlackoutDate.disabled', {
              page: 'reservations',
            })
          })
        },
        'WARNING'
      )
    } else {
      $('#blackout-confirm').show()
      SvrManager.Components.mountConfirmModal(
        'blackout-confirm',
        'Are you sure?',
        'Adding a blackout will prevent external bookings for this entire day',
        'Add Blackout',
        function (unmount) {
          unmount()
          setTimeout(() => {
            $('#blackout-confirm').hide()
          }, 0)
        },
        function (unmount) {
          unmount()

          deferred = BlackoutDateService.blackoutDateReservation(
            Pmp.Manager.Reservations.Day._managerBaseUrl,
            Pmp.Manager.Reservations.Day._dateUrlParam
          )

          deferred.done(function (isEnabled) {
            $('.ui-datepicker-current-day').addClass('blacked-date')
            toggle.addClass('enabled')
            $('p.blackout').show()
            $('#blackout-confirm').hide()
            metric.track('Reservations.toggleBlackoutDate.enabled', {
              page: 'reservations',
            })
          })
        },
        'WARNING'
      )
    }
  })

  // Initiate block slideout callbacks
  var refreshBlocklist = function () {
    Pmp.Manager.Reservations.Day.updateBlocksList()
  }
  SvrManager.BlockSlideout.registerCallbackHandlers({
    onAdd: refreshBlocklist,
    onEdit: refreshBlocklist,
    onDelete: refreshBlocklist,
  })
  $('#close-block-slideout-button').click(function () {
    BookingBlockSlideOut.close()
  })

  $('#stats-bar')
    .find('.toggler, .clickable')
    .on('click', function () {
      var $stats_bar = $('#stats-bar')
      var expanded = $stats_bar.hasClass('expanded')
      var expanded_height = $stats_bar.height()
      if (expanded) {
        $stats_bar.removeClass('expanded').animate({ bottom: 40 - expanded_height }, 100)
      } else {
        $stats_bar.addClass('expanded').animate({ bottom: '0' }, 100)
      }
    })

  // Door notes
  var hideOrShowToggleFn = function () {
    // hack to determine if text flows over a line
    var $door_notes = $('#door-notes'),
      $door_notes_expand_link = $door_notes.find('.expand-link'),
      $door_notes_close_link = $door_notes.find('.close-link')
    var expandedHeight = $door_notes
      .find('span.note')
      .clone()
      .css({ display: 'inline', width: '100%', float: 'left', visibility: 'hidden' })
      .appendTo('#main-area')
      .height()

    if (expandedHeight < 20) {
      $('#door-notes').find('.toggle').hide()
    } else {
      if ($('#door-notes').hasClass('expanded')) {
        $door_notes_expand_link.hide()
        $door_notes_close_link.show()
      } else {
        $door_notes_expand_link.show()
        $door_notes_close_link.hide()
      }
    }
  }

  var $door_notes = $('#door-notes')
  $door_notes.find('.toggle').on('click', function () {
    $door_notes.toggleClass('expanded')
    hideOrShowToggleFn()
  })
  $('#door-notes-link').on('click', function () {
    $door_notes.addClass('editing expanded')
    hideOrShowToggleFn()
    metric.track('Reservations.editDailyNotes', { page: 'reservations' })
  })
  $door_notes.find('.cancel-btn').on('click', function () {
    $door_notes.removeClass('editing expanded')
    $door_notes.find('textarea').val($door_notes.find('div.view span.note').html().trim())
    hideOrShowToggleFn()
  })
  $door_notes.find('.save-btn').on('click', function () {
    var newNote = $('#door-notes').find('textarea').val().trim()
    Pmp.Manager.Reservations.Day.saveDoorNotes(newNote, function () {
      $door_notes.removeClass('editing expanded')
      $door_notes.find('div.view span.note').sext(newNote)
      if (newNote === '') {
        $door_notes.addClass('no-data')
      } else {
        $door_notes.removeClass('no-data')
      }
      hideOrShowToggleFn()
    })
    hideOrShowToggleFn()
  })
  hideOrShowToggleFn()

  // adding a new block
  $('#add-block-dropdown').on('click', function () {
    var date = $.datepicker.parseDate('mm-dd-yy', Pmp.Manager.Reservations.Day._dateUrlParam)
    SvrManager.BlockSlideout.addBlock({ date: moment(date) })
  })

  // booking a new reservation
  $('#book-new').on('click', function (e) {
    if ($(e.target).hasClass('disabled')) {
      return
    }
    var date = $.datepicker.parseDate('mm-dd-yy', Pmp.Manager.Reservations.Day._dateUrlParam)
    metric.track('Reservations.bookNew', { page: 'reservations' })
    if (globalInit.venueSettings.use_supafly) {
      var url_key = Pmp.Manager.Reservations.Day._venue_url_key
      var user_domains = Pmp.Manager.Global.UserDomainVenues.venues
      var venue = user_domains.filter(function (venue) {
        return venue.url_key == url_key
      })[0]
      SvrManager.ActualSlideout.addReservation({ date: moment(date), accountId: venue.account_id })
    } else {
      ReservationSlideOut.bookNew(date)
    }
  })

  $('#add-request').on('click', function (e) {
    if ($(e.target).hasClass('disabled')) {
      return
    }

    var date = $.datepicker.parseDate('mm-dd-yy', Pmp.Manager.Reservations.Day._dateUrlParam)
    metric.track('Reservations.addRequest', { page: 'reservations' })
    ReservationSlideOut.bookNew(date, undefined, undefined, 'force_request')
  })

  var new_handler = function (actual, response, assignments_and_actual_problems_promise) {
    if (
      response.status === 200 &&
      (response.data.actual !== undefined || response.data.needs_approval || response.data.request_added)
    ) {
      if (response.data.needs_approval) {
        Interface.alertSuccess('Reservation request created. Awaiting approval.')
        ReservationSlideOut.close()
        return
      }

      if (response.data.request_added) {
        Interface.alertSuccess('Reservation request added.')
        ReservationSlideOut.close()
        return
      }

      if (response.data.charge_status === 'FAILED') {
        Interface._alert('Reservation was booked, but charge failed!')
      }

      var date = $.datepicker.parseDate('mm/dd/yy', actual.date)
      if (date.getTime() !== current_date.getTime()) {
        ReservationSlideOut.close()
        return
      }

      var spid = $('#shift-select').val()
      // if no shift is selected or the actual somehow does not have a shift persistent id, then we do want to show it
      if (spid && actual.shift_persistent_id && spid !== actual.shift_persistent_id) {
        ReservationSlideOut.close()
        return
      }
      var assignmentsAndActualProblemsPromise =
        assignments_and_actual_problems_promise || Pmp.Manager.Reservations.Day.AssignmentsAndActualProblemsPromise()

      Pmp.Manager.Reservations.Day.updateAssignmentsAndProblems(assignmentsAndActualProblemsPromise)
      Pmp.Manager.Reservations.Day._updateActualCache(actual)
      Pmp.Manager.Reservations.Day._rerenderActuals(true, true)

      // fade away the just added color
      var el = $('#actual-row-' + actual.id)
      el.addClass('just-added')
      el.animate({ backgroundColor: '#ffffff' }, 5000)
    } else {
      Interface._alert('Something went wrong!')
    }
  }

  var edit_handler = function (original_actual_id, actual, response, assignments_and_actual_problems_promise) {
    if (response.status === 200 && response.data.actual !== undefined) {
      var old_row = $('#actual-row-' + original_actual_id)
      if (old_row.length === 0) {
        return
      }

      Pmp.Manager.Reservations.Day._updateActualCache(actual, original_actual_id)
      if ($('#search-results').is(':visible')) {
        ReservationSearchBar._performSearch()
      } else {
        Pmp.Manager.Reservations.Day._rerenderActuals(true, true)
      }

      Pmp.Manager.Reservations.Day.updateAssignmentsAndProblems(assignments_and_actual_problems_promise, actual)
    }
  }

  var charge_handler = function (actual_id, prepayment_formatted) {
    var prepayment = $('#actual-row-' + actual_id + ' .col-payment .number-fix')
    if (prepayment) {
      prepayment.sext(prepayment_formatted)
    }
    prepayment = $('#tab-display-res').find('.-prepayment_formatted')
    if (prepayment) {
      prepayment.sext(prepayment_formatted)
    }
  }

  // Define how to update stuff on the page with slideout actions
  if (globalInit.venueSettings.use_supafly) {
    SvrManager.ActualSlideout.registerCallbackHandlers({
      onBook: new_handler,
      onEditSave: edit_handler,
      onCancel: Pmp.Manager.Reservations.Day.cancel_handler,
      onUncancel: Pmp.Manager.Reservations.Day.uncancel_handler,
    })

    // Needed to make requests work
    ReservationSlideOut.onBookNew(new_handler)
  } else {
    ReservationSlideOut.onBookNew(new_handler)
    ReservationSlideOut.onEdit(edit_handler)
    ReservationSlideOut.onCharge(charge_handler)
    ReservationSlideOut.onCancel(Pmp.Manager.Reservations.Day.cancel_handler)
    ReservationSlideOut.onUncancel(Pmp.Manager.Reservations.Day.uncancel_handler)
  }
})

var Pmp = Pmp || {}
Pmp.Manager = Pmp.Manager || {}
Pmp.Manager.Reservations = Pmp.Manager.Reservations || {}

Pmp.Manager.Reservations.Day = {
  initialize: function (
    managerBaseUrl,
    dateUrlParam,
    todayDate,
    muni_today_date,
    media_url,
    is_live_day,
    num_res_pages,
    res_display_grouping,
    is_nightlife_class,
    use_full_dining_backend,
    group_by_type,
    seating_area_id_to_code,
    summary,
    using_total_dollars_cost_option,
    payout_profile,
    currency_symbol,
    show_cost_options,
    seating_area_id_to_sort_order,
    seating_area_id_to_name
  ) {
    /* NOTE: This is used by the staff summary print page too.
     * If you change the param list you need to do it there as well! */

    var self = this
    var destroyerFn = function () {
      self.destroy()
    }

    var _isValidGroupByType = function (group_by_type) {
      return (
        group_by_type in
        {
          VIP_NONVIP: true,
          DEFAULT: true,
          RES_STATUS: true,
          RES_TYPE: true,
          RES_TIME: true,
          SEATING_AREA: true,
          TABLE_BAR: true,
        }
      )
    }

    Pmp.Client.Static.Factory.Register(Nightloop.Templates.Manager.Reservations.Day, null, destroyerFn)

    // Despite the underscores, many of these properties are used externally
    this._summary = summary
    this._managerBaseUrl = managerBaseUrl
    this._dateUrlParam = dateUrlParam
    this._doRefreshOnClose = false
    this._media_url = media_url
    this._is_live_day = is_live_day
    this._ms_per_day = 24 * 60 * 60 * 1000
    this._actuals = []
    this._res_status = {}
    this._user_domain_venue_subscribers = []

    var url_parts = managerBaseUrl.split('/')
    this._venue_url_key = url_parts[url_parts.length - 1]
    this._muni_today_date = muni_today_date
    this._res_display_grouping = res_display_grouping
    this._is_nightlife_class = is_nightlife_class
    this._use_full_dining_backend = use_full_dining_backend
    this._seating_area_id_to_code = seating_area_id_to_code
    this._using_total_dollars_cost_option = using_total_dollars_cost_option
    this._group_by_type = group_by_type // DEFAULT, SEATING_AREA, TABLE_BAR, PAYING_COMP, RES_TYPE
    // Check for a valid group by type. This avoids errors when these change.
    // Ignore non-default group by types on mobile since there is no way to change it anyway.
    if (!_isValidGroupByType(group_by_type) || this._is_mobile) {
      this._group_by_type = 'DEFAULT'
    }
    this._payout_profile = payout_profile
    this._assignments = {}
    this._actual_problems = {}
    this._currency_symbol = currency_symbol
    this._show_cost_options = show_cost_options
    this._seating_area_id_to_sort_order = seating_area_id_to_sort_order
    this._seating_area_id_to_name = seating_area_id_to_name
    LazyActualsService.initialize(managerBaseUrl, dateUrlParam)

    if (!this._is_nightlife_class && !this._summary) {
      this._initCalendar()
    }
    this._bindClickHandlers()
    this._hideMessageOnDelay()

    if ($('body').hasClass('mobile')) {
      $('#actual-header-wrapper').remove()
    }

    Pmp.Manager.Reservations.Day.updateBlocksList()

    ReservationSearchService.initialize(
      this._managerBaseUrl,
      undefined, // start date
      undefined, // end date
      undefined, // all shifts
      undefined, // bookedby
      10, // date
      ReservationSearchBar.showResults
    )

   if (!Pmp.Manager.Global.UserDomainVenues) {
      Pmp.Manager.Global.subscribeOnUserDomainVenuesLoad(this.loadAddReservationWithClient)
    } else {
      this.loadAddReservationWithClient()
    }
  },

  loadAddReservationWithClient: function () {
    const params = Pmp.Utils.getParamsFromUrl()
    if (params.hasOwnProperty('cid' ) && params.cid) {
      if (globalInit.venueSettings.use_supafly) {
        const venue = Pmp.Manager.Global.UserDomainVenues.venues.find(venue => venue.url_key === Pmp.Manager.Reservations.Day._venue_url_key)
        SvrManager.ActualSlideout.addReservation({date: moment(this.todayDate), accountId: venue.account_id, clientId: params.cid})
      } else {
        ReservationSlideOut.bookNew(this.todayDate)
      }
    }
  },
  log: function (msg) {
    console.log('Pmp.Manager.Reservations.Day: ' + msg)
  },

  debug: function (msg) {
    if (Pmp.Settings.DEBUG) {
      this.log(msg)
    }
  },

  destroy: function () {
    this.debug('destroying')
    $(document).unbind('mouseup')
  },
  cancel_handler: function (id) {
    var clonedElement = $('#actual-row-' + id).clone()
    clonedElement.removeClass('non-canceled-rows')
    $('#actual-row-' + id).remove()
    $('#canceled-rows').append(clonedElement)

    // A little weird this to be so manual, but
    // the canceled row doesn't rerender.
    clonedElement.find('.col-status').sext('Canceled')
    var assignmentsAndActualProblemsPromise = Pmp.Manager.Reservations.Day.AssignmentsAndActualProblemsPromise()

    Pmp.Manager.Reservations.Day._cancelActualInCache([id])
    Pmp.Manager.Reservations.Day._rerenderActuals(true, true)
    $('#daily-results').find('#canceled-block').show()
    $('.show-canceled-link').click()
    Pmp.Manager.Reservations.Day.updateAssignmentsAndProblems(assignmentsAndActualProblemsPromise)
  },
  uncancel_handler: function (id) {
    Pmp.Manager.Reservations.Day._adjustTableHeaders()
    $('#actual-row-' + id).remove()
    Pmp.Manager.Reservations.Day._lazyLoadReservations(
      function () {
        var actualEl = $('#actual-row-' + id)
        if (actualEl.length) {
          actualEl.addClass('selected')
        }
      },
      function () {
        Pmp.Manager.Reservations.Day._uncancelActualInCache(id)
      }
    )
  },
  _hideMessageOnDelay: function () {
    var $gold_message = $('.gold-message')
    if ($gold_message) {
      $gold_message.delay(5000).slideUp(1000)
    }
  },
  _INSERT_AFTER_GROUPS_ID: '#insert-groups',
  _bindClickHandlers: function () {
    this.debug('bindClickHandlers')
    var self = this
    var onClickBookedResRowFn = function (event) {
      self._onClickBookedResRow(event)
    }
    var onClickShowCanceledFn = function (event) {
      self._onClickShowCanceled(event)
    }
    var onClickHideCanceledFn = function (event) {
      self._onClickHideCanceled(event)
    }
    var onMouseEnterClientRequestFn = function (event) {
      self._onMouseEnterClientRequest(event)
    }
    var onMouseLeaveClientRequestFn = function (event) {
      self._onMouseLeaveClientRequest(event)
    }
    var onMouseEnterClientInfoReservedFn = function (event) {
      self._onMouseEnterClientInfoReserved(event)
    }
    var onMouseLeaveClientInfoReservedFn = function (event) {
      self._onMouseLeaveClientInfoReserved(event)
    }
    var onClickEditDailyNotesLinkFn = function (event) {
      self._onClickEditDailyNotesLink(event)
    }
    var onClickRefreshLinkFn = function (event) {
      self._onClickRefreshLink(event)
    }
    var onClickGroupLinkFn = function (event) {
      self._onClickGroupLink(event)
    }
    var onClickSortLink = function (event) {
      self._onClickSortLink(event, $(this))
    }

    var onClickRegroupDefaultFn = function (event) {
      self._onClickRegroupDefault(event, $(this))
    }
    var onClickRegroupSeatingAreaFn = function (event) {
      self._onClickRegroupSeatingArea(event, $(this))
    }
    var onClickRegroupResStatusFn = function (event) {
      self._onClickRegroupResStatus(event, $(this))
    }
    var onClickRegroupTableBarFn = function (event) {
      self._onClickRegroupTableBar(event, $(this))
    }
    var onClickRegroupPayingCompFn = function (event) {
      self._onClickRegroupPayingComp(event, $(this))
    }
    var onClickRegroupResTypeFn = function (event) {
      self._onClickRegroupResType(event, $(this))
    }
    var onClickRegroupVipFn = function (event) {
      self._onClickRegroupVip(event, $(this))
    }
    var onClickRegroupTimeFn = function (event) {
      self._onClickRegroupTime(event, $(this))
    }

    var page = Pmp.Client.Static.Page
    page.AddLiveHandler('.booked-row', 'click', onClickBookedResRowFn)
    page.AddLiveHandler('.client-request-hover', 'mouseenter', onMouseEnterClientRequestFn)
    page.AddLiveHandler('.client-request-hover', 'mouseleave', onMouseLeaveClientRequestFn)
    page.AddLiveHandler('.client-info-reserved-hover', 'mouseenter', onMouseEnterClientInfoReservedFn)
    page.AddLiveHandler('.client-info-reserved-hover', 'mouseleave', onMouseLeaveClientInfoReservedFn)
    page.AddLiveHandler('.sort-link', 'click', onClickSortLink)

    if (!self._is_nightlife_class) {
      var onClickReceivedLink = function (event) {
        self._onClickReceivedLink(event, $(this))
        return false
      }
      page.AddLiveHandler('.received-button', 'click', onClickReceivedLink)

      var linkID = '#status-question-mark'
      var divID = '#status-help-tooltip'
      page.AddLiveHandler(linkID, 'mouseenter', function () {
        $(divID).show()
        return true
      })
      page.AddLiveHandler(linkID, 'mouseleave', function () {
        $(divID).hide()
        return true
      })
    }

    $('.show-canceled-link').click(onClickShowCanceledFn)
    $('.hide-canceled-link').click(onClickHideCanceledFn)
    $('.edit-daily-notes').click(onClickEditDailyNotesLinkFn)
    $('#refresh-container').click(onClickRefreshLinkFn)
    $('#regroup-container').click(onClickGroupLinkFn)

    // grouping links
    $('#regroup-link-default').click(onClickRegroupDefaultFn)
    $('#regroup-link-seatingarea').click(onClickRegroupSeatingAreaFn)
    $('#regroup-link-resstatus').click(onClickRegroupResStatusFn)
    $('#regroup-link-tablebar').click(onClickRegroupTableBarFn)
    $('#regroup-link-payingcomp').click(onClickRegroupPayingCompFn)
    $('#regroup-link-restype').click(onClickRegroupResTypeFn)
    $('#regroup-link-vipnonvip').click(onClickRegroupVipFn)
    $('#regroup-link-restime').click(onClickRegroupTimeFn)

    var $blocks_list = $('#blocks-list')
    var $blocks_list_header = $blocks_list.find('.header')

    $blocks_list_header.on('click', function () {
      $blocks_list_header.find('.show-blocks').toggleClass('hide')
      $blocks_list_header.find('.hide-blocks').toggleClass('hide')
      $blocks_list.find('.blocks-list-wrapper').toggleClass('hide')
    })

    // eujern (2/25/15)
    // This may sound crazy, but this is used on the print page.
    // Bigger refactoring needed.
    $('#group-by-results').on('change', function () {
      switch ($(this).val()) {
        case 'tbg':
          self._group_by_type = 'TABLE_BAR'
          onClickRegroupTableBarFn()
          break

        case 'vipag':
          self._group_by_type = 'VIP_NONVIP'
          onClickRegroupVipFn()
          break

        case 'sbg':
          self._group_by_type = 'SEATING_AREA'
          onClickRegroupSeatingAreaFn()
          break

        case 'pcg':
          self._group_by_type = 'PAYING_COMP'
          onClickRegroupPayingCompFn()
          break

        case 'tme':
          self._group_by_type = 'RES_TIME'
          onClickRegroupTimeFn()
          break
        case 'tpe':
          self._group_by_type = 'RES_TYPE'
          onClickRegroupResTypeFn()
          break
        case 'sts':
          self._group_by_type = 'RES_STATUS'
          onClickRegroupResStatusFn()
          break
        default:
          self._group_by_type = 'DEFAULT'
          onClickRegroupDefaultFn()
          break
      }
    })

    $('#shift-select').on('change', function () {
      // Clear out search to prevent weirdness
      $('#search-reservations').val('')
      ReservationSearchBar._performSearch()
      self.updateBlocksList()
      self._lazyLoadReservations()
      var url = self._managerBaseUrl + '/home/doc/staff/' + self._dateUrlParam
      var $shift_select = $('#shift-select')
      var shift = $shift_select.val()
      if (shift) {
        url += '?shift_persistent_id=' + shift
      }

      $('#print-link').attr('href', url)
      var shift_name = $shift_select.find('option:selected').sext()
      $('#shift-title').sext(shift_name)
      Pmp.Utils.updateParametersInUrl({ shift_id: shift })
      metric.track('Reservations.shiftSelected', {
        page: 'reservations',
        'shift-name': shift_name,
      })
    })

    // initialize which grouping
    $('#regroup-link-' + this._group_by_type.toLowerCase().replace('_', '')).addClass('selected')

    var params = Pmp.Utils.getParamsFromUrl()
    var hasTriggeredShiftChange, successHandler
    if (params.hasOwnProperty('shift_id') && params.shift_id) {
      hasTriggeredShiftChange = ShiftSelector.init(this._managerBaseUrl, params.shift_id)
    } else {
      hasTriggeredShiftChange = ShiftSelector.init(this._managerBaseUrl)
    }

    if (params.hasOwnProperty('actual_id') && params.actual_id) {
      successHandler = function () {
        if (globalInit.venueSettings.use_supafly) {
          SvrManager.ActualSlideout.viewActual(params.actual_id)
        } else {
          ReservationSlideOut.showReservation(params.actual_id)
        }
      }
    }

    if (params.hasOwnProperty('order_id') && params.order_id) {
      successHandler = function () {
        SvrManager.OrderSlideout.viewOrder(globalInit.venueId, params.order_id)
      }
    }

    if (!hasTriggeredShiftChange) {
      this._lazyLoadReservations(successHandler)
    }
    if (successHandler) {
      if (!Pmp.Manager.Global.UserDomainVenues) {
        if (Pmp.Manager.Global) {
          Pmp.Manager.Global.subscribeOnUserDomainVenuesLoad(successHandler)
        } else {
          this._user_domain_venue_subscribers.append(successHandler)
        }
      } else {
        successHandler()
      }
    }
  },
  _convertCutOffTypeAndValueIntoDate: function (cutoff_num, cutoff_type) {
    const cutoff_date = new Date()
    if (cutoff_type === 'DAYS') {
        cutoff_date.setDate(cutoff_date.getDate() + cutoff_num)
    } else if (cutoff_type === 'HOURS') {
        cutoff_date.setHours(cutoff_date.getHours() + cutoff_num)
    } else if (cutoff_type === 'MINUTES') {
        cutoff_date.setMinutes(cutoff_date.getMinutes() + cutoff_num)
    } else if (cutoff_type === 'WEEKS') {
        cutoff_date.setDate(cutoff_date.getDate() + (cutoff_num * 7))
    } else if (cutoff_type === 'MONTHS') {
        cutoff_date.setMonth(cutoff_date.getMonth() + cutoff_num)
    }
    return cutoff_date
  },
  _checkIfHeld: function (cutoff_num, cutoff_type, dateUrlParam, is_held) {
    if (!cutoff_type || !cutoff_num) {
      return is_held
    }

    if (cutoff_type === 'HOURS' || cutoff_type === 'MINUTES') {
      const current_time = new Date()
      const cutoff_time = new Date(current_time)
        if (cutoff_type === 'HOURS') {
          cutoff_time.setHours(cutoff_time.getHours() + cutoff_num)
        } else if (cutoff_type === 'MINUTES') {
          cutoff_time.setMinutes(cutoff_time.getMinutes() + cutoff_num)
        }

        if (current_time.getDate() === cutoff_time.getDate() &&
            current_time.getMonth() === cutoff_time.getMonth() &&
            current_time.getFullYear() === cutoff_time.getFullYear()) {
        return is_held
      }
    }
    const [month, day, year] = dateUrlParam.split('-')
    const current_view_date = new Date(year, month - 1, day)
    const is_held_from_date = this._convertCutOffTypeAndValueIntoDate(cutoff_num, cutoff_type, dateUrlParam)
    return is_held && is_held_from_date < current_view_date
  },
  updateBlocksList: function () {
    var $blocks_list = $('#blocks-list')
    $blocks_list.find('.hide-blocks').removeClass('hide').addClass('hide')
    $blocks_list.find('.show-blocks').removeClass('hide').addClass('hide')
    $blocks_list.find('.wrapper').empty()
    self = this;
    var _selectedShiftPersistentId = this._selectedShiftPersistentId(),
      shiftCategory = ShiftSelector.getCategoryFromPersistentId(_selectedShiftPersistentId)
    $.when(
      BookingBlocksService.getBlocks(this._venue_url_key, this._dateUrlParam, _selectedShiftPersistentId),
      AccessRulesService.getSingleDayRules(this._venue_url_key, this._dateUrlParam, shiftCategory)
    ).done(function (blocks_response, rules_response) {
      var held_rules = _.filter(_.flatten(_.values(rules_response.data.access)), function (x) {
        return self._checkIfHeld(x.cutoff_num, x.cutoff_type, self._dateUrlParam, x.is_held)
      })

      held_rules = [...new Map(held_rules.map(rule => [rule.id, rule])).values()]
      var $blocks_list_when_done = $('#blocks-list')
      var $blocks_list_wrapper = $blocks_list_when_done.find('.blocks-list-wrapper')
      $blocks_list_wrapper.empty().append(
        _.map(blocks_response, function (block) {
          return Nightloop.Templates.Manager.Reservations.BookingBlock({
            block: block,
            MEDIA_URL: self._media_url,
          })
        })
      )

      $blocks_list_wrapper.append(
        _.map(held_rules, function (block) {
          return Nightloop.Templates.Manager.Reservations.BookingBlock({
            block: block,
            MEDIA_URL: self._media_url,
          })
        })
      )

      $blocks_list_wrapper.find('.wrapper').on('click', function (e) {
        var is_access_rule = $(e.currentTarget).find('.access_time_type').val()
        if (is_access_rule) {
          //TODO: Remove after access rule slideout is redone
          BookingBlockSlideOut.openEditBlock($(e.currentTarget).find('.id').val(), $(e.currentTarget).find('.name').val())
        } else {
          SvrManager.BlockSlideout.viewBlock({
            blockId: $(e.currentTarget).find('.id').val(),
          })
        }
      })
      if ((blocks_response && blocks_response.length) || (rules_response && rules_response.length)) {
        $blocks_list_when_done.find('.hide-blocks').removeClass('hide')
        $blocks_list_when_done.find('.show-blocks').addClass('hide')
        $blocks_list_wrapper.removeClass('hide')
      }
    })
  },

  saveDoorNotes: function (notes, successHandler) {
    var url = this._managerBaseUrl + '/home/dailynotes/' + this._dateUrlParam + '/edit'
    $.ajax({
      url: url,
      method: 'post',
      data: {
        note: notes,
      },
      success: successHandler,
    })
  },

  _updateActualCache: function (actual, original_actual_id) {
    for (var i = 0; i < this._actuals.length; i++) {
      var cachedActual = this._actuals[i]
      if (cachedActual.id === original_actual_id) {
        if (
          cachedActual.date === actual.date &&
          cachedActual.shift_persistent_id === actual.shift_persistent_id &&
          globalInit.venueId === actual.venue_id
        ) {
          this._actuals[i] = actual // TODO: are all the necessary properties available??
        } else {
          this._actuals.splice(i, 1)
        }
        return
      }
    }
    // at this point, we didn't find an actual, so add it if its same day
    if (actual.date_urlparam === this._dateUrlParam) {
      this._actuals.push(actual)
    }
  },

  _findActualIndex: function (actual_id) {
    for (var i = 0; i < this._actuals.length; i++) {
      var cachedActual = this._actuals[i]
      if (cachedActual.id === actual_id) {
        return i
      }
    }
    return -1
  },

  _removeActualFromCache: function (actual_id) {
    var index = this._findActualIndex(actual_id)
    if (index !== -1) {
      this._actuals.splice(index, 1)
    }
  },

  // Necessary due to eventual consistency on res actions
  _cancelActualInCache: function (actual_ids) {
    actual_ids.forEach(actual_id => {
        var index = this._findActualIndex(actual_id)
        if (index !== -1) {
          this._actuals[index].is_canceled = true
          this._actuals[index].is_status_canceled = true
          this._actuals[index].is_not_completed = true
          this._actuals[index].is_reconciled = true
          this._actuals[index].is_dead = true
          this._actuals[index].status = 'CANCELED'
          this._actuals[index].status_formatted = 'Cancelled'
        }
    });
  },

  _uncancelActualInCache: function (actual_id) {
    var index = this._findActualIndex(actual_id)
    if (index !== -1) {
      this._actuals[index].is_canceled = false
      this._actuals[index].is_dead = false
      this._actuals[index].is_status_canceled = false
      this._actuals[index].is_not_completed = true
      this._actuals[index].is_reconciled = false
      this._actuals[index].status = 'NOT_RECONCILED'
      this._actuals[index].status_formatted = 'Booked'
    }
  },

  _updateAssignments: function (assignments) {
    this._assignments = assignments
  },

  _updateActualProblems: function (actual_problems) {
    this._actual_problems = actual_problems
  },

  _clearUncanceledReservations: function () {
    $('.non-canceled-rows').remove()
  },

  // This data is set during the actual loops in
  // the respective functions.
  _commonCallbacks: function () {
    this._lazyLock = false
    $('#shift-select').prop('disabled', false)
  },

  _selectedShiftPersistentId: function () {
    let selectedShiftKind = $('#shift-select').find(":selected").attr("kind")
    let shift_persistent_id = $('#shift-select').val()
    if (shift_persistent_id && selectedShiftKind !== 'shift-reporting-id') {
      return shift_persistent_id
    } else {
      return undefined
    }
  },

  _selectedShiftReportingPeriodPersistentId: function () {
    let selectedShiftKind = $('#shift-select').find(":selected").attr("kind")
    let shift_persistent_id = $('#shift-select').val()
    if (shift_persistent_id && selectedShiftKind === 'shift-reporting-id'){
      return shift_persistent_id
    } else {
      return undefined
    }
  },

  _renderReservationListNotReady: function () {
    $('p.no-content').html(LazyActualsService.notReadyMessage)
  },

  _lazyLoadReservations: function (successHandler, preRenderHandler) {
    var type_key = 'view'
    var shift_persistent_id = this._selectedShiftPersistentId()
    let shift_reporting_period_persistent_id = this._selectedShiftReportingPeriodPersistentId()
    var self = this

    var assignments_and_actual_problems_promise = Pmp.Manager.Reservations.Day.AssignmentsAndActualProblemsPromise()

    $.when(assignments_and_actual_problems_promise).done(function (assignments_and_actual_problems) {
      self._updateAssignments(assignments_and_actual_problems.assignments)
      self._updateActualProblems(assignments_and_actual_problems.problems)
    })

    var completion_callback = function (actuals, success) {
      if (!success) {
        self._renderReservationListNotReady()
      }

      self._actuals = actuals
      var i, actual_at_idx
      for (i = 0; i < actuals.length; i++) {
        actual_at_idx = actuals[i]
        if (actual_at_idx && actual_at_idx.status && !_.has(self._res_status, actual_at_idx.status)) {
          self._res_status[actual_at_idx.status] = actual_at_idx.status_formatted
        }
      }
      if (preRenderHandler) {
        preRenderHandler(self._actuals)
      }
      self._postReceiveActuals()
      $.when(assignments_and_actual_problems_promise).done(function () {
        self._insertAssignmentsAndProblems()
      })

      if (successHandler) {
        successHandler()
      }
    }

    var actuals_callback = function (actuals, success) {
      completion_callback(actuals, success)
    }
    var didStart = LazyActualsService.lazyLoadActuals(shift_persistent_id, actuals_callback, true, shift_reporting_period_persistent_id)
    if (didStart) {
      $('#loading-row').show()
      $('#shift-select').prop('disabled', true)
      this._clearUncanceledReservations()
    }
  },

  updateAssignmentsAndProblems: function (assignments_and_actual_problems_promise) {
    $.when(assignments_and_actual_problems_promise).done(function (assignments_and_actual_problems) {
      Pmp.Manager.Reservations.Day._updateAssignments(assignments_and_actual_problems.assignments)
      Pmp.Manager.Reservations.Day._updateActualProblems(assignments_and_actual_problems.problems)
      Pmp.Manager.Reservations.Day._insertAssignmentsAndProblems()
    })
  },
  _postReceiveActuals: function () {
    this._adjustTableHeaders()
    if (this._group_by_type !== 'DEFAULT') {
      this._recieveGroupableReservations()
    } else {
      this._recieveUngroupedReservations()
    }
    $('#shift-select').prop('disabled', false)
    $('#loading-row').hide()
  },

  _receiveCanceledReservations: function () {
    var canceledActuals = []
    for (var i = 0; i < this._actuals.length; i++) {
      var actual = this._actuals[i]
      if (actual.is_canceled || actual.status === 'NO_SHOW') {
        canceledActuals.push(actual)
      }
    }
    $('#canceled-block').hide()

    var html = this._generateActualRowHtml(canceledActuals, true)
    $('#canceled-rows').html(html)
    if (canceledActuals.length) {
      $('#canceled-block').show()
    }
  },

  _recieveGroupableReservations: function () {
    this._groupActualsByTypeAndOrTime(this._group_by_type)
  },

  _getNumGuestsDisplay: function (actuals) {
    var count = 0
    var s = ''
    for (var i = 0; i < actuals.length; i++) {
      var totalGuests = actuals[i].total_guests
      if (totalGuests === '') {
        totalGuests = 0
      }
      count += parseInt(totalGuests)
    }
    if (count != 1) {
      s = 's'
    }
    if (this._is_nightlife_class) {
      count += ' guest'
    } else {
      count += ' cover'
    }
    return count + s
  },

  _adjustTableHeaders: function () {
    var $no_res_text = $('#no-res-text')
    var $actuals_header_wrapper = $('#actual-header-wrapper')
    if (this._actuals && this._actuals.length > 0) {
      $no_res_text.hide()
      $actuals_header_wrapper.show()
    } else {
      $actuals_header_wrapper.hide()
      $no_res_text.show()
    }
  },
  _rerenderActuals: function (keep_selected, scroll_to_actual) {
    var selected_actual_id = $('.booked-row.selected').first().attr('actualid')

    this._clearUncanceledReservations()

    this._adjustTableHeaders()

    if (this._group_by_type === 'DEFAULT') {
      this._recieveUngroupedReservations()
    } else {
      this._groupActualsByTypeAndOrTime(this._group_by_type)
    }

    if (selected_actual_id && keep_selected) {
      var actual_row = $('#actual-row-' + selected_actual_id)
      if (!actual_row.length) {
        return
      }
      actual_row.addClass('selected')
      if (scroll_to_actual) {
        $('body').scrollTop(actual_row.offset().top)
      }
    }
  },

  _renderStats: function (groupByType) {
    ReservationStats.init(this._actuals, this._seating_area_id_to_code, !this._is_nightlife_class)
    ReservationStats.refresh()

    // Always process global stats
    var totalMin = Pmp.Utils.Currency.AddSymbolToVal(ReservationStats.min, this._currency_symbol, true)
    $('#quick-reservations').sext(ReservationStats.netReservations)
    $('#quick-spend').sext(totalMin)
    $('#quick-covers').sext(ReservationStats.netCovers)

    // update stats
    if (!this._is_nightlife_class && this._summary) {
      // format:
      // Covers expected: 3 | Reservations expected: 4 (4 VIP)
      var stats_text =
        'Covers expected: ' + ReservationStats.netCovers + ' | ' + 'Reservations expected: ' + ReservationStats.netReservations
      $('.totals').sext(stats_text)
    }

    var stats = null
    var spid = $('#shift-select').val()
    if (!spid || !spid.length) {
      stats = ReservationStats.statsForAllShifts()[groupByType]
    } else {
      stats = ReservationStats.stats[groupByType][spid]
    }

    // occurs if there are no actuals.
    if (!stats) {
      stats = {}
    }
    var $stats_bar = $('#stats-bar')
    var numRows = 0
    var covers = 0
    $stats_bar.find('.stats-row').remove()
    var html = ''

    var groupId, i, formatted_label

    // Get a list of groupIds to sort using the same order as the groupings.
    var listOfGroupIds = _.map(stats, function (grpStatObj, groupId) {
      return { label: groupId, id: groupId }
    })
    listOfGroupIds = this._reorderGroupDisplay(listOfGroupIds, groupByType)

    for (i = 0; i < listOfGroupIds.length; i++) {
      groupId = listOfGroupIds[i].label
      // skip empty stat rows
      if (!stats[groupId]['netReservations']) {
        continue
      }
      var min = Pmp.Utils.Currency.AddSymbolToVal(stats[groupId]['min'], this._currency_symbol, true)

      covers = groupByType === 'RES_STATUS' ? stats[groupId]['covers'] : stats[groupId]['netCovers']
      switch (groupByType) {
        case 'RES_TIME':
          formatted_label = timeutils.sort_order_to_time(groupId)
          break
        case 'SEATING_AREA':
          if (_.isUndefined(this._seating_area_id_to_name[listOfGroupIds[i].id])) {
            formatted_label = 'Unassigned'
          } else {
            formatted_label = this._seating_area_id_to_name[listOfGroupIds[i].id]
          }
          break
        default:
          formatted_label = groupId
      }

      html =
        html +
        Nightloop.Templates.Manager.Reservations.QuickStatsRow({
          label: formatted_label,
          is_dining_class: !this._is_nightlife_class,
          reservations: stats[groupId]['netReservations'],
          spend: min,
          guests: covers,
        })
      numRows += 1
    }
    //$(html).insertAfter('#stats-bar .total-stats-row');
    $stats_bar.find('.total-stats-row').after(html)
    var expandedHeight = numRows * 43
    if (groupByType != 'DEFAULT') {
      expandedHeight += 40
    } else {
      expandedHeight = 40
    }
    $stats_bar.height(expandedHeight)
    if ($stats_bar.hasClass('expanded')) {
      $stats_bar.css('bottom', 0)
    } else {
      $stats_bar.css('bottom', 40 - expandedHeight)
    }
  },

  _clearIfNoActuals: function (group_by_type) {
    if (!this._actuals || this._actuals.length === 0) {
      $('#actuals-list').empty()
      this._receiveCanceledReservations()
      this._renderStats(group_by_type)
      return true
    }
    return false
  },

  _groupActualsByTypeAndOrTime: function (group_by_type) {
    Interface.clearnew()

    if (this._clearIfNoActuals(group_by_type)) {
      return
    }

    // group all actuals by seating area
    var non_empty_groups_list = [],
      groups_map = {},
      group_id = '',
      price = 0,
      that = this,
      groups_list,
      html,
      i,
      j,
      actual,
      group,
      new_group,
      actuals

    // Init groups list with fixed, ordered groups
    if (group_by_type === 'RES_TIME') {
      groups_list = [{ id: '', label: '', actuals: [] }]
    } else if (group_by_type === 'SEATING_AREA') {
      groups_list = [{ id: 'BAR', label: 'Bar', actuals: [] }]
    } else if (group_by_type === 'TABLE_BAR') {
      groups_list = [
        { id: 'TABLE', label: 'Table', actuals: [] },
        { id: 'BAR', label: 'Bar', actuals: [] },
      ]
    } else if (group_by_type === 'PAYING_COMP') {
      groups_list = [
        { id: 'PAYING', label: 'Paying', actuals: [] },
        { id: 'COMP', label: 'Comp', actuals: [] },
      ]
    } else if (group_by_type === 'RES_TYPE') {
      groups_list = [{ id: 'RES_TYPE', label: 'Type', actuals: [] }]
    } else if (group_by_type === 'VIP_ALL' || group_by_type === 'VIP_NONVIP') {
      groups_list = [
        { id: 'ALL', label: 'Other', actuals: [] },
        { id: 'VIP', label: 'VIP', actuals: [] },
      ]
    } else if (group_by_type === 'RES_STATUS') {
      groups_list = _.map(this._res_status, function (label, group_id) {
        return { id: group_id, label: label, actuals: [] }
      })
    }

    for (j = 0; j < groups_list.length; j++) {
      group = groups_list[j]
      groups_map[group['id']] = group
    }

    for (i = 0; i < this._actuals.length; i++) {
      actual = this._actuals[i]
      // don't process canceled actuals here
      if (actual.is_canceled || actual.status === 'NO_SHOW') {
        continue
      }

      price = 0
      if (actual.min_price_type === 'dollar') {
        price = actual.min_price
      }

      if (group_by_type === 'RES_TIME') {
        group_id = ''
      } else if (group_by_type === 'RES_STATUS') {
        group_id = actual.status

        if (!_.has(this._res_status, group_id)) {
          var group_object = { id: group_id, label: actual.status_formatted, actuals: [] }
          this._res_status[group_id] = actual.status_formatted
          groups_list.push(group_object)
          groups_map[group_id] = group_object
        }
      } else if (group_by_type === 'SEATING_AREA') {
        if (actual.is_bar_reservation) {
          group_id = 'BAR'
        } else {
          group_id = actual.venue_seating_area_id
          var group_label
          if (group_id && this._seating_area_id_to_name[group_id] !== undefined) {
            group_label = this._seating_area_id_to_name[group_id]
          } else {
            group_id = ''
            group_label = 'Unassigned'
          }
          if (groups_map[group_id] === undefined) {
            new_group = { id: group_id, label: group_label, actuals: [] }
            groups_map[group_id] = new_group
            groups_list.push(new_group)
          }
        }
      } else if (group_by_type === 'TABLE_BAR') {
        group_id = actual.is_bar_reservation ? 'BAR' : 'TABLE'
      } else if (group_by_type === 'PAYING_COMP') {
        group_id = actual.is_comp ? 'COMP' : 'PAYING'
      } else if (group_by_type === 'RES_TYPE') {
        group_label = 'No Tag' // No type
        if (_.size(actual.reservation_tags)) {
          var first_tag = actual.reservation_tags[0].tags[0]
          group_label = first_tag.tag_name_display || first_tag.tag_name
        }

        group_id = group_label.replace(/[^\w\d-]/g, '-')
        if (groups_map[group_id] === undefined) {
          new_group = { id: group_id, label: group_label, actuals: [] }
          groups_map[group_id] = new_group
          groups_list.push(new_group)
        }
      } else if (group_by_type === 'VIP_ALL') {
        if (actual.is_client_vip) {
          groups_map['VIP'].actuals.push(actual)
        }
        group_id = 'ALL'
      } else if (group_by_type === 'VIP_NONVIP') {
        if (actual.is_client_vip) {
          group_id = 'VIP'
        } else {
          group_id = 'ALL'
        }
      }

      groups_map[group_id].actuals.push(actual)
    }

    // Filter empty out
    for (j = 0; j < groups_list.length; j++) {
      group = groups_list[j]
      if (group.actuals.length > 0) {
        non_empty_groups_list.push(group)
      }
    }

    that._renderStats(group_by_type)

    if (group_by_type === 'RES_TIME') {
      if (non_empty_groups_list && non_empty_groups_list.length > 0) {
        actuals = non_empty_groups_list[0].actuals
        this._loadGroupedReservationsByEstArrTime(actuals)
      }
    } else {
      non_empty_groups_list = this._reorderGroupDisplay(non_empty_groups_list, group_by_type)
      this._loadGroupedReservationsByGroupSelector(non_empty_groups_list, false, true)
    }

    this._receiveCanceledReservations()
  },

  _reorderGroupDisplay: function _reorderGroupDisplay(non_empty_groups_list, group_by_type) {
    var i = 0,
      actual_grouping,
      actual_grouping_comparator,
      seating_area_comparator,
      result = non_empty_groups_list,
      swap_temp,
      self = this

    actual_grouping_comparator = function (first_actual_grouping_list, second_actual_grouping_list) {
      if (first_actual_grouping_list.label < second_actual_grouping_list.label) {
        return -1
      }
      if (first_actual_grouping_list.label === second_actual_grouping_list.label) {
        return 0
      }

      return 1
    }

    seating_area_comparator = function (first_actual_grouping_list, second_actual_grouping_list) {
      var first_seating_area_sort_order = self._seating_area_id_to_sort_order[first_actual_grouping_list.id],
        second_seating_area_sort_order = self._seating_area_id_to_sort_order[second_actual_grouping_list.id]

      return first_seating_area_sort_order - second_seating_area_sort_order
    }

    switch (group_by_type) {
      case 'VIP_NONVIP':
      case 'VIP_ALL':
        for (i = 0; i < non_empty_groups_list.length; i++) {
          actual_grouping = non_empty_groups_list[i]

          if (actual_grouping.label.toLowerCase() === 'vip') {
            swap_temp = non_empty_groups_list[0]
            non_empty_groups_list[0] = actual_grouping
            non_empty_groups_list[i] = swap_temp
            result = non_empty_groups_list
            break
          }
        }
        break
      case 'SEATING_AREA':
        var not_unassigned = [],
          unassigned_groups = [],
          combined,
          sorted_result
        for (i = 0; i < non_empty_groups_list.length; i++) {
          actual_grouping = non_empty_groups_list[i]
          if (_.isUndefined(actual_grouping.id) || _.isUndefined(self._seating_area_id_to_sort_order[actual_grouping.id])) {
            unassigned_groups.push(actual_grouping)
            continue
          }

          not_unassigned.push(actual_grouping)
        }
        sorted_result = not_unassigned.sort(seating_area_comparator)
        combined = sorted_result.concat(unassigned_groups)
        result = combined
        break
      default:
        result = non_empty_groups_list.sort(actual_grouping_comparator)
    }

    return result
  },

  _generateGroupHeaderTitle: function _generateGroupHeaderTitle(group_name, actuals, formatter) {
    var num_guests_display = this._getNumGuestsDisplay(actuals),
      header_title

    if (formatter) {
      header_title = formatter(group_name, actuals)
    } else {
      header_title = group_name
    }
    if (this._is_nightlife_class) {
      header_title += ' (' + actuals.length + ') - ' + num_guests_display
    } else {
      header_title += ' (' + actuals.length + ' reservation'
      if (actuals.length !== 1) {
        header_title += 's'
      }
      header_title += ')'
      header_title += ' - ' + num_guests_display
    }

    return header_title
  },
  _generateActualRowHtml: function (actuals, show_time) {
    var inner_actual_html = '',
      inner_actual,
      j,
      assignment
    var sort_order = this.prev_sort_order,
      prev_sort_key = this.prev_down_sort_key
    var is_desc = sort_order === 'desc',
      final_bill = 0,
      extracted_numbers,
      parsed_amount
    var parse_final_bill_regex = /\d+/g

    prev_sort_key = prev_sort_key === 'name' ? 'client_display_name' : prev_sort_key
    actuals.sort(function (a, b) {
      // Bogus values.
      if (_.isUndefined(a[prev_sort_key]) || _.isUndefined(b[prev_sort_key])) {
        return 0
      }

      if (a[prev_sort_key] < b[prev_sort_key]) {
        return is_desc ? 1 : -1
      }

      if (a[prev_sort_key] === b[prev_sort_key]) {
        return 0
      }
      return is_desc ? -1 : 1
    })

    for (j = 0; j < actuals.length; j++) {
      inner_actual = actuals[j]
      assignment = this._buildAssignmentDisplay(inner_actual)

      if (this._summary) {
        inner_actual_html += Nightloop.Templates.Manager.Home.Doc.StaffSummaryRow({
          MEDIA_URL: this._media_url,
          actual: inner_actual,
          is_dining_class: !this._is_nightlife_class,
          group_header: false,
          show_time: !!show_time,
        })
      } else {
        if (!_.isUndefined(inner_actual.final_bill_formatted)) {
          extracted_numbers = inner_actual.final_bill_formatted.match(parse_final_bill_regex)
          if (extracted_numbers === null) {
            final_bill = 0
          } else {
            extracted_numbers = extracted_numbers.join('')
            parsed_amount = parseInt(extracted_numbers, 10)

            if (_.isNaN(parsed_amount)) {
              final_bill = 0
            } else {
              final_bill = parsed_amount
            }
          }
        } else {
          final_bill = 0
        }
        inner_actual_html += Nightloop.Templates.Manager.Reservations.ActualRow({
          MEDIA_URL: this._media_url,
          actual: inner_actual,
          isEven: j % 2 === 0,
          isCanceled: this,
          use_full_dining_backend: this._use_full_dining_backend,
          is_nightlife_class: this._is_nightlife_class,
          using_total_dollars_cost_option: Pmp.Manager.Reservations.Day._using_total_dollars_cost_option,
          show_cost_options: this._show_cost_options,
          payout_profile: this._payout_profile,
          show_time: !!show_time,
          is_auto_assign: assignment.is_auto_assign,
          assignment_display: assignment.assignment_display,
          problem: this._actual_problems ? this._actual_problems[inner_actual.id] : undefined,
          final_bill: final_bill,
          show_time_column: this._res_display_grouping,
        })
      }
    }
    return inner_actual_html
  },
  _loadGroupedReservationsByGroupSelector: function (non_empty_groups_list, header_title_override, show_time) {
    var $show_new_actual_row = $('.show-new-actual-row'),
      result_html = '',
      show_new_actual_id,
      openrow,
      group_header_html,
      inner_actual_html,
      actuals_obj,
      l,
      i

    for (i = 0; i < non_empty_groups_list.length; i++) {
      actuals_obj = non_empty_groups_list[i]
      group_header_html = Nightloop.Templates.Manager.Reservations.ActualGroupHeader({
        header_title: this._generateGroupHeaderTitle(actuals_obj.label, actuals_obj.actuals, header_title_override),
        appending_id: actuals_obj.id,
        payout_profile: this._payout_profile,
      })
      inner_actual_html = this._generateActualRowHtml(actuals_obj.actuals, show_time)
      result_html += group_header_html + '<div>' + inner_actual_html + '</div>'
    }

    $('#actuals-list').html(result_html)
  },

  _buildAssignmentDisplay: function (actual) {
    var is_assignment_available = this._assignments && this._assignments[actual.id],
      is_auto_assignment_enabled = Pmp.Manager.Global._auto_assign_enabled,
      is_reassigned =
        is_assignment_available &&
        this._assignments[actual.id].hasOwnProperty('is_reassigned') &&
        this._assignments[actual.id].is_reassigned,
      tables,
      is_auto_assign

    is_auto_assign = is_auto_assignment_enabled && (actual.is_auto_assign || is_reassigned)

    if (is_assignment_available && (is_auto_assign || !this._assignments[actual.id].hasOwnProperty('is_reassigned'))) {
      tables = this._assignments[actual.id].table_codes.join(', ')
    } else {
      tables = ''
    }

    return {
      assignment_display: tables,
      is_auto_assign: is_auto_assign,
    }
  },

  // If actual.auto_assign & has_assignment -> Yes
  // If !actual.auto_assign & has_assignment &

  _insertAssignmentsAndProblems: function () {
    var i,
      actual,
      assignment,
      actual_id,
      html,
      $selector,
      problem,
      problem_class,
      base_string = '#actual-row-'
    for (i = 0; i < this._actuals.length; i++) {
      actual = this._actuals[i]
      assignment = this._buildAssignmentDisplay(actual)
      assignment.MEDIA_URL = this._media_url
      actual_id = base_string + actual.id
      html = Nightloop.Templates.Manager.Reservations.TableNumberRowEntryFragment(assignment)
      $selector = $(actual_id)
      $selector.removeClass('res-actual-problem-major res-actual-problem-minor')
      $selector.children('.col.col-table-no').html(html)

      if (!this._actual_problems || !this._actual_problems.hasOwnProperty(actual.id)) {
        continue
      }
      problem = this._actual_problems[actual.id]
      problem_class = problem.is_major ? 'res-actual-problem-major' : 'res-actual-problem-minor'
      $selector.addClass(problem_class)
    }
  },

  _time_slot_formatter: function _time_slot_formatter(sort_order, actuals) {
    if (actuals && actuals.length > 0) {
      return actuals[0].arrival_time_display ? actuals[0].arrival_time_display : 'No Arrival Time'
    }
    return ''
  },
  _loadGroupedReservationsByEstArrTime: function (actuals_master) {
    var timeslot_groups = {},
      timeslots_ordered = [],
      result_list = [],
      i,
      sort_order,
      actual
    for (i = 0; i < actuals_master.length; i++) {
      actual = actuals_master[i]
      sort_order = actual.arrival_time_sort_order
      if (!timeslot_groups[sort_order]) {
        timeslot_groups[sort_order] = [actual]
        Pmp.Utils.Array.InsertSorted(sort_order, timeslots_ordered)
      } else {
        // Leverage the sorted-by-name pages, so just push onto the end
        timeslot_groups[sort_order].push(actual)
      }
    }

    for (i = 0; i < timeslots_ordered.length; i++) {
      result_list.push({ actuals: timeslot_groups[timeslots_ordered[i]] })
    }

    this._loadGroupedReservationsByGroupSelector(result_list, this._time_slot_formatter, true)
  },

  _recieveUngroupedReservations: function () {
    var row_html,
      uncancelled_actuals = [],
      i,
      actual
    if (this._clearIfNoActuals('DEFAULT')) {
      return
    }

    for (i = 0; i < this._actuals.length; i++) {
      actual = this._actuals[i]
      if (!actual.is_canceled && actual.status !== 'NO_SHOW') {
        uncancelled_actuals.push(actual)
      }
    }
    row_html = this._generateActualRowHtml(uncancelled_actuals, true)
    $('#actuals-list').html(row_html)

    // Show spinners for page gaps above

    this._moveShowNewActualToSortPosition()
    this._receiveCanceledReservations()
    this._renderStats('DEFAULT')
  },

  _moveShowNewActualToSortPosition: function () {
    var $show = $('.show-new-actual-row')
    if ($show.length === 0) {
      return
    }
    var $show_new_actual_row_sort_name = $show.attr('client_sort_name')
    var $actual_rows = $('.actual-row')
    var last_smaller_row
    for (var i = 0; i < $actual_rows.length; i++) {
      var actual_row = $($actual_rows[i])
      if (actual_row.attr('client_sort_name') < $show_new_actual_row_sort_name) {
        last_smaller_row = actual_row
      }
    }
    // Check one more time that it's not removed yet for race conditions
    if ($show.length === 0) {
      return
    }
    if (last_smaller_row) {
      $show.insertAfter(last_smaller_row)
    }
  },

  AssignmentsAndActualProblemsPromise: function () {
    return JQueryAssignmentsAndProblemsService.getAssignmentsAndProblems(
      Pmp.Manager.Reservations.Day._venue_url_key,
      Pmp.Manager.Reservations.Day._dateUrlParam,
      $('#shift-select').val(),
      Pmp.Manager.Global._actual_problems_enabled,
      Pmp.Manager.Global._auto_assign_enabled
    )
  },

  _onClickEditDailyNotesLink: function (event) {
    var onCloseOverlayFn = function () {
      self._onCloseOverlay()
    }
    var url = this._managerBaseUrl + '/home/dailynotes/' + this._dateUrlParam + '/edit'
    Pmp.Main.Popup.Base.loadUrl(url, true)
    Pmp.Main.Popup.Base.showPopup(400, undefined /*onCompleteFnHook*/, onCloseOverlayFn)
  },

  _saveVenueSetting: function (group_by_type) {
    var that = this
    var relativeUrl = this._managerBaseUrl + '/home/save/venue-user'
    var data = {
      'group-by-type': group_by_type,
    }
    Pmp.Client.LoadAjaxData(relativeUrl, data, true, function () {
      that._group_by_type = group_by_type
    })
  },

  _onClickRegroupDefault: function (event, obj) {
    metric.track('Reservations.groupByDefault', { page: 'reservations' })

    if (!$('.booked-row').length) return

    this._recieveUngroupedReservations()
    $('#stats-bar').find('.stats-row').remove()
    $('ul.regroupmenu a').removeClass('selected')
    $(obj).addClass('selected')
    this._saveVenueSetting('DEFAULT')
  },
  _resetStatsBar: function _resetStatsBar() {
    var stats_bar = $('#stats-bar')
    stats_bar.removeClass('expanded')
    stats_bar.height(40)
    stats_bar.animate({ bottom: 0 }, 100)
  },
  _onClickRegroupTime: function (event, obj) {
    metric.track('Reservations.groupByTime', { page: 'reservations' })

    if (!$('.booked-row').length) return

    this._groupActualsByTypeAndOrTime('RES_TIME')
    $('ul.regroupmenu a').removeClass('selected')
    $(obj).addClass('selected')
    this._saveVenueSetting('RES_TIME')
  },
  _onClickRegroupSeatingArea: function (event, obj) {
    metric.track('Reservations.groupBySeatingArea', { page: 'reservations' })

    if (!$('.booked-row').length) return

    this._groupActualsByTypeAndOrTime('SEATING_AREA')
    $('ul.regroupmenu a').removeClass('selected')
    $(obj).addClass('selected')
    this._saveVenueSetting('SEATING_AREA')
  },
  _onClickRegroupResStatus: function (event, obj) {
    metric.track('Reservations.groupByStatus', { page: 'reservations' })
    if (!$('.booked-row').length) return

    this._groupActualsByTypeAndOrTime('RES_STATUS')
    $('ul.regroupmenu a').removeClass('selected')
    $(obj).addClass('selected')
    this._saveVenueSetting('RES_STATUS')
  },
  _onClickRegroupTableBar: function (event, obj) {
    metric.track('Reservations.groupByTableBar', { page: 'reservations' })

    if (!$('.booked-row').length) return

    this._groupActualsByTypeAndOrTime('TABLE_BAR')
    $('ul.regroupmenu a').removeClass('selected')
    $(obj).addClass('selected')
    this._saveVenueSetting('TABLE_BAR')
  },
  _onClickRegroupPayingComp: function (event, obj) {
    metric.track('Reservations.groupByPayingComp', { page: 'reservations' })

    if (!$('.booked-row').length) return

    this._groupActualsByTypeAndOrTime('PAYING_COMP')
    $('ul.regroupmenu a').removeClass('selected')
    $(obj).addClass('selected')
    this._saveVenueSetting('PAYING_COMP')
  },
  _onClickRegroupResType: function (event, obj) {
    metric.track('Reservations.groupByReservationType', { page: 'reservations' })
    this._groupActualsByTypeAndOrTime('RES_TYPE')
    $('ul.regroupmenu a').removeClass('selected')
    $(obj).addClass('selected')
    this._saveVenueSetting('RES_TYPE')
  },
  _onClickRegroupVip: function (event, obj) {
    metric.track('Reservations.groupByVip', { page: 'reservations' })
    this._groupActualsByTypeAndOrTime('VIP_NONVIP')
    $('ul.regroupmenu a').removeClass('selected')
    $(obj).addClass('selected')
    this._saveVenueSetting('VIP_NONVIP')
  },

  _onClickBookedResRow: function (event) {
    // avoid clicks to received
    if ($(event.target).hasClass('received-button')) {
      return
    }

    var booked_row = Pmp.Utils.GetParentElemForEventHandle(event, '.booked-row')
    var actual_id = $(booked_row).attr('actualid')

    if (!(window && window.globalInit && window.globalInit.venueSettings)) {
      return false
    }
    if (window.globalInit.venueSettings.use_supafly) {
      SvrManager.ActualSlideout.viewActual(actual_id, null, function () {
        $('.list-block .standard-row').removeClass('selected')
        $(booked_row).addClass('selected')
      })
    } else {
      ReservationSlideOut.showReservation(actual_id)
    }
    return false
  },

  _onClickShowCanceled: function (event) {
    $('.show-canceled-link').hide()
    $('.hide-canceled-link').show()
    $('#canceled-rows').show()
  },

  _onClickHideCanceled: function (event) {
    $('.show-canceled-link').show()
    $('.hide-canceled-link').hide()
    $('#canceled-rows').hide()
  },

  _onMouseEnterClientRequest: function (event) {
    var client_request_hover = $(event.target)
    var client_request = client_request_hover.siblings('.client-request')
    client_request.show()
  },

  _onMouseLeaveClientRequest: function (event) {
    var client_request_hover = $(event.target)
    var client_request = client_request_hover.siblings('.client-request')
    client_request.hide()
  },

  _onMouseEnterClientInfoReserved: function (event) {
    var client_request_hover = $(event.target)
    var client_request = client_request_hover.siblings('.client-info-reserved')
    client_request.show()
  },

  _onMouseLeaveClientInfoReserved: function (event) {
    var client_request_hover = $(event.target)
    var client_request = client_request_hover.siblings('.client-info-reserved')
    client_request.hide()
  },

  // Sort defaults
  prev_sort_order: 'asc',
  prev_down_sort_key: 'arrival_time_sort_order',

  _onClickSortLink: function (event, element) {
    var container_id = '#booked-box'
    var row_class = '.booked-row'

    if ($(row_class, container_id).length === 0) return

    var sort_key = $(element).attr('sort_key')
    var sort_attr = 'sort_' + sort_key
    var sort_order = $(container_id).data('prev_sort_order') === 'desc' ? 'asc' : 'desc'

    if (this.prev_down_sort_key !== sort_key && container_id === this.prev_container) {
      sort_order = 'asc'
    }

    $(container_id).data('prev_sort_order', sort_order)

    this.prev_container = container_id
    this.prev_down_sort_key = sort_key
    this.prev_sort_order = sort_order

    $(container_id).find('.sort-link').removeClass('asc desc')
    $(container_id)
      .find('.sort-link[sort_key=' + sort_key + ']')
      .addClass(sort_order)

    var iAsc = sort_order === 'asc' ? 1 : -1
    var sort_fn = function (a, b) {
      var sA = $.trim(a.s),
        sB = $.trim(b.s)
      // Always bubble empty rows to the bottom
      if (sA === '') {
        return 1
      } else if (sB === '') {
        return -1
      }
      // Sort numbers
      fA = parseFloat(sA, 10)
      fB = parseFloat(sB, 10)
      if (!isNaN(fA) && !isNaN(fB)) {
        return iAsc * (fA < fB ? -1 : fA > fB ? 1 : 0)
      }
      return iAsc * (sA < sB ? -1 : sA > sB ? 1 : 0)
    }

    this.debug('_onClickSortLink: ' + sort_key + ', ' + sort_order)
    $(row_class).tsort({ attr: sort_attr, order: sort_order, sortFunction: sort_fn })

    Interface.clearnew()
  },

  _onClickReceivedLink: function (event, obj) {
    var self = this

    var actual_id = $(obj).parents('.booked-row').attr('actualid')
    var received_col = $(obj).parents('.booked-row').find('.col-received')
    var spinner = received_col.find('.received-spinner')
    var relativeUrl = this._managerBaseUrl + '/actual/' + actual_id + '/save-edits'
    var data = {
      is_received: 'y',
    }

    $(obj).hide()
    spinner.removeClass('no-display')
    Pmp.Client.LoadAjaxData(relativeUrl, data, true, function (data) {
      spinner.addClass('no-display')
      if (data.payload.success) {
        var html = Nightloop.Templates.Manager.Reservations.ReceivedLink({
          actual: data.payload.actual,
          MEDIA_URL: self._media_url,
        })
        received_col.html(html)
      } else {
        $(obj).show()
      }
    })
    return false // stop further event propagation
  },

  _initCalendar: function () {
    var that = this
    var sel = '#secondary-calendar'
    var locale = Pmp.Manager.Global._locale
    var dateFmt = Pmp.Utils.dateFormat(locale)
    var dt = Pmp.Utils.parseDateUrlParam(that._dateUrlParam)
    var formattedDate = $.datepicker.formatDate(dateFmt, dt)
    Pmp.Utils.LocalizeDatePicker(locale, sel, sel + '-submit')
    $(sel).datepicker('option', 'defaultDate', formattedDate)
    $(sel).datepicker('option', 'showOtherMonths', true)
    $(sel).datepicker('option', 'selectOtherMonths', true)
    $(sel).datepicker('option', 'onSelect', function (dateText, calendar) {
      Pmp.Manager.Global._onSelectDate(dateText, calendar)
      Pmp.Utils.formatDatePicker(sel)
      metric.track('Reservations.DropDownCalendar.changeDate', { page: 'reservations' })
    })
    $(sel).datepicker('option', 'beforeShow', function () {
      $('#ui-datepicker-div').addClass('calendar customize')
    })
    $(sel).datepicker('option', 'beforeShowDay', function (date) {
      return Pmp.Manager.Global._beforeShowDay(date)
    })
    $('#secondary-calendar').datepicker('setDate', formattedDate)
    Pmp.Utils.formatDatePicker(sel)
  },
}
