// This is an initial attempt to simplify js for manager portal
var Pmp = Pmp || {}
Pmp.Manager = Pmp.Manager || {}
var TransactionTypes = {
  REQUEST: 'transaction_type_request_info',
  REQUEST_REMOVED: 'transaction_type_request_removed',
  REQUEST_FULFILLED: 'transaction_type_request_fulfilled',
  REFUND: 'transaction_type_refund',
  AUTO_REFUND: 'transaction_type_auto_refund',
  SAVED: 'transaction_type_save',
  REMOVED: 'transaction_type_remove',
  CANCELED: 'transaction_type_canceled',
  THREE_D_SECURE_SUCCEED: 'transaction_type_3d_secure_succeed',
  THREE_D_SECURE_FAILED: 'transaction_type_3d_secure_failed',

  REQUEST_REFUND: 'transaction_type_request_refund',
  THREE_D_SECURE_STARTED: 'transaction_type_3d_secure_started',
  PAYMENT_STARTED: 'transaction_payment_started',
}
var GroupManager = {
  member_groups: [],

  init: function (selected) {
    // Default ids
    this.$area = $('#groups-area')
    this.$drop = $('#group-dropdown')

    this.load(selected)
    this.bind()
  },

  load: function (selected) {
    var that = this

    if (!this.member_groups.length) {
      MemberGroupService.getByVenue(Pmp.Manager.Global._managerBaseUrl, false, function (data) {
        that.member_groups = data
      })
    }
  },

  // Possible race condition here, should be outlier
  showMembers: function (selected, target) {
    for (iter = 0; iter < this.member_groups.length; iter++) {
      var group = this.member_groups[iter]
      if ($.inArray(group.id, selected) == -1) continue
      var add = Nightloop.Templates.Widget.MembershipGroup({
        group_id: group.id,
        group_name: group.name,
        group_color: group.color,
      })
      $(target).prepend($(add))
    }
  },

  bind: function () {
    var that = this
    this.$area.on('click', function (e) {
      e.stopPropagation()
      if ($(e.target).hasClass('closer')) {
        return
      }

      that.$drop.show()
    })

    $('body').on('click', function () {
      that.$drop.hide()
    })

    this.$drop.on('click', '.member-group', function (e) {
      var group = $(e.target)
      if (!group.hasClass('client-group')) {
        group = group.closest('.member-group')
      }
      var id = group.attr('group_id')
      that.addGroup(id)
    })
  },

  addGroup: function (id) {
    var group = $('.group-selector').find('[group_id=' + id + ']')
    var name = group.attr('group_name')
    var color = group.attr('group_color')

    var add = Nightloop.Templates.Widget.MembershipGroup({
      group_id: id,
      group_name: name,
      group_color: color,
      closer: true,
      input: true,
    })

    add = $(add)
    add.find('.closer').on('click', function () {
      add.remove()
      group.show()
    })
    group.hide()

    this.$drop.hide()
    this.$area.append(add)
  },

  clear: function () {
    this.$area.html('')
    this.$drop.find('.group-selector').html('')
  },
}

var CostOptions = {
  init: function (scope) {
    this.scope = scope

    $(scope)
      .find('.cost-type input')
      .on('click', function () {
        var name = $(this).attr('name')
        var min_price_el = $(scope).find('input[name=min_price_override]')
        if (name == 'comp_table') {
          min_price_el.val('')
          min_price_el.addClass('disabled')
          min_price_el.attr('placeholder', 'COMP')
        } else if (name == 'nomin_table') {
          min_price_el.val('')
          min_price_el.addClass('disabled')
          min_price_el.attr('placeholder', 'NO MIN')
        } else {
          min_price_el.removeClass('disabled')
          min_price_el.removeAttr('placeholder')
        }
      })
  },

  update: function (scope, entity) {
    if (entity == undefined) {
      var total_dollars = $(scope).find('.cost-type input[value="total_dollars"]')
      if (total_dollars.length) {
        total_dollars.trigger('click')
        return
      }

      var min_dollars = $(scope).find('.cost-type input[value="dollar"]')
      if (min_dollars.length) {
        min_dollars.trigger('click')
        return
      }
      return
    }
    if (entity.is_nomin) {
      $(scope).find('input[name="nomin_table"]').trigger('click')
    } else if (entity.is_comp) {
      $(scope).find('input[name="comp_table"]').trigger('click')
    } else if (entity.cost_option == 4) {
      // TOTAL_DOLLARS
      $(scope).find('input[value="total_dollars"]').trigger('click')
    } else if (entity.min_price_type) {
      $(scope)
        .find('.cost-type input[value=' + entity.min_price_type + ']')
        .trigger('click')
    }
  },

  clear: function () {
    var cost_options = $('div.cost-type.radioset').find('input')
    if (cost_options.length) {
      $(cost_options[0]).click()
    }
  },
}

var Attachments = {
  init: function (baseurl) {
    this.baseurl = baseurl
    this.$tab = $('#tab-attachments')
    this._last_upload = null
    $('#nav-attachments').on('click', $.proxy(this.fetch, this))
  },

  initWithContext: function (mediaurl, concierge_or_client_name) {
    this.attachmentid = $('#chat-attachments_id').val()
    this.attachmentbaseurl = this.baseurl + '/attachments/'
    this.attachmenturl = this.attachmentbaseurl + this.attachmentid
    this.removeattachmenturl = '/attachment/' + this.attachmentid + '/remove'
    this.mediaurl = mediaurl
    this.concierge_or_client_name = concierge_or_client_name
  },

  fetch: function () {
    var that = this

    if (!(this.attachmentid.length > 0)) {
      return
    }

    // get attachments and populate
    $.ajax({
      global: false,
      url: that.attachmenturl,
      success: function (response) {
        that.$tab.empty()
        var attachments = response.attachments
        if (attachments) {
          for (var i = 0; i < attachments.length; i++) {
            var a = attachments[i]
            that.add(a, that.concierge_or_client_name, that.mediaurl)
          }
        }
        if (!attachments.length) {
          var $warn = $('<p class="empty"/>')
          $warn.sext('Attachments added to client messages or internal notes will accumulate here.')
          that.$tab.append($warn)
        }
      },
    })
  },

  uploadHandler: function (e, data) {
    // Note: this is now handled inside chatter
    //attachments = [data];
    //Chatter.sendchat('','added attachment ' + data.filename, 'ACTIVITY','',undefined,attachments);
    Attachments.fetch()
  },

  getLastUploadData: function () {
    var tmp = this._last_upload
    this.clearUploadCache()
    return tmp
  },

  clearUploadCache: function () {
    this._last_upload = null
  },

  hasUploadData: function () {
    if (!this._last_upload) {
      return false
    }
    return true
  },

  cacheUploadData: function (data) {
    if (!this._last_upload) {
      this._last_upload = []
    }
    this._last_upload = this._last_upload.concat(data)
  },

  cacheRemoveOne: function (blob_key) {
    var tmp = []
    for (i = 0; i < this._last_upload.length; i++) {
      if (this._last_upload[i].blob_key == blob_key) {
        continue
      }
      tmp.push(this._last_upload[i])
    }
    this._last_upload = tmp
  },

  add: function (a, concierge_or_client_name, mediaurl) {
    var classes = 'attachment'
    if (a.is_internal) {
      classes += ' internal'
    }

    var html = "<p class='attachment'>"
    html += "    <img src='" + mediaurl + "images/icons/file-solid.png' alt='' />"
    if (a.author_name != null) {
      html += '    <strong>' + a.author_name + '</strong>'
    } else {
      html += '    <strong>' + concierge_or_client_name + '</strong>'
    }
    html += '    <em>' + a.created + '</em>'
    html += "    <a href='/.h/download/" + a.blob_key + "' target='_blank' class='file-link'>" + a.filename + '</a>'
    html +=
      "    <a href='javascript:void(0);' onclick=\"javascript:Attachments.remove('" + a.blob_key + "',this);\" class='delete'>&times;</a>"
    html += '</p>'
    this.$tab.append(html)
  },

  remove: function (blob_key, el, suppress) {
    if (!blob_key) {
      return
    }

    var that = this
    $.ajax({
      global: true,
      url: that.removeattachmenturl,
      method: 'post',
      data: { blob_key: blob_key },
      success: function (response) {
        $(el).parents('.attachment').fadeOut(1000).remove()
        var filename = $(el).siblings('.file-link').html()
        if (that._last_upload) {
          that.cacheRemoveOne(blob_key)
        }
        if (suppress) {
          return
        }

        Chatter.sendchat('', 'removed attachment ' + filename, 'ACTIVITY', '', true)
      },
    })
  },
}

function Followers() {
  this.kind = null
}

Followers.prototype.init = function (base_url, scope, doPersistence) {
  this.base_url = base_url
  this.scope = scope
  this.entity_id = null
  this.kind = null
  this.doPersistence = doPersistence
  var that = this
  $('#followers-select', this.scope).change(function (e) {
    var follower_id = $(this).val()
    var follower_name = $(this).find(':selected').sext()
    that.add(follower_id, follower_name)
  })
  $('.follower-remove', this.scope).live('click', function () {
    var follower_id = $(this).attr('fid')
    that.remove(follower_id, $(this).parents('.pic'))
  })
}

Followers.prototype.refreshFollowersSelect = function (booked_by_users) {
  var select = $('#followers-select', this.scope)
  select.empty()
  var emptyoption = $('<option/>').attr('selected', 'selected')
  select.append(emptyoption)
  for (var i = 0; i < booked_by_users.length; i++) {
    if (booked_by_users[i].is_user) {
      var option = $('<option/>').sext(booked_by_users[i].name).val(booked_by_users[i].id)
      select.append(option)
    }
  }
}

Followers.prototype.getSaveUrl = function (follower_id) {
  var url
  if (this.kind == 'Actual') {
    url = '/api-yoa/followers/actual/' + this.entity_id + '/' + follower_id
  } else if (this.kind == 'Request') {
    url = '/api-yoa/followers/request/' + this.entity_id + '/' + follower_id
  } else {
    url = '/api-yoa/followers/client/' + this.entity_id + '/' + follower_id
  }
  return url
}

Followers.prototype.getRefreshFollowersUrl = function () {
  var url
  if (this.kind == 'Actual') {
    url = '/api-yoa/followers/actual/' + this.entity_id + '/refresh'
  } else if (this.kind == 'Request') {
    url = '/api-yoa/followers/request/' + this.entity_id + '/refresh'
  } else {
    url = '/api-yoa/followers/client/' + this.entity_id + '/refresh'
  }
  return url
}

Followers.prototype.add = function (follower_id, follower_name) {
  if (this.doPersistence) {
    this.addAndPersist(follower_id)
  } else {
    this.addOnly(follower_id, follower_name)
  }
  $('#followers-select', this.scope).val('option:first')
}

Followers.prototype.addOnly = function (follower_id, follower_name) {
  var initials = follower_name.match(/\b\w/g)
  initials = (initials.shift() + initials.pop()).toUpperCase()
  var follower = {
    id: follower_id,
    full_name: follower_name,
    initials: initials,
  }
  this.addOneFollower(follower, {})
  // TODO: figure out images
  // - add input element to render
}

Followers.prototype.addAndPersist = function (follower_id) {
  var that = this
  var url = this.getSaveUrl(follower_id)
  $.ajax({
    url: url,
    method: 'put',
    dataType: 'json',
    success: function (r) {
      that.refresh(r.data.followers)
    },
  })
}

Followers.prototype.remove = function (follower_id, el) {
  if (this.doPersistence) {
    this.removeAndPersist(follower_id)
  } else {
    this.removeOnly(el)
  }
  $('#followers-select', this.scope).val('option:first')
}

Followers.prototype.removeOnly = function (el) {
  $(el).remove()
}

Followers.prototype.removeAndPersist = function (follower_id) {
  var that = this
  var url = this.getSaveUrl(follower_id)
  $.ajax({
    url: url,
    method: 'delete',
    dataType: 'json',
    success: function (r) {
      that.refresh(r.data.followers)
    },
  })
}

Followers.prototype.refreshAndPersistFollowers = function () {
  var that = this
  var url = this.getRefreshFollowersUrl()
  $.ajax({
    url: url,
    method: 'put',
    dataType: 'json',
    success: function (r) {
      that.refresh(r.data.followers)
    },
  })
}

Followers.prototype.addOneFollower = function (follower, bbdct) {
  // check for existence
  var followerExists = false
  $('.followers-area input', this.scope).each(function () {
    if ($(this).val() == follower.id) {
      followerExists = true
      return false
    }
  })

  if (followerExists) {
    return
  }

  var photo_key = null
  if (follower.id in bbdct) {
    var usr = bbdct[follower.id]
    if (usr.photo) {
      if (usr.photo.small) {
        photo_key = usr.photo.small
      }
    }
  }
  var pic
  if (photo_key) {
    pic = $('<span/>').addClass('pic')
    var img = $('<img/>').attr('src', '/.h/download/' + photo_key),
      del = $('<span/>').addClass('del').addClass('follower-remove').attr('fid', follower.id).sext('x')
    pic.append(img)
    pic.append(del)
  } else {
    pic = $('<span/>').addClass('pic')
    var del = $('<span/>').addClass('del').addClass('follower-remove').attr('fid', follower.id).sext('x')
    pic.append(follower.initials)
    pic.append(del)
  }
  if (!this.doPersistence) {
    pic.append($('<input/>').attr('type', 'hidden').attr('name', 'fid').val(follower.id))
  }
  pic.append($('<em/>').addClass('name').sext(follower.full_name))
  $('.followers-area', this.scope).append(pic)
}

Followers.prototype.refresh = function (followers, booked_by_content) {
  var bbdct = {}
  // chicken and egg problem doh
  // loading booked_by_content is async, so sometimes isn't ready yet.
  // for now, just shows initials instead of pics KP
  if (booked_by_content) {
    var bbusers = booked_by_content.booked_by_users
    for (var i = 0; i < bbusers.length; i++) {
      if (bbusers[i].is_user) {
        bbdct[bbusers[i].id] = bbusers[i]
      }
    }
  }
  $('.followers-area', this.scope).html('')
  for (f in followers) {
    var follower = followers[f]
    this.addOneFollower(follower, bbdct)
  }
  $('#followers-select option:first', this.scope).attr('selected', 'selected')
}

Followers.prototype.clear = function () {
  $('.followers-area', this.scope).html('')
}

var Assigned = {
  /**
   * Set up event listener for all select tags with the #assigned-select id.
   * A callback must be passed into this function instead of any 'caller'
   * functions.
   */
  init: function (base_url, callback) {
    this.callback = callback
    this.base_url = base_url
    this.entity_id = null
    this.kind = null
    var that = this
    $('#assigned-select').change(function (e) {
      var assigned_to_id = $(this).val()
      that.replace(assigned_to_id, callback)
    })
  },

  isSet: function () {
    return this.getUserId() != ''
  },

  getUserId: function () {
    return $('#assigned-select').val()
  },

  isRequest: function () {
    return this.kind == 'Request'
  },

  refreshSelect: function (booked_by_users) {
    var select = $('#assigned-select')
    select.empty()
    var emptyoption = $('<option/>') //.attr('selected', 'selected');
    select.append(emptyoption)
    for (var i = 0; i < booked_by_users.length; i++) {
      if (booked_by_users[i].is_user) {
        var option = $('<option/>').sext(booked_by_users[i].name).val(booked_by_users[i].id)
        select.append(option)
      }
    }
  },

  replace: function (assigned_to_id) {
    var that = this
    var url = this.base_url + '/actual/' + this.entity_id + '/save-edits'

    if (this.kind == 'Request') {
      url = this.base_url + '/requests/' + this.entity_id + '/save-edits'
    }

    $.ajax({
      url: url,
      method: 'post',
      dataType: 'json',
      data: { assigned_to_id: assigned_to_id },
      success: function (data) {
        that.refresh(data.payload.assigned)
        if (that.callback) {
          that.callback(data.payload.assigned)
        }
      },
    })
  },

  refresh: function (assigned_to, booked_by_content) {
    var bbdct = {}
    // chicken and egg problem doh
    // loading booked_by_content is async, so sometimes isn't ready yet.
    // for now, just shows initials instead of pics KP
    if (booked_by_content) {
      var bbusers = booked_by_content.booked_by_users
      for (var i = 0; i < bbusers.length; i++) {
        if (bbusers[i].is_user) {
          bbdct[bbusers[i].id] = bbusers[i]
        }
      }
    }
    this.clear()

    // set to nothing
    if (assigned_to == null) {
      var plus = $('<span/>').sext('+')
      $('.assigned-area').append(plus)
      $('#assigned-select option[value=""]').attr('selected', 'selected')
      return
    }

    var photo_key = null
    if (assigned_to.id in bbdct) {
      var usr = bbdct[assigned_to.id]
      if (usr.photo) {
        if (usr.photo.small) {
          photo_key = usr.photo.small
        }
      }
    }
    if (photo_key) {
      var pic = $('<span/>').addClass('pic'),
        img = $('<img/>').attr('src', '/.h/download/' + photo_key)
      pic.append(img)
      $('.assigned-area').append(pic)
    } else {
      var pic = $('<span/>').addClass('pic')
      pic.append(assigned_to.initials)
      $('.assigned-area').append(pic)
    }

    $('#assigned-select option[value="' + assigned_to.id + '"]').attr('selected', 'selected')
  },

  clear: function () {
    $('.assigned-area').html('')
  },
}

var BillingHistory = {
  init: function (baseurl) {
    this.baseurl = baseurl
    this.$tab = $('#tab-payments')
    this.bind()
    $('#payments-history').show()
    $('#refund-form-container').hide()
  },

  bind: function () {
    $('#nav-payments').on('click', $.proxy(this.showCharges, this))

    $('#tab-payments').on('click', '.invalid-refund-link', function (event) {
      Interface.alertLeftButtomSnackBarError("It looks like this charge was initiated" +
        " from a different stripe account. " +
        "If you wish to refund, please do so" +
        " via your Stripe portal directly.")
    })
    $('#tab-payments').on('click', '.refund-link', function (event) {
      $('#issue-refund').removeClass('disabled')
      var charge_id = $(event.target).attr('transaction_id')
      $('#input-charge_id').val(charge_id)
      var amount_remaining = $(event.target).attr('amount_remaining')
      $('#input-refund-amount').val(amount_remaining)
      $('#input-refund-amount').attr('data-full-amount', amount_remaining)
      $('#input-refund-reason').val('')
      $('#id_FULL').trigger('click')
      $('.send_refund_email').removeClass('checked')
      $('.send_refund_email input').prop('checked', false)
      $('#notification-rid').val($(this).closest('.payment').prop('id'))

      // Get client email here
      $('.send_refund_email input').val('')
      $('.notification-address').hide()
      $('#payments-history').hide()
      $('#refund-form-container, #refund-form').show()
    })

    $('#tab-payments').on('click', '.send-note-link', function (event) {
      $('#notification-rid').val($(this).closest('div.payment').prop('id'))
      $('#payments-history').hide()
      $('#notification-form-container, #notification-form').show()
    })
  },

  showCharges: function () {
    var actual_id = $('#input-actual-id').val()
    var venue_payout_profile_account_id = $('#venue-payout-profile-account-id').val()
    var deferred = BillingService.listActualCharges(actual_id)
    $('#payments-history ').empty()
    $('#input-charge_id').val('')
    $('#input-refund-amount').val('')

    $('#refund-form, #charge-form, #notification-form').hide()

    deferred.done(function (resp) {
      var charges = resp.charges // TODO only fetches first 50, fine for now
      if (!charges) {
        $('#payments-history').show()
        $('#refund-form-container').hide()
        $('#charge-form-container').show()
        return
      }
      var successful_charges = []
      var refundable_charges = {}
      for (var i = 0; i < charges.length; ++i) {
        if (charges[i].successful) {
          const charge = charges[i]
          var charge_id = charge.id
          successful_charges.push(charge_id)
          let is_refundable_charge = true;
          if (charge.payout_profile_system === 'STRIPE' && charge.payout_profile_account_id && charge.payout_profile_account_id !== venue_payout_profile_account_id) {
            is_refundable_charge = false
          }
          refundable_charges[charge_id] = is_refundable_charge
        }
        var not_final_status = [TransactionTypes.THREE_D_SECURE_STARTED, TransactionTypes.REQUEST_REFUND, TransactionTypes.PAYMENT_STARTED]
        var protocol = 'http'
        if (Pmp.Settings.IS_PRODUCTION) {
          protocol = 'https'
        }
        var domain = protocol + '://' + Pmp.Manager.Global._domain_name
        var venue = _.findWhere(Pmp.Manager.Global.UserDomainVenues.venues, { id: charges[i].venue_id })
        var html = Nightloop.Templates.Manager.PaymentRow({
          name: venue ? venue.name : null,
          history_id: charges[i].id,
          ndb_id: charges[i].ndb_id,
          transaction_id: charges[i].transaction_id,
          is_save: charges[i].transaction_type == TransactionTypes.SAVED,
          is_delete: charges[i].transaction_type == TransactionTypes.REMOVED,
          is_info_request: charges[i].transaction_type == TransactionTypes.REQUEST,
          request_removed: charges[i].transaction_type == TransactionTypes.REQUEST_REMOVED,
          request_fulfilled: charges[i].transaction_type == TransactionTypes.REQUEST_FULFILLED,
          request_refund: charges[i].transaction_type == TransactionTypes.REQUEST_REFUND,
          three_d_secure_started: charges[i].transaction_type == TransactionTypes.THREE_D_SECURE_STARTED,
          three_d_secure_succeed: charges[i].transaction_type == TransactionTypes.THREE_D_SECURE_SUCCEED,
          three_d_secure_failed: charges[i].transaction_type == TransactionTypes.THREE_D_SECURE_FAILED,
          canceled: charges[i].transaction_type == TransactionTypes.CANCELED,
          is_not_final_status: not_final_status.includes(charges[i].transaction_type),
          amount: charges[i].amount_formatted,
          base_amount: charges[i].base_amount_formatted,
          service_charge: charges[i].service_charge_formatted,
          tax: charges[i].tax_formatted,
          gratuity: charges[i].gratuity_formatted,
          amount_raw: charges[i].amount,
          charged: charges[i].charged_formatted,
          brand: charges[i].brand,
          last_4: charges[i].last_4,
          card_id: charges[i].card_id,
          is_refund: charges[i].is_refund,
          reason: charges[i].notes,
          successful: charges[i].successful,
          venue_key: Pmp.Manager.Global._url_key_or_id,
          domain: domain,
          non_zero: charges[i].amount ? true : false,
          promo_discount_amount: charges[i].promo_discount_amount_formatted,
          upsell_amount: charges[i].upsell_amount_formatted,
          original_amount: charges[i].original_base_formatted,
        })
        $('#payments-history').append(html)
      }

      // There's no indication that the card has been
      // deleted in the history, so rather than tacking
      // on kruft in the backend, just check for id
      // matches.
      $('#payments-history .delete').each(function () {
        var id = $(this).attr('card_id')
        $('[name=choose_card] option[value=' + id + ']').remove()
        $('[name=choose_card]').trigger('change')
        $('#payments-history .save[card_id=' + id + '] p.actions').removeClass('actions')
      })

      for (let i = 0; i < successful_charges.length; ++i) {
        var $row = $('#payment-' + successful_charges[i])
        var transaction_id = $row.attr('transaction_id')

        // just for scoping
        var helper = (function ($row) {
          var deferred = $.Deferred()

          if (transaction_id) {
            deferred = BillingService.getInvoiceByChargeId(transaction_id, actual_id)
          }

          deferred.done(function (resp) {
            $row.find('p.actions').each(function () {
              $(this).empty()

              if ($row.hasClass('delete')) {
                return
              }
              if ($row.hasClass('request-removed')) {
                return
              }
              if ($row.hasClass('request-fulfilled')) {
                return
              }

              if ($row.hasClass('info-request')) {
                var copy_link = $('<a/>').addClass('copy-info-link').attr('href', 'javascript:void(0)').sext('copy link')

                var remove_link = $('<a/>').addClass('remove-info-link').attr('href', 'javascript:void(0)').sext('delete link')

                var send_info_link = $('<a/>')
                  .addClass('send-note-link')
                  .attr('href', 'javascript:void(0)')
                  .sext('send credit card link email')

                var divider = $('<span/>').addClass('divide').sext('|')

                $(this).append(copy_link)
                $(this).append(divider.clone())
                $(this).append(remove_link)
                $(this).append(divider.clone())
                $(this).append(send_info_link)

                new Clipboard(copy_link[0], {
                  text: function (trigger) {
                    Interface.alertSuccess('Link copied!')
                    return $(trigger).closest('.details').find('input.link-copy').val()
                  },
                })

                remove_link.on('click', function () {
                  var history_id = $row.attr('history_id')
                  var deferred = BillingService.removeLink(history_id)
                  var $title = $row.find('p strong')
                  deferred.done(function (resp) {
                    $row.find('p.actions, form').remove()
                    $title.sext($title.text() + ' (Deleted)')
                    Interface.alertSuccess('Request link removed.')
                  })
                })

                return
              }

              if ($row.hasClass('save')) {
                var delete_link = $('<a/>').addClass('delete-saved-card').attr('href', 'javascript:void(0)').sext('delete card')

                delete_link.on('click', function () {
                  var card_id = $(this).closest('div.payment').attr('card_id')
                  var deferDelete
                  $(this).remove()

                  if ($.inArray(card_id, ReservationSlideOut._card_ids) !== -1) {
                    var venue_id = $('#input-venue-id').val()
                    deferDelete = BillingService.removeCard(vgc_id, card_id, true, actual_id, venue_id)
                  } else {
                    deferDelete = BillingService.removeCardFromReservation(actual_id, card_id)
                  }
                  deferDelete.done(function (resp) {
                    // I would refresh here, but there's a heinous lag in the backend
                    // updating, so I'd have to delay by 3+ seconds.
                    Interface.alertSuccess('Card removed')

                    $('select[name=choose_card] option[value=' + card_id + ']').remove()
                    ReservationSlideOut._setDefaultCard()
                  })
                })
                $(this).append(delete_link)
                return
              }

              if (resp && resp.amount_remaining && !$row.hasClass('refund')) {
                const refund_possible = refundable_charges[successful_charges[i]]
                var refund_link = $('<a/>')
                  .addClass(refund_possible ? 'refund-link' : 'invalid-refund-link')
                  .attr('href', 'javascript:void(0)')
                  .attr('amount', resp.amount)
                  .attr('amount_remaining', resp.amount_remaining_formatted)
                  .attr('transaction_id', resp.charge_id)
                  .sext('issue refund')
                $(this).append(refund_link)
                var divider = $('<span/>').addClass('divide').sext('|')
                $(this).append(divider)
              }

              if (resp) {
                var notification_link = $('<a/>')
                  .addClass('send-note-link')
                  .attr('href', 'javascript:void(0)')
                  .attr('transaction_id', resp.charge_id)

                if (!$row.hasClass('refund')) {
                  notification_link.sext('send payment notification').addClass('payment')
                } else {
                  notification_link.sext('send refund notification').addClass('refund')
                }

                $(this).append(notification_link)
              }
            })
          })

          if (!transaction_id) {
            deferred.resolve(null)
          }
        })($row)
      }
      if (successful_charges.length) {
        $('#payments-hold').hide()
      }
      $('#payments-history').show()
      $('#refund-form-container').hide()
    })
  },
}

// Enable success and error messages across React and Legacy JS environments
var UserNotificationInterop = {
  success: function (msg) {
    Interface.alertSuccess(msg)
    if (SvrManager.UserNotifications) {
      SvrManager.UserNotifications.postSuccessMessage(msg) // exported in dayview app.jsx
    }
  },
  error: function (msg) {
    Interface._alert(msg)
    if (SvrManager.UserNotifications) {
      SvrManager.UserNotifications.postErrorMessage(msg) // exported in dayview app.jsx
    }
  },
}

var Interface = {
  _alert: function (message, where) {
    if (!where) {
      where = '#general-error'
    }

    $where = $(where)

    if (!message || typeof message != 'string') {
      message = 'Whoops! Something went wrong.'
    }

    if ($where.find('.text').length) {
      $where.find('.text').sext(message)
    } else {
      $where.sext(message)
    }

    $where.show()
    $where.delay(8000).fadeOut(2000)
  },

  alertSuccess: function (message) {
    this._alert(message, '#general-success')
  },

  alertLeftButtomSnackBarError: function (message) {
    this._alert(message, '#left-bottom-error')
  },

  openslide: function (id) {
    $('#main-interface .auto-tab li').removeClass('selected')
    $('#main-interface .auto-tab li.default').addClass('selected')
    $(id + ' .tab').hide()
    $(id + ' .tab.default').show()
    $(id + ' .tab').scrollTop(0)

    // IE9 is being a huge bitch about this
    if (!$('#res_type').val()) {
      $('#res_type').val('TABLE')
    }

    // Annoying, but gotta do whatcha gotta do
    // Do this on the backed someday
    if ($('#interface-client .-city_display').sext() == '' || $('#interface-client .-state_display').sext() == '') {
      $('#interface-client .comma').hide()
    } else {
      $('#interface-client .comma').show()
    }

    // KP - boooooo
    // Minor hack
    if ($('.-total_guests').sext() == '' && $('.-max_guests_formatted').sext() == '') {
      $('.header-info span.total-guests').hide()
    } else {
      $('.header-info span.total-guests').show()
    }

    $(id).animate(
      {
        right: 0,
      },
      200
    )
    return false
  },

  closeslide: function (id) {
    $('#client-profile-area').hide()
    $('#flyout-notes-area').hide()
    $(id).animate(
      {
        right: -1 * ($('#main-interface, #block-slideout').width() + 10),
      },
      200
    )
    return false
  },

  isClosed: function (id) {
    if ($('#' + id)) {
      return $(window).width() < $('#' + id).position().left
    } else {
      return false
    }
  },
  toggleform: function () {
    $('#nav-reservation').click()
    $('#main-interface').toggleClass('editing')
    $('#edit-reservation').scrollTop(0)
  },

  sendform: function (e, successhandler, errorhandler) {
    var that = this

    // Pre prep func, needs to be dynamic
    // update_res_form();

    var $e = $(e.target)
    var target = $e.attr('target')
    var $form

    if (!successhandler && $e.attr('success_redirect')) {
      successhandler = function () {
        window.location = $e.attr('success_redirect')
      }
    }

    if (target) {
      $form = $(target)
    } else {
      $form = $(e.target).closest('form')
    }

    if (typeof $form.attr('sr-validate') != undefined) {
      var validator = new sr.Validator($form.parent(), undefined)
      if (!validator.validate()) {
        return false
      }
    }

    var data = $form.serialize()

    $.ajax({
      url: $form.prop('action'),
      method: $form.attr('method'), // needs to be attr
      data: data,
      success: successhandler,
      error: errorhandler,
    })

    return false
  },

  attachsearch: function (searcher, target, containers) {
    var $searcher = $(searcher),
      $target = $(target)

    $searcher.on('keyup', function () {
      $seek = $(this).val().toLowerCase()

      $target.find('.standard-row').show()

      if ($seek.length < 3) return

      $target.find('.standard-row').each(function (i) {
        $text = $(this).find(containers).sext().toLowerCase()
        if ($text.indexOf($seek) == -1) {
          $(this).hide()
        }
      })
    })
  },

  sploosh: function (obj, id) {
    var target = $(id)
    for (key in obj) {
      var value = obj[key]
      target.find('.auto.-' + key).each(function (iter, item) {
        $item = $(item)
        if ($item.is('input, textarea')) {
          $item.val(value)
        } else {
          $item.sext(value, $item.hasClass('--noescape'))
        }
      })
    }
  },

  clearnew: function () {
    $('.standard-row').removeClass('just-added')
  },

  clear: function (id) {
    var target = $(id)
    target.find('.auto').not('select').html('')
    target.find('.auto').val('')
    target.find('.error').hide()
    target.find('.artificial-exclusion input').prop('checked', false)
    target.find('.artificial-exclusion .form-element').removeClass('checked')
    target.find('input, textarea, select, .radioset').removeClass('disabled')
  },
}

AjaxLoader = {
  show: function () {
    $mjax = $('#manager-ajax')
    $mjax.prop('class', '')
    $mjax.addClass('default')
    $mjax.show()
  },
}

$(function () {
  $scope = $('body')

  // I REALLY. NEED. TEMPLATE. SETTINGS. TO. PROPOGATE. UP.
  if ($('.has-sidebar').length) {
    $('#content-container').addClass('so-above')
  }

  // This has to be better
  //$('#submit-account-form').on('click', function() {});

  var account_photo_upload_handler = function (e, data) {
    Pmp.Common.PhotoCropper.onImageUpload(data)
  }

  var attachments_handler = function (e, data) {
    Attachments.cacheUploadData(data)

    for (i = 0; i < data.length; i++) {
      var file = data[i]
      var wrap = $('<span/>').sext(file.filename)
      wrap.addClass('attachment')

      var close = $('<a/>').html('&times;')
      close.on('click', function () {
        Attachments.remove(file.blob_key, this, true)
        return false
      })
      wrap.append(close)

      $('.attachment-filename-display').append(wrap)
    }
    $('.attach-file').removeClass('loading')
    //Attachments.uploadHandler(e,data);
  }

  var client_photo_upload_handler = function (e, data) {
    Pmp.Common.PhotoCropper.onImageUpload(data)
  }

  const add_loader = function () {
    $('.attach-file').addClass('loading')
  }

  Uploader.basic('#booknew-uploader', attachments_handler, false, add_loader)

  const chatOnAdd = function (data) {
    const selector = '#chat-uploader'
    const redirect_url = $(selector).attr('rurl')
    const dataArray = data.files.map(file => {
      const cloneData = {
        files: [file],
        originalFiles: [file],
        context: data.context,
        paramName: data.paramName.slice(),
      }
      return cloneData
    })
    function submitFile(dataItem) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `/upload-url?rurl=${redirect_url}`,
          method: 'post',
          success(response_data) {
            const { upload_url } = response_data
            const formData = new FormData()
            const file = dataItem.files[0]
            formData.append(dataItem.paramName[0] || 'file', file, file.name)
            if (dataItem.context) {
              formData.append('context', JSON.stringify(dataItem.context))
            }
            $.ajax({
              url: upload_url,
              type: 'POST',
              data: formData,
              cache: false,
              contentType: false,
              processData: false,
              success(uploadResponse) {
                const file = uploadResponse[0]
                resolve({
                  ...file,
                })
              },
              error(jqXHR, textStatus, errorThrown) {
                console.error('Error during file upload', textStatus, errorThrown)
                reject(errorThrown) // Reject promise if file upload fails
              },
            })
          },
          error(xhr, status, error) {
            console.error('Failed to fetch upload URL', error)
            reject(error) // Reject the promise if URL fetch fails
          },
        })
      })
    }

    dataArray
      .reduce(
        (promiseChain, dataItem) =>
          promiseChain.then(resultArray =>
            submitFile(dataItem).then(result => {
              resultArray.push(result)
              return resultArray
            })
          ),
        Promise.resolve([])
      )
      .then(finalResponse => {
        console.log('All files uploaded successfully', finalResponse)
        attachments_handler(undefined, finalResponse)
        Chatter.validate()
      })
      .catch(error => {
        console.error('One or more files failed to upload', error)
      })
  }

  Uploader.advanced(
    '#chat-uploader',
    (e, data) => {
      attachments_handler(e, data)
      Chatter.validate()
    },
    false,
    add_loader,
    undefined,
    chatOnAdd
  )
  Uploader.photo('#account-photo', account_photo_upload_handler)
  Uploader.photo('#client-photo', client_photo_upload_handler)

  // Cheats
  $('#ajax-spinner').remove() // :(

  // Ajax global indicators
  $mjax = $('#manager-ajax')
  $(document).ajaxStart(function () {
    $mjax.show()
    $('.activity').css('opacity', 0.4)
  })

  $(document).ajaxError(function (event, jqxhr, settings, thrownError) {
    if (jqxhr.status == 401) {
      console.log('refreshing the page')
      location.reload()
    }
  })

  $(document).ajaxStop(function (event, xhr) {
    //if( xhr.status == 301 || xhr.status == 302 ) {
    //  window.location = '/';
    //}

    $mjax.fadeOut(1000)
    $('.activity').css('opacity', 1)
  })

  $('.toggle-sibling').on('click', function (event) {
    $(this).next().toggle()
  })

  $scope.on('click', '.sendform', Interface.sendform)
  $scope.on('click', '#close-interface', function () {
    Interface.closeslide('#main-interface')
    $('#flyout-notes-area').hide()
    return false
  })
  $scope.on('click', '.close-interface', function () {
    Interface.closeslide($(this).closest('.slideout'))
    return false
  })

  $scope.on('click', '#close-error', function () {
    $('#general-error').hide()
  })

  $scope.on('click', '#close-success', function () {
    $('#general-success').hide()
  })

  $scope.on('click', '#account-options', function (e) {
    // position the dropdown around the same position as
    // the parent element.
    var el = $('#account-options')
    var pos = el.position()
    var right = $('#content-container').width() - el.width() - pos.left - 10
    var dropDown = $('#user-menu')
    dropDown.css('top', pos.top + 23)
    dropDown.css('right', right)
    e.stopPropagation()
    $('#user-menu').toggle()
    return false
  })

  $scope.on('click', '.sidebar-drop .current', function () {
    $('.sidebar-drop li.option').show()
    return false
  })

  $scope.on('mouseleave', '.sidebar-drop', function () {
    $('.sidebar-drop li.option').hide()
    return false
  })

  $scope.on('click', function (e) {
    if ($(e.target).closest('.utildrop').length) {
      return
    }
    $('.utildrop').hide()
  })

  var autonav = function (e) {
    e.preventDefault()

    var $tab = $(e.target).closest('a')
    var $menu = $tab.closest('ul')
    var $container = $menu.closest('.autonav-container')

    $menu.find('li').removeClass('selected')

    var showTab = $tab.attr('showtab')
    var section = $tab.prop('id').slice(4)

    $menu.find('li.' + section).addClass('selected')

    $container.children('.tab').hide()
    if (showTab) {
      $container.find('#tab-' + showTab).show()
    } else {
      $container.find('#tab-' + section).show()
    }

    return false
  }

  $('.auto-tab a').on('click', autonav)
})

// Old js

Pmp.Manager.Global = {
  _user_domain_venue_subscribers: [],
  _user_domain_venue_group_subscribers: [],

  initialize: function (
    access_orgs,
    managerBaseUrl,
    url_key_or_id,
    venue_name,
    venue_id,
    venue_squid,
    venue_internal_display_name,
    venue_class,
    venue_has_floorplan,
    initDateText,
    dateUrlParam,
    todayDate,
    muni_today_date,
    bookedDates,
    blackedDates,
    customOpenedDates,
    daysOfOperation,
    locale,
    navigation,
    venue_local_date,
    cal_data_added,
    user_id,
    user_email,
    user_first_name,
    user_last_name,
    user_is_superuser,
    job_title,
    user_create_date,
    additional_options,
    venue_location,
    venue_created_date,
    guestlist_enabled,
    event_widget_enabled,
    dining_widget_enabled,
    membership_enabled,
    is_sizzle_enabled,
    is_guest_satisfaction_enabled,
    is_payments_enabled,
    is_orders_integration_enabled,
    order_systems,
    gc_sync_method,
    env,
    statuses,
    privilege_level,
    is_military_time,
    can_overbook,
    can_manage_restricted_tags,
    can_override_payment_requirement,
    last_name_first,
    start_of_day_hour,
    auto_assign_enabled,
    actual_problems_enabled,
    domain_name,
    entity_url_suffix,
    venue_group_id,
    venue_group_name,
    venue_group_squid,
    session_key,
    isFullstoryEnabled,
    productProvisionPackage,
    productProvisionAddonOnlineOrdering,
    productProvisionAddonEmailCampaigns,
    productProvisionAddonApi,
    productProvisionAddonPremiumIntegrations,
    productProvisionAddonPms,
    productProvisionAddonSmsMarketing,
    highlightInsightText,
    highlightInsightTypeKey,
    highlightInsightLink,
    forceRedirectsOnLegacyWidget,
    newResWidgetEnabled,
    internalArBookingEnabled,
    internalArBookingEnabledMobile,
    internalUpsellsEnabled,
    internalUpsellsEnabledMobile,
  ) {
    var nav_array = ['reservations', 'guestlist']

    this._access_orgs = access_orgs
    this._user_id = user_id
    this._user_email = user_email
    this._user_first_name = user_first_name
    this._user_last_name = user_last_name
    this._user_is_superuser = user_is_superuser
    this._job_title = job_title
    this._user_create_date = user_create_date
    this._user_additional_options = additional_options
    this._venue_location = venue_location
    this._account_create_date = venue_created_date
    this._guestlist_enabled = guestlist_enabled
    this._event_widget_enabled = event_widget_enabled
    this._dining_widget_enabled = dining_widget_enabled
    this._membership_enabled = membership_enabled
    this._sizzle_enabled = is_sizzle_enabled
    this._guest_satisfaction_enabled = is_guest_satisfaction_enabled
    this._payments_enabled = is_payments_enabled
    this._is_orders_integration_enabled = is_orders_integration_enabled
    this._order_systems = order_systems
    this._gc_sync_method = gc_sync_method
    this._env = env
    this._url_key_or_id = url_key_or_id
    this._venue_id = venue_id
    this._venue_squid = venue_squid
    this._venue_name = venue_name
    this._venue_internal_display_name = venue_internal_display_name
    this._venue_class = venue_class
    this._venue_has_floorplan = venue_has_floorplan
    this._locale = locale
    this._navigation = $.inArray(navigation, nav_array) ? navigation : 'reservations'
    this._managerBaseUrl = managerBaseUrl
    this._privilege_level = privilege_level
    this._is_military_time = is_military_time
    this._can_overbook = can_overbook
    this._can_override_payment_requirement = can_override_payment_requirement
    this._can_manage_restricted_tags = can_manage_restricted_tags
    this._last_name_first = last_name_first
    this._venue_group_id = venue_group_id
    this._venue_group_name = venue_group_name
    this._venue_group_squid = venue_group_squid
    this._session_key = session_key
    try {
      this._venue_today_date = $.datepicker.parseDate('mm/dd/yy', venue_local_date)
    } catch (e) {
      this._venue_today_date = new Date()
    }
    this._venue_today_date.setHours(0, 0, 0, 0)
    this._auto_assign_enabled = auto_assign_enabled
    this._actual_problems_enabled = actual_problems_enabled
    this.start_of_day_hour = start_of_day_hour
    this._domain_name = domain_name
    this._entity_url_suffix = entity_url_suffix
    this._productProvisionPackage = productProvisionPackage
    this._productProvisionAddonOnlineOrdering = productProvisionAddonOnlineOrdering
    this._productProvisionAddonEmailCampaigns = productProvisionAddonEmailCampaigns
    this._productProvisionAddonApi = productProvisionAddonApi
    this._productProvisionAddonPremiumIntegrations = productProvisionAddonPremiumIntegrations
    this._productProvisionAddonPms = productProvisionAddonPms
    this._productProvisionAddonSmsMarketing = productProvisionAddonSmsMarketing
    this._highlightInsightText = highlightInsightText
    this._highlightInsightTypeKey = highlightInsightTypeKey
    this._highlightInsightLink = highlightInsightLink
    this._forceRedirectsOnLegacyWidget = forceRedirectsOnLegacyWidget
    this._newResWidgetEnabled = newResWidgetEnabled
    this._internalArBookingEnabled = internalArBookingEnabled
    this._internalArBookingEnabledMobile = internalArBookingEnabledMobile
    this._internalUpsellsEnabled = internalUpsellsEnabled
    this._internalUpsellsEnabledMobile = internalUpsellsEnabledMobile

    // Prevents crashing when calendar info is missing
    if (cal_data_added) {
      this._customOpenedDates = customOpenedDates
      this._daysOfOperation = daysOfOperation
      this._initDateText = initDateText
      this._dateUrlParam = dateUrlParam
      this._bookedDates = bookedDates
      this._blackedDates = blackedDates
      this._statuses = statuses
      this._today_date = $.datepicker.parseDate('mm/dd/y', todayDate)
      this._initCalendar()
    }

    this._initAnalytics(
      {
        id: user_id,
        email: user_email,
        firstName: user_first_name,
        lastName: user_last_name
      },
      this._job_title,
      this._venue_group_id,
      this._venue_name,
      this._venue_class,
      this._locale,
      isFullstoryEnabled,
    )
    if (globalInit.venueSettings.use_supafly) {
      this.setupAddReservationButton()
    }
    this._loadUserDomainVenues()
    this._loadUserDomainVenueGroups(this._session_key)

    if ($('#head-menu').length) {
      this._headerRef = SvrManager.Header.container('head-menu', this._venue_id, this._entity_url_suffix, this.onVenuesPickerToggle)
    }
  },

  onVenuesPickerToggle: function () {
    $('#user-menu').hide()
  },

  setupAddReservationButton: function () {
    var $bookNew = $('#book-new')
    $bookNew.addClass('disabled')
    // Added to disable the 'Add Reservation' button
    // unless User Domain venues have loaded (only for Supafly)
    this.subscribeOnUserDomainVenuesLoad(function (venuesAndVenueSearchGroups) {
      if (!venuesAndVenueSearchGroups || !venuesAndVenueSearchGroups.venues || venuesAndVenueSearchGroups.venues.length === 0) {
        return
      }
      $bookNew.removeClass('disabled')
    })
  },

  fullStory: function (userId, userEmail, venueGroupId, venueName, venueClass, locale) {
    window['_fs_debug'] = false
    window['_fs_host'] = 'fullstory.com'
    window['_fs_script'] = 'edge.fullstory.com/s/fs.js'
    window['_fs_org'] = '6ABR4'
    window['_fs_namespace'] = 'FS'
    ;(function (m, n, e, t, l, o, g, y) {
      if (e in m) {
        if (m.console && m.console.log) {
          m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].')
        }
        return
      }
      g = m[e] = function (a, b, s) {
        g.q ? g.q.push([a, b, s]) : g._api(a, b, s)
      }
      g.q = []
      o = n.createElement(t)
      o.async = 1
      o.crossOrigin = 'anonymous'
      o.src = 'https://' + _fs_script
      y = n.getElementsByTagName(t)[0]
      y.parentNode.insertBefore(o, y)
      g.identify = function (i, v, s) {
        g(l, { uid: i }, s)
        if (v) g(l, v, s)
      }
      g.setUserVars = function (v, s) {
        g(l, v, s)
      }
      g.event = function (i, v, s) {
        g('event', { n: i, p: v }, s)
      }
      g.anonymize = function () {
        g.identify(!!0)
      }
      g.shutdown = function () {
        g('rec', !1)
      }
      g.restart = function () {
        g('rec', !0)
      }
      g.log = function (a, b) {
        g('log', [a, b])
      }
      g.consent = function (a) {
        g('consent', !arguments.length || a)
      }
      g.identifyAccount = function (i, v) {
        o = 'account'
        v = v || {}
        v.acctId = i
        g(o, v)
      }
      g.clearUserCookie = function () {}
      g.setVars = function (n, p) {
        g('setVars', [n, p])
      }
      g._w = {}
      y = 'XMLHttpRequest'
      g._w[y] = m[y]
      y = 'fetch'
      g._w[y] = m[y]
      if (m[y])
        m[y] = function () {
          return g._w[y].apply(this, arguments)
        }
      g._v = '1.3.0'
    })(window, document, window['_fs_namespace'], 'script', 'user')

    FS.identify(userId, {
      email: userEmail,
      venueName_str: venueName,
      venueClass_str: venueClass,
      venueLocale_str: locale,
      venueGroupId_str: venueGroupId,
    })
  },

  log: function (msg) {
    console.log('Pmp.Manager.Global: ' + msg)
  },

  debug: function (msg) {
    if (Pmp.Settings.DEBUG) {
      this.log(msg)
    }
  },

  _initCalendar: function () {
    this.debug('initCalendar')
    var self = this
    Pmp.Utils.LocalizeDatePicker(self._locale, '#monthly-calendar', '#monthly-calendar-submit')
    var sel = '#monthly-calendar'

    $(sel).datepicker('option', 'defaultDate', this._initDateText)
    $(sel).datepicker('setDate', this._initDateText)
    $(sel).datepicker('option', 'dayNamesMin', ['S', 'M', 'T', 'W', 'T', 'F', 'S'])
    // $(sel).datepicker('option', 'monthNames', ['JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER']);
    $(sel).datepicker('option', 'showOtherMonths', true)
    $(sel).datepicker('option', 'selectOtherMonths', true)
    $(sel).datepicker('option', 'onSelect', function (dateText, calendar) {
      self._onSelectDate(dateText, calendar)
    })

    $(sel).datepicker('option', 'beforeShowDay', function (date) {
      return self._beforeShowDay(date)
    })

    if ($('#viewing-all-requests').val()) {
      $('#calendar-wrap td a').removeClass('ui-state-active')
    }
  },

  _initAnalytics: function (user, jobTitle, venueGroupId, venueName, venueClass, locale, isFullstoryEnabled) {
    if (isFullstoryEnabled){
      this.fullStory(user.id, user.email, venueGroupId, venueName, venueClass, locale)
    }
  },

  _beforeShowDay: function (date) {
    var date_formatted = $.datepicker.formatDate('mm/dd/y', date)
    var day_of_week_short = $.datepicker.formatDate('D', date)
    var dow_index = $.inArray(day_of_week_short, ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])
    var td_class = ''

    /* Trumping order of background colors:
     *  - Blacked-out
     *  - Today
     *  - Closed Date (except custom opened ones)
     *  - Passed Date
     *  - NOTE: trumping handled in CSS instead of here
     */

    td_classes = []
    if ($.inArray(date_formatted, this._blackedDates) >= 0) {
      td_classes.push('blacked-date')
    }
    if (date.compareTo(this._today_date) == 0) {
      td_classes.push('today-date')
    }
    if (!this._daysOfOperation[dow_index] && $.inArray(date_formatted, this._customOpenedDates) < 0) {
      td_classes.push('closed-date')
    }
    if (date.compareTo(this._today_date) < 0) {
      td_classes.push('passed-date')
    }

    /* Added Background images:
     *  - Booked date
     */
    if ($.inArray(date_formatted, this._bookedDates) >= 0) {
      td_classes.push('booked-date')
    }

    td_class = td_classes.join(' ')

    return [true, td_class]
  },

  _onSelectDate: function (dateText, calendar) {
    this.debug('_onSelectDate')

    if (this._initDateText !== dateText) {
      var dateObj = $('#' + calendar.id).datepicker('getDate')
      var dateText = $.datepicker.formatDate('mm-dd-yy', dateObj)
      var mobile_prefix = Pmp.Settings.IS_MOBILE ? Pmp.Settings.MOBILE_PATH : ''
      var relativeUrl = mobile_prefix + this._managerBaseUrl + '/' + this._navigation + '/day/' + dateText
      window.location = relativeUrl
    }
  },

  _loadUserDomainVenues: function () {
    var that = this
    let user_domain_cache_version = ''
    try {
      user_domain_cache_version = window.localStorage.user_domain_cache_version || ''
    } catch (err) {
      this.debug('Local storage not available')
    }
    var data = {
      venue_group_id: that._venue_group_id,
      venue_id: that._venue_id,
      sid: that._session_key,
      cache_version: 'v22-cache-improvements' + user_domain_cache_version,
    }
    if (this._user_is_superuser) {
      data['venue_id'] = this._venue_id
    }
    $.ajax({
      url: '/api-yoa/venue/user_venue_users',
      data: data,
      success: function (response) {
        if (response.status !== 200) {
          UserNotificationInterop.error(response.msg)
          return
        }
        that._onLoadUserDomainVenues(response.data)
      },
    })
  },

  _onLoadUserDomainVenues: function (data) {
    var that = this
    that.UserDomainVenues = data
    data.venues.forEach((venue) => {
      venue.manager_base_url = `/manager/${venue.url_key || venue.id}`
      venue.internal_display_name = venue.internal_name || venue.name
    })

    if (that._entity_url_suffix !== null) {
      this._headerRef && this._headerRef.current.onVenuesLoaded(data.venues)
      var mobile_prefix = Pmp.Settings.IS_MOBILE ? Pmp.Settings.MOBILE_PATH : ''
      $.each(data.venues, function (i, user_venue) {
        var option = $('<option />')
          .attr('value', mobile_prefix + user_venue.manager_base_url + that._entity_url_suffix)
          .sext(user_venue.name)
        if (user_venue.id === that._url_key_or_id || user_venue.url_key === that._url_key_or_id) {
          option.attr('selected', true)
        }
        $('#mobile-venue-change').append(option)
      })
    }

    $.each(that._user_domain_venue_subscribers, function (i, callback) {
      callback(that.UserDomainVenues)
    })

    if (Pmp.Manager.Reservations.Day && Pmp.Manager.Reservations.Day._user_domain_venue_subscribers) {
      $.each(Pmp.Manager.Reservations.Day._user_domain_venue_subscribers, function (i, callback) {
        callback(that.UserDomainVenues)
      })
    }
  },

  subscribeOnUserDomainVenuesLoad: function (callback) {
    this._user_domain_venue_subscribers.push(callback)
  },

  _loadUserDomainVenueGroups: function (sessionKey) {
    var that = this
    $.ajax({
      url: '/api-yoa/venuegroup/user_venue_group_users',
      data: { sid: sessionKey, cache_version: 'v1' },
      success: function (response) {
        if (response.status !== 200) {
          UserNotificationInterop.error(response.msg)
          return
        }
        that._onLoadUserDomainVenueGroups(response.data)
      },
    })
  },

  _onLoadUserDomainVenueGroups: function (data) {
    var that = this
    that.UserDomainVenueGroups = data

    $.each(that._user_domain_venue_group_subscribers, function (i, callback) {
      callback(that.UserDomainVenueGroups)
    })
  },

  subscribeOnUserDomainVenueGroupsLoad: function (callback) {
    this._user_domain_venue_group_subscribers.push(callback)
  },
}

var ShiftSelector = {
  init: function (baseUrl, override_selected_shift) {
    var that = this
    if (!('localStorage' in window) || window['localStorage'] === null) {
      return
    }

    this.selectedShiftKey = 'selected_shift' + baseUrl
    if (override_selected_shift) {
      localStorage.setItem(that.selectedShiftKey, override_selected_shift)
    }

    var floorplan_base_url = undefined
    var update_floorplan_href_shift_param = function () {
      // Floorplan page is the only one that requires shift at load time (and uses override param)
      // since shift-specific layout data is part of the initial load response.  We update the
      // link manually so that you remain on the same shift when changing tabs (at least when coming
      // from another shift-specific tab).
      var updated_shift = that.getSelectedShiftPersistentId()
      if ($('#floorplan-link a').length && updated_shift) {
        floorplan_base_url = floorplan_base_url || $('#floorplan-link a').attr('href')
        var updated_url = floorplan_base_url + '?shift_persistent_id=' + updated_shift
        $('#floorplan-link a').attr('href', updated_url)
      }
    }

    $('#shift-select').on('change', function () {
      localStorage.setItem(that.selectedShiftKey, $(this).val())
      update_floorplan_href_shift_param()
    })

    var hasTriggeredShiftChange = false
    var lastShiftPid = localStorage.getItem(this.selectedShiftKey)
    if (lastShiftPid && $('#shift-select').length) {
      if ($('#shift-select option[value="' + lastShiftPid + '"]').length) {
        $('#shift-select').val(lastShiftPid)
      } else {
        // Fall back to category match or first shift
        var lastShiftCategory = ShiftSelector.getCategoryFromPersistentId(lastShiftPid)
        var bestShiftPid = undefined
        $('#shift-select option').each(function () {
          var shiftPid = $(this).val()
          var shiftCategory = ShiftSelector.getCategoryFromPersistentId(shiftPid)
          if (shiftCategory === lastShiftCategory) {
            bestShiftPid = shiftPid
            return false // break
          } else if (bestShiftPid === undefined) {
            bestShiftPid = shiftPid
          }
        })
        if (bestShiftPid) {
          $('#shift-select').val(bestShiftPid)
        }
      }
      if (override_selected_shift === undefined) {
        console.log('triggering shift change')
        $('#shift-select').trigger('change')
        hasTriggeredShiftChange = true
      }
      // this is hacky
      var shift_name = $('#shift-select option:selected').sext()
      $('#shift-title').sext(shift_name)
    }

    update_floorplan_href_shift_param()
    return hasTriggeredShiftChange
  },

  getSelectedShiftPersistentId: function () {
    var shift_persistent_id = $('#shift-select').val()
    if (!shift_persistent_id && localStorage.getItem(this.selectedShiftKey)) {
      shift_persistent_id = localStorage.getItem(this.selectedShiftKey)
    }
    if (shift_persistent_id) {
      return shift_persistent_id
    }
    return undefined
  },

  getCategoryFromPersistentId: function (shift_persistent_id) {
    return shift_persistent_id ? shift_persistent_id.split('-').reverse()[1] : undefined
  },
}

//Global error handling
var exceptions = []

var getPerfStats = function () {
  var timing = window.performance.timing
  return {
    latency: timing.responseStart - timing.fetchStart,
    domProcessing: timing.domInteractive - timing.domLoading,
    domComplete: timing.domComplete - timing.domInteractive,
  }
}

var retrieveParamFromUrl = function (param) {
  var params = _.object(
    _.map(((window.location || {}).search || '').split('&'), function (p) {
      return p.split('=')
    })
  )
  return params[param] || params['?' + param]
}

// Show perf stats
window.onload = function () {
  if (window.performance && window.performance.timing && retrieveParamFromUrl('perf')) {
    var ntStats = getPerfStats(),
      sum = _.reduce(
        _.values(ntStats),
        function (x, y) {
          return x + y
        },
        0
      )
    $('#perf-stats').show()
    $('#perf-stats .latency.header').sext(ntStats.latency)
    $('#perf-stats .domProcessing.header').sext(ntStats.domProcessing)
    $('#perf-stats .domComplete.header').sext(ntStats.domComplete)
    $('#perf-stats .total.header').sext(sum)
    $('#perf-stats .latency').css('width', Math.floor((90 * ntStats.latency) / sum) + '%')
    $('#perf-stats .domProcessing').css('width', Math.floor((90 * ntStats.domProcessing) / sum) + '%')
    $('#perf-stats .domComplete').css('width', Math.floor((90 * ntStats.domComplete) / sum) + '%')
    $('#perf-stats .total').css('width', '10%')
  }
}
